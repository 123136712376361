import React, { useCallback, useContext, useState } from 'react'
import { FormikErrors, useFormik } from 'formik'
import * as Yup from 'yup'
import { v4 } from 'uuid'
import { AlertProps } from '@mui/material'
import { ApolloError } from '@apollo/client'
import { captureException } from '@sentry/browser'
import { Flex } from '../Flex'
import { RecatContext } from '../../context/RecatContext'
import {
  Category,
  EmissionLayerInput,
  GetRecatRulesDocument,
  NumberSearchType,
  RecatRuleInput,
  SearchField,
  StringSearchType,
  useAddRecatRuleMutation,
} from '../../graphql/generated'

import { AppDataContext } from '../../context/AppDataContext'
import { SetEmissionsBox } from './components/SetEmissionsBox'
import { ChangeCatBox } from './components/ChangeCatBox'
import { RecatType, SelectRecatType } from './components/SelectRecatType'
import { RecatButtons } from './components/RecatButtons'
import { colors, spacing } from '../../theme'
import { handleValidationError } from '../../utils/validationError'
import { Body2Bold } from '../Typography'
import { RulesBreadCrumbs } from './components/RulesBreadCrumbs'
import { SnackAlert } from '../SnackAlert'

interface Props {
  setRecatType: (recatType: RecatType) => void
  recatType: RecatType
}

export function RecatBox({
  setRecatType,
  recatType,
}: Props): React.JSX.Element {
  const { categories } = useContext(AppDataContext)
  const [loading, setLoading] = useState(false)
  const [addRecatRules] = useAddRecatRuleMutation({
    refetchQueries: [{ query: GetRecatRulesDocument }],
  })

  const [snackBar, setSnackbar] = useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)

  const {
    ruleSuppliers,
    ruleAccounts,
    totalKr,
    firstTransactionDate,
    lastTransactionDate,
    containsNotRecatable,
    getFieldCriterion,
  } = useContext(RecatContext)

  const escapeRegex = (s: string) => {
    return s.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')
  }

  const getCommentRegexp = useCallback(() => {
    const commentCriterion = getFieldCriterion(SearchField.Comment)
    if (!commentCriterion || !commentCriterion.searchString) {
      return undefined
    }
    if (commentCriterion.stringSearchType === StringSearchType.Regexp) {
      return commentCriterion.searchString[0]
    } else if (commentCriterion.stringSearchType === StringSearchType.Contain) {
      return `.*${escapeRegex(commentCriterion.searchString[0])}.*`
    } else if (
      commentCriterion.stringSearchType === StringSearchType.StartWith
    ) {
      return `^${escapeRegex(commentCriterion.searchString[0])}.*`
    } else {
      return `^${escapeRegex(commentCriterion.searchString[0])}$`
    }
  }, [getFieldCriterion])

  const createNewRules = useCallback(
    (variables: {
      category: Category | undefined
      comment: string
      emissionIntensity: number
      recatType: RecatType
      emissionLayers: EmissionLayerInput
    }): RecatRuleInput => {
      const commentRegexp = getCommentRegexp()
      let accs: string[] = ruleAccounts.map((accountInfo) => accountInfo.id)
      if (accs.length < 1) {
        const accountIdCriterion = getFieldCriterion(SearchField.AccountId)
        if (accountIdCriterion) {
          accs = accountIdCriterion.searchString ?? []
        }
      }
      let category = variables.category
      const emissions = variables.emissionIntensity
      if (!category && !emissions) {
        throw new Error('this should never happen')
      }
      const dateCriterion = getFieldCriterion(SearchField.Date)
      let sDate = dateCriterion?.startDate
      let eDate = dateCriterion?.endDate
      let dSearchType = dateCriterion?.numberSearchType
      let emissionLayers: EmissionLayerInput | undefined
      let emissionIntensity: number | undefined
      if (variables.recatType === RecatType.SET_EMISSIONS) {
        // A rule can either set the emission or the category, not both.
        // Ensure that category is undefined, in case we set a default category
        category = undefined

        // when setting emission we enforce the rule to be on a date between
        // so that we know from which dates we will need to extrapolate for future and past emissions
        if (dSearchType !== NumberSearchType.Between || !sDate) {
          dSearchType = NumberSearchType.Between
          sDate = firstTransactionDate
          eDate = lastTransactionDate
        }

        emissionLayers = variables.emissionLayers
        emissionIntensity = emissions / totalKr
      }

      const supplierIds: string[] = ruleSuppliers
        //filter out the cases where the id is not defined
        .filter((supplierInfo) => supplierInfo.id)
        // keep only the ids
        .map((supplierInfo) => supplierInfo.id) as string[]
      const supplierNames: string[] = ruleSuppliers
        //filter out the cases where the id is defined as the are covered by the supplierIds
        .filter((supplierInfo) => !supplierInfo.id)
        // keep only the name
        .map((supplierInfo) =>
          // the undefined option should never happen as we should always have either an id or a name
          // but we need it for typescript to pass
          supplierInfo.name ? `^${escapeRegex(supplierInfo.name)}$` : undefined,
        ) as string[]

      const result: RecatRuleInput = {
        id: v4(), // temporary unique value to identify the rule, when applying the actual ID will be created on the server.
        accounts: accs,
        supplierIds,
        commentRegexp: commentRegexp,
        categoryId: category?.id,
        category: category,
        sni: category?.sni,
        comment: variables.comment,
        organisationId: '', // will be set by the server
        startDate: sDate,
        endDate: eDate,
        dateSearchType: dSearchType,
        supplierNames,
        emissionIntensity,
        emissionLayers,
      }
      return result
    },
    [
      getCommentRegexp,
      ruleAccounts,
      ruleSuppliers,
      getFieldCriterion,
      totalKr,
      firstTransactionDate,
      lastTransactionDate,
    ],
  )

  const initLayers: EmissionLayerInput = {
    ttm: true,
    scope1: true,
    energy: true,
    products: true,
    investments: false,
    services: false,
  }

  const {
    values: formValues,
    setFieldValue,
    handleSubmit,
    handleBlur,
    handleChange,
    resetForm,
    errors: formErrors,
    touched,
  } = useFormik({
    initialValues: {
      // take the first category for which sni is not null
      category: categories.find((cat) => cat.sni),
      comment: '',
      // the user enter an amount of CO2, but to be able to match with potential error returned by the backend we call it intensity
      emissionIntensity: 0,
      recatType,
      emissionLayers: initLayers,
    },
    onSubmit: async (variables, { setErrors }) => {
      setLoading(true)
      try {
        const rules = createNewRules(variables)
        await addRule(rules, setErrors)
        setSnackbar({
          severity: 'success',
          children: 'Regeln har lagts till i listan över regler att tillämpa.',
        })
        return rules
      } catch (err) {
        setSnackbar({
          severity: 'error',
          children: 'Ett fel inträffade när regeln skulle sparas',
        })
      } finally {
        setLoading(false)
      }
    },

    validateOnChange: true,

    validationSchema: Yup.object().shape({
      emissionIntensity: Yup.number().when('recatType', (val) => {
        if (val?.length > 0 && val[0] === RecatType.SET_EMISSIONS) {
          return Yup.number()
            .min(0, 'Utsläppen ska vara ett nolltal eller ett positivt tal!')
            .max(totalKr, 'Utsläppen ska vara mindre än ett kg CO2 per kr')
            .required()
        }
        return Yup.number().notRequired()
      }),
      comment: Yup.string().when('recatType', (val) => {
        if (val?.length > 0 && val[0] === RecatType.SET_EMISSIONS) {
          return Yup.string().max(255, 'max 255 tecken').required()
        }
        return Yup.string().max(255, 'max 255 tecken')
      }),
      category: Yup.object().when((val) => {
        if (val?.length > 0 && val[0] === RecatType.CHANGE_CATEGORY) {
          return Yup.object().required()
        }
        return Yup.object().notRequired()
      }),
    }),
  })

  const switchRecatType = (type: RecatType) => {
    resetForm()
    setFieldValue('recatType', type)
    setRecatType(type)
  }

  const addRule = useCallback(
    async (
      rule: RecatRuleInput,
      setErrors: (errors: FormikErrors<unknown>) => void,
    ) => {
      try {
        await addRecatRules({
          variables: {
            rule: rule,
          },
        })
      } catch (err) {
        let hasSetError = false
        if (err instanceof ApolloError) {
          if (err.graphQLErrors) {
            err.graphQLErrors.forEach((gqlError) => {
              if (gqlError.extensions?.code === 'BAD_USER_INPUT') {
                const result = handleValidationError(
                  setErrors,
                  gqlError.extensions.validationErrors,
                )
                if (result) {
                  hasSetError = true
                }
              }
            })
          }
        }
        if (!hasSetError) {
          captureException(err)
          setSnackbar({
            severity: 'error',
            children: 'Ett fel inträffade när regeln skulle sparas',
          })
        }
      }
    },
    [addRecatRules],
  )

  return (
    <Flex column stretchWidth style={{ gap: spacing.medium }}>
      <SnackAlert
        open={!!snackBar}
        onClose={() => setSnackbar(null)}
        {...snackBar}
      />
      <Body2Bold data-testid='job-error'>Skapa regler</Body2Bold>
      <RulesBreadCrumbs recatType={recatType} setRecatType={switchRecatType} />
      {recatType === RecatType.CHOOSE && (
        <SelectRecatType switchRecatType={switchRecatType} />
      )}
      {recatType === RecatType.CHANGE_CATEGORY && (
        <ChangeCatBox
          formValues={formValues}
          handleBlur={handleBlur}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          setFieldValue={setFieldValue}
        />
      )}
      {recatType === RecatType.SET_EMISSIONS && (
        <SetEmissionsBox
          formValues={formValues}
          handleBlur={handleBlur}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          formErrors={formErrors}
          touched={touched}
        />
      )}
      <Flex column style={{ gap: spacing.medium }}>
        {containsNotRecatable && recatType !== RecatType.CHOOSE && (
          <div
            style={{ background: colors.orange, padding: spacing.small }}
            data-testid='rule-warning'
          >
            Varning: en del av transaktionen kan inte omkategoriseras. Regeln
            kommer endast att gälla för de andra transaktionerna.
          </div>
        )}
      </Flex>
      <RecatButtons
        disabled={snackBar?.severity === 'error' || loading}
        recatType={recatType}
        setRecatType={setRecatType}
      />
    </Flex>
  )
}
