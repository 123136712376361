import { useFormik } from 'formik'
import { ButtonBase, TextField } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { captureException } from '@sentry/browser'
import { Flex } from '../../components/Flex'
import { UserContext } from '../../context/UserContext'
import {
  MeDocument,
  MeQuery,
  useAuthenticationMutation,
} from '../../graphql/generated'
import { spacing } from '../../theme'
import { setToken } from '../../utils/auth'
import { toErrorMap } from '../../utils/toErrorMap'
import { Form } from './components/Form'
import { ButtonGreen } from '../../components/Buttons'
import { appHistory } from '../../utils/history'

interface Props {
  setError: React.Dispatch<React.SetStateAction<string>>
}

export function PasswordLogin({ setError }: Props): React.JSX.Element {
  const [login] = useAuthenticationMutation()
  const history = useHistory()
  const { setLoggedIn } = useContext(UserContext)

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    handleBlur,
    errors,
    isValid,
  } = useFormik({
    initialValues: { username: '', password: '' },
    validate: (v) => {
      const err: any = {}
      if (!v.username) {
        err.username = 'Required'
      }
      if (!v.password) {
        err.password = 'Required'
      }
      return err
    },
    onSubmit: async (v, { setErrors }) => {
      try {
        const res = await login({
          variables: {
            username: v.username,
            password: v.password,
          },
          update: (cache, { data }) => {
            if (data?.login.user) {
              cache.writeQuery<MeQuery>({
                query: MeDocument,
                data: {
                  __typename: 'Query',
                  me: data?.login.user,
                },
              })
            }
          },
        })

        if (res.data?.login.errors) {
          setErrors(toErrorMap(res.data.login.errors))
          return
        }

        if (res.data?.login.authentication) {
          const { token, expiresAt, refreshToken } =
            res.data.login.authentication
          setToken(token, refreshToken, expiresAt)
          setLoggedIn(true)
          history.push('/')
          return
        }
        // we should never reach this point as if the data were not set an error should have been thrown
        // and if data was set we should have gone in one of the if statements above
        throw new Error('No data returned')
      } catch (err) {
        captureException(err)
        setError('Något gick fel, försök igen senare.')
      }
    },
  })

  useEffect(() => {
    if (
      (values.password && errors.password && errors.password !== 'Required') ||
      (values.username && errors.username && errors.username !== 'Required')
    ) {
      setError(
        'Felaktigt användarnamn eller lösenord! Vänligen kontrollera stavning.',
      )
    } else {
      setError('')
    }
  }, [values, errors, isValid, setError])

  return (
    <Form
      onSubmit={handleSubmit}
      data-testid='password-login'
      style={{ width: '100%', gap: spacing.medium }}
    >
      <TextField
        type='text'
        name='username'
        label='Användarnamn'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.username}
        variant='standard'
        placeholder='fyll i ditt användarnamn'
        slotProps={{ inputLabel: { shrink: true } }}
        error={!!touched.username && !!errors.username}
        helperText={touched.username && errors.username}
        required
      />
      <TextField
        type='password'
        name='password'
        label='Lösenord'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
        variant='standard'
        placeholder='fyll i ditt lösenord'
        slotProps={{ inputLabel: { shrink: true } }}
        error={!!touched.password && !!errors.password}
        helperText={touched.password && errors.password}
        required
      />
      <ButtonGreen type='submit'>Logga In</ButtonGreen>
      <Flex column itemsCenter>
        <ButtonBase onClick={() => appHistory.push('/forgot-password')}>
          Glömt lösenordet
        </ButtonBase>
      </Flex>
    </Form>
  )
}
