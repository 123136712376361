import React, { useContext } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Tooltip } from '@mui/material'
import { captureException } from '@sentry/browser'
import {
  Job,
  Role,
  useGetAndProcessFortnoxDataMutation,
  useMeQuery,
} from '../../graphql/generated'
import { ButtonGreenBorder } from '../Buttons'
import { Loader } from '../Loader'
import { FortnoxContext } from '../../context/FortnoxContext'
import { JobContext } from '../../context/JobContext'
import { sideColumnMinWidthRem } from '../TableOfContent'
import { ErrorSnackBarContext } from '../../context/ErrorSnackBarContex'

export function FortnoxButton(): React.JSX.Element {
  const { data: meData } = useMeQuery() // this is called inside AppDataContext which will handle the error
  const user = meData?.me
  const { jobRunning, setJobRunning, handleJob, currentState, jobSnack } =
    useContext(JobContext)
  const { permissionError } = useContext(FortnoxContext)
  const [getAndProcessFortnoxData] = useGetAndProcessFortnoxDataMutation()

  const { setError } = useContext(ErrorSnackBarContext)

  const getData = async () => {
    setJobRunning(true)
    try {
      const response = await getAndProcessFortnoxData()
      handleJob(response.data?.getAndProcessFortnoxData as Job)
    } catch (error) {
      captureException(error)
      setError()
    }
  }

  const canPull =
    [Role.Admin, Role.SysAdmin, Role.Accountant].includes(
      user?.role ?? Role.User,
    ) && !permissionError.error

  return (
    <Tooltip
      title={
        !canPull
          ? (permissionError.error ?? 'du saknar behörighet för denna åtgärd')
          : 'Hämta data från Fortnox'
      }
    >
      <span>
        <ButtonGreenBorder
          style={{
            fontWeight: 'bold',
            // keep the button in the dimmention of the remaining of the header
            height: '33px',
            // avoid the button changing size while processing data
            // using `rem` based on the expected longest text,
            // in the expected fonts.
            minWidth: `${sideColumnMinWidthRem}rem`,
          }}
          id='fortnox-button'
          data-testid='fortnox-button'
          onClick={getData}
          disabled={jobRunning || jobSnack.severity === 'error' || !canPull}
          startIcon={
            jobRunning ? (
              <Loader size={15} borderSize={4} />
            ) : (
              <ArrowDownwardIcon />
            )
          }
        >
          {/* 
              `text-wrap` is a relatively new in CSS (2023/2024), and is not in React's types.
              If it exists it will be applied, and will prevent the button text from
              breaking into multiple lines, even if the user sets a different font size/family.
            */}
          <span style={{ textWrap: 'nowrap' } as React.CSSProperties}>
            {currentState ? `${currentState}` : 'Hämta data'}
          </span>
        </ButtonGreenBorder>
      </span>
    </Tooltip>
  )
}
