import { useFormik } from 'formik'
import React, { useState } from 'react'
import { TextField } from '@mui/material'
import * as Yup from 'yup'
import { captureException } from '@sentry/browser'
import { Body1, H5, S2Bold } from '../../components/Typography'
import { useForgotPasswordMutation } from '../../graphql/generated'
import { Form } from './components/Form'
import { ButtonGreen } from '../../components/Buttons'
import { LoginPageWrapper } from './components/LoginPageWrapper'
import { RedirectToLanding } from './registration/RegistrationPage'
import { SnackAlert, SnackAlertProps } from '../../components/SnackAlert'
import { spacing } from '../../theme'
import { toErrorMap } from '../../utils/toErrorMap'

export function ForgotPassword(): React.JSX.Element {
  const [snackbar, setSnackbar] = useState<SnackAlertProps>({})
  const [forgotPassword] = useForgotPasswordMutation()

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    isSubmitting,
    isValid,
    errors,
  } = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (val, { setErrors }) => {
      try {
        const result = await forgotPassword({ variables: val })
        if (result.data) {
          if (result.data?.forgotPassword.errors) {
            const errorMap = toErrorMap(result.data?.forgotPassword.errors)
            setErrors(errorMap)
          } else {
            setSnackbar({
              open: true,
              severity: 'success',
              children:
                'Ett meddelande har skickats till din e-post om adressen finns registrerad hos oss.',
              onClose: () => setSnackbar({}),
            })
          }
          return
        }
        // we should never reach this point as if the data were not set an error should have been thrown
        throw new Error('No data returned')
      } catch (err) {
        captureException(err)
        setSnackbar({
          open: true,
          severity: 'error',
          children: 'Något gick fel, försök igen senare.',
          onClose: () => setSnackbar({}),
        })
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('ogiltig e-post').required('Nödvändig'),
    }),
  })

  return (
    <LoginPageWrapper redirectBlock={<RedirectToLanding />}>
      <SnackAlert {...snackbar} />
      <S2Bold data-testid='account-creation'>Logga in</S2Bold>
      <H5>Glömt lösenord?</H5>
      <Body1>
        Ange din e-postadress för att få instruktioner om hur du återställer
        ditt lösenord.
      </Body1>
      <Form
        onSubmit={handleSubmit}
        data-testid='password-login'
        style={{ width: '100%', gap: spacing.medium }}
      >
        <TextField
          type='email'
          name='email'
          label='E-post'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          variant='standard'
          placeholder='fyll i din e-post'
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
          error={!!touched.email && !!errors.email}
          helperText={touched.email && errors.email}
          required
          data-testid='email-field'
        />

        <ButtonGreen
          type='submit'
          disabled={isSubmitting || !isValid}
          data-testid='submit-button'
        >
          Återställ lösenord
        </ButtonGreen>
      </Form>
    </LoginPageWrapper>
  )
}
