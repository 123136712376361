import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { captureException } from '@sentry/browser'
import { FortnoxContext } from '../../../context/FortnoxContext'
import { Body1, H5Bold, H6Bold } from '../../../components/Typography'
import { Loader } from '../../../components/Loader'
import { Flex, FlexingBox } from '../../../components/Flex'
import { spacing } from '../../../theme'
import { JobContext } from '../../../context/JobContext'
import { Role, useMeQuery, useUsersQuery } from '../../../graphql/generated'
import { Link } from '../../../components/Link'
import { ErrorSnackBarContext } from '../../../context/ErrorSnackBarContex'

const Container = styled(FlexingBox)`
  margin-top: ${spacing.large};
  margin-right: ${spacing.large};
  max-width: 680px;
  padding: ${spacing.xl};
  gap: ${spacing.xxxl};
`

export function FortnoxNoDataView(): React.JSX.Element {
  const { data: meData } = useMeQuery() // this is called inside AppDataContext which will handle the error
  const user = meData?.me
  const { jobRunning, currentState } = useContext(JobContext)
  const { permissionError } = useContext(FortnoxContext)
  const { data: usersData, error: userDataError } = useUsersQuery({
    variables: {},
    skip: ![Role.Admin, Role.SysAdmin].includes(user?.role ?? Role.User),
  })

  const { setError } = useContext(ErrorSnackBarContext)
  useEffect(() => {
    if (userDataError) {
      captureException(userDataError)
      setError()
    }
  }, [userDataError, setError])

  const hasInvitedAccountants = !!usersData?.users.find(
    (u) => u.invited && [Role.Admin, Role.Accountant].includes(u.role),
  )

  if (jobRunning) {
    return (
      <>
        <H5Bold>Här kommer det visas grafer över ert klimatavtryck sen!</H5Bold>
        <Body1 data-testid='working'>
          Vi laddar och behandlar era uppgifter.
        </Body1>
        <Loader size={80} borderSize={20} />
        <H6Bold>{currentState ?? 'Få data från Fortnox'}</H6Bold>
      </>
    )
  }
  if ([Role.Admin, Role.SysAdmin].includes(user?.role ?? Role.User)) {
    if (permissionError?.hasBeenSet) {
      if (permissionError.error) {
        // the user does not have the permissions to get the data from fortnox
        if (hasInvitedAccountants) {
          // there is an accountant (it can also be an admin) that has not logged in to the CIS yet
          return (
            <Container>
              <Body1>
                Det verkar som att personen med bokföringslicens i Fortnox inte
                har loggat in än. Vi saknar därför ert godkännande för att hämta
                data från Fortnox.
              </Body1>
              <Body1>
                <span>
                  Läs mer om behörighet och inställningar i Fortnox i vår
                </span>
                <Link
                  href='https://svalna.gitlab.io/business-intelligence-frontend/permissions'
                  target='_blank'
                  rel='noreferrer'
                >
                  dokumentation
                </Link>
                <span>.</span>
              </Body1>
            </Container>
          )
        }
        // all the accountants and admins have logged in but we still don't have the data and no job is running
        return (
          <Container>
            <Body1 data-testId='no-permission-admin'>
              Det verkar som att du eller någon annan med bokföringslicens i
              Fortnox inte har haft rätt inställningar aktiverade innan ni
              loggat in i vårt system. Vi saknar därför ert godkännande för att
              hämta data från Fortnox.
            </Body1>
            <Body1>
              <span>
                Läs mer om behörighet och inställningar i Fortnox i vår
              </span>{' '}
              <Link
                href='https://svalna.gitlab.io/business-intelligence-frontend/permissions'
                target='_blank'
                rel='noreferrer'
              >
                dokumentation
              </Link>
              <span>.</span>
            </Body1>
          </Container>
        )
      }
      return (
        <Body1>
          Vi kommer att börja ladda ner och behandla dina uppgifter inom kort.
        </Body1>
      )
    }
  } else if (user) {
    // if it is not an admin the user can't load the data from Fortnox
    return (
      <Container>
        <H5Bold data-testid='no-permission-user'>
          Vi saknar godkännde från er för att hämta data från Fortnox och kan
          därför inte visa resultat.
        </H5Bold>
        <Flex column style={{ gap: spacing.medium }}>
          <Body1>
            En revisor måste logga in och ladda ner data från Fortnox innan du
            kan se något resultat här.
          </Body1>
        </Flex>
      </Container>
    )
  }
  // If user or permissionError is not set yet just show a loading page
  return <Loader size={80} borderSize={20} />
}
