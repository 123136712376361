import React, { useCallback, useContext, useEffect, useState } from 'react'
import { captureException } from '@sentry/browser'
import Svalna from '../@types'
import {
  Job,
  JobError,
  JobErrorType,
  Maybe,
  MeDocument,
  Role,
  useCheckPermissionsMutation,
  useDeleteErrorJobMutation,
  useGetAndProcessFortnoxDataMutation,
  useMeQuery,
} from '../graphql/generated'
import { AppDataContext } from './AppDataContext'
import { JobContext } from './JobContext'
import { SnackAlert, SnackAlertProps } from '../components/SnackAlert'
import { goToFortnox } from '../utils/FortnoxUtils'
import { Body1, Body2 } from '../components/Typography'
import { Link } from '../components/Link'
import { ButtonWhiteBorder } from '../components/Buttons'
import { Flex } from '../components/Flex'
import { spacing } from '../theme'
import { ErrorSnackBarContext } from './ErrorSnackBarContex'

export type FortnoxContextType = {
  tokenexpired: boolean
  permissionError: {
    error: string
    hasBeenSet: boolean
  }
}

const initialValues: FortnoxContextType = {
  tokenexpired: false,
  permissionError: { error: '', hasBeenSet: false },
}

export const FortnoxContext =
  React.createContext<FortnoxContextType>(initialValues)

const { Provider } = FortnoxContext

export function FortnoxProvider({
  children,
}: Svalna.PropWithChildren): React.JSX.Element {
  const { organisation, orgHasInitializedData } = useContext(AppDataContext)
  const { handleJob, setFortnoxErrorHandler, fortnoxErrorHandler } =
    useContext(JobContext)
  const [tokenexpired, setTokenExpired] = useState(false)
  const [permissionError, setPermissionError] = useState({
    error: '',
    hasBeenSet: false,
  })
  const [getAndProcessFortnoxData] = useGetAndProcessFortnoxDataMutation()

  const [checkPermissions] = useCheckPermissionsMutation({
    // the user may be set as admin
    refetchQueries: [{ query: MeDocument }],
    awaitRefetchQueries: true,
  })

  const { setError } = useContext(ErrorSnackBarContext)

  useEffect(() => {
    if (organisation.orgType !== 'fortnox') {
      setPermissionError({
        error: 'not a fortnox organisation',
        hasBeenSet: true,
      })
      return
    }
    checkPermissions()
      .then((result) => {
        if (
          result.data?.checkPermissions.error?.type ===
          JobErrorType.Permissiondenied
        ) {
          setPermissionError({
            error: result.data.checkPermissions.error.message,
            hasBeenSet: true,
          })
        } else if (
          result.data?.checkPermissions.error?.type ===
          JobErrorType.Tokenexpired
        ) {
          setTokenExpired(true)
        } else {
          setPermissionError({ error: '', hasBeenSet: true })
        }
      })
      .catch((error) => {
        captureException(error)
        setPermissionError({
          error: 'Något gick fel när vi försökte kontrollera behörigheten',
          hasBeenSet: true,
        })
      })
  }, [checkPermissions, organisation.orgType, setError])

  const errorHandler = useCallback((error: Maybe<JobError> | undefined) => {
    if (error?.type === JobErrorType.Tokenexpired) {
      setTokenExpired(true)
      return true
    }
    return false
  }, [])

  useEffect(() => {
    // When given a function as argument the state setter handle it as a function that return the state
    // so to set a function as a state we need to pass a function that return a function
    setFortnoxErrorHandler(() => errorHandler)
  }, [errorHandler, setFortnoxErrorHandler])

  const getData = useCallback(async () => {
    try {
      const response = await getAndProcessFortnoxData()
      handleJob(response.data?.getAndProcessFortnoxData as Job)
    } catch (error) {
      captureException(error)
      setError()
    }
  }, [getAndProcessFortnoxData, handleJob, setError])

  useEffect(() => {
    if (
      permissionError.hasBeenSet &&
      !permissionError.error &&
      fortnoxErrorHandler &&
      !orgHasInitializedData
    ) {
      getData()
    }
  }, [getData, orgHasInitializedData, permissionError, fortnoxErrorHandler])

  const [deleteErrorJob] = useDeleteErrorJobMutation()
  const [snackbar, setSnackbar] = useState<SnackAlertProps>({ open: false })
  const { data: meData } = useMeQuery() // this is called inside AppDataContext which will handle the error

  const renewFortnoxToken = useCallback(async () => {
    try {
      await deleteErrorJob()
      await goToFortnox()
    } catch (error) {
      captureException(error)
      setError()
    }
  }, [deleteErrorJob, setError])

  useEffect(() => {
    if (tokenexpired) {
      setSnackbar({
        open: true,
        severity: 'error',
        action: (
          <ButtonWhiteBorder size='small' onClick={renewFortnoxToken}>
            Anslut
          </ButtonWhiteBorder>
        ),
        children: (
          <Flex style={{ gap: spacing.medium }}>
            <Body1>
              Fortnox-anslutningen har löpt ut, vänligen anslut igen
            </Body1>
          </Flex>
        ),
      })
    } else if (permissionError.error && meData?.me.role === Role.Accountant) {
      setSnackbar({
        open: true,
        severity: 'error',
        onClose: () => setSnackbar({ open: false }),
        children: (
          <Flex column stretchWidth style={{ gap: spacing.medium }}>
            <Body1 data-testId='no-permission-admin'>
              Det verkar som att du inte har haft rätt inställningar aktiverade
              innan du loggat in i vårt system. Du saknar därför behörigheten
              att hämta data från Fortnox.
            </Body1>
            <Body1>
              <span>
                Läs mer om behörighet och inställningar i Fortnox i vår
              </span>{' '}
              <Link
                href='https://svalna.gitlab.io/business-intelligence-frontend/permissions'
                target='_blank'
                rel='noreferrer'
              >
                dokumentation
              </Link>
              <span>.</span>
            </Body1>
            <Body2>({permissionError.error})</Body2>
          </Flex>
        ),
      })
    }
  }, [meData?.me.role, permissionError.error, renewFortnoxToken, tokenexpired])

  return (
    <Provider
      value={{
        tokenexpired,
        permissionError,
      }}
    >
      <SnackAlert {...snackbar} />
      {children}
    </Provider>
  )
}
