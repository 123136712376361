import React from 'react'
import { AlertProps } from '@mui/material'
import { Body1, Body1Bold } from '../../../components/Typography'
import { Flex, InnerBoxText } from '../../../components/Flex'
import { AddUserForm } from '../../settings/AddUserForm'
import { Role } from '../../../graphql/generated'
import { Link } from '../../../components/Link'
import { getAccountingRolesMap } from '../../../utils/roles'
import { colors, spacing } from '../../../theme'

interface Props {
  setSnackbar: React.Dispatch<
    React.SetStateAction<Pick<AlertProps, 'children' | 'severity'> | null>
  >
  goToNextStep: () => void
}
export function InviteAccountantStep({
  goToNextStep,
  setSnackbar,
}: Props): React.JSX.Element {
  const setError = (error: string) => {
    setSnackbar({
      children: error,
      severity: 'error',
    })
  }

  return (
    <>
      <InnerBoxText>
        <Body1Bold data-testid='accountant-step'>
          Bjud in din ekonomiadministratör
        </Body1Bold>
        <Body1>
          För att beräkningarna ska kunna påbörjas behöver Svalna få tillgång
          till bokföringsdata. Detta måste godkännas av den person hos er som
          har en bokföringslicens i Fortnox, vilket vanligtvis är en
          ekonomiadministratör eller revisor. Den personen behöver bara logga in
          och godkänna villkoren. Så snart det är klart börjar systemet hämta
          och behandla befintlig bokföringsdata från ert bokföringssystem. När
          analysen är färdig kan ni ta del av resultatet här i verktyget - det
          brukar gå snabbt!
        </Body1>
        <Body1>
          Lägg till din ekonomiadministratör via formuläret nedan. När du
          klickar på fortsätt skickar vi ett mail med all nödvändig information
          till den angivna adressen.
        </Body1>
        <Body1>
          Är det du själv som har rätt licens i Fortnox? Se till att du har rätt
          inställningar i Fortnox med hjälp av dokumentationen nedan. Kom sedan
          tillbaka och ladda om den här sidan för att gå vidare.
        </Body1>
        <Body1>
          <span>Läs mer om behörighet och inställningar i Fortnox i vår</span>{' '}
          <Link
            href='https://svalna.gitlab.io/business-intelligence-frontend/permissions'
            target='_blank'
            rel='noreferrer'
          >
            dokumentation
          </Link>
          <span>.</span>
        </Body1>
      </InnerBoxText>
      <Flex
        stretchWidth
        style={{
          borderTop: `1px solid ${colors.gray}`,
          paddingTop: spacing.medium,
        }}
      >
        <AddUserForm
          setError={setError}
          roleOptions={getAccountingRolesMap()}
          defaultRole={Role.Accountant}
          onSuccess={() => goToNextStep()}
          buttonText='FORTSÄTT'
          setSnackbar={setSnackbar}
        />
      </Flex>
    </>
  )
}
