import React from 'react'
import styled from 'styled-components'
import { colors } from '../theme'

const Element = styled('div')(({ theme }) => ({
  border: `1px solid ${(theme.vars || theme).palette.divider}`,
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: 26,
  borderRadius: 2,
}))

const Value = styled('div')({
  position: 'absolute',
  lineHeight: '24px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
})

export enum Status {
  Bad,
  Medium,
  Good,
}
interface BarProps {
  level: Status
}
const Bar = styled.div.withConfig({
  shouldForwardProp: (prop) => !['level'].includes(prop),
})<BarProps>`
  height: 100%;
  background-color: ${({ level }) => {
    switch (level) {
      case Status.Bad:
        return colors.red
      case Status.Medium:
        return colors.orange
      default:
        return colors.deepGreen
    }
  }};
`

function defaultGetStatus(valueInPercent: number): Status {
  if (valueInPercent < 10) {
    return Status.Bad
  }
  if (valueInPercent < 50) {
    return Status.Medium
  }
  return Status.Good
}

export function reverseGetStatus(valueInPercent: number): Status {
  if (valueInPercent < 10) {
    return Status.Good
  }
  if (valueInPercent < 50) {
    return Status.Medium
  }
  return Status.Bad
}

interface ProgressBarProps {
  value: number
  getStatus?: (valueInPercent: number) => Status
}

export function ProgressBar({
  value,
  getStatus = defaultGetStatus,
}: ProgressBarProps): React.JSX.Element {
  const valueInPercent = value * 100

  return (
    <Element>
      <Value>{`${valueInPercent.toLocaleString()} %`}</Value>
      <Bar
        level={getStatus(valueInPercent)}
        style={{ maxWidth: `${valueInPercent}%` }}
      />
    </Element>
  )
}
