import React from 'react'
import { Body1, Body1Bold } from '../../../components/Typography'
import { ButtonGreen } from '../../../components/Buttons'
import { InnerInnerBox } from '../../../components/Flex'

export function ProfileIntroStep({
  goToNextStep,
}: {
  goToNextStep: () => void
}): React.JSX.Element {
  return (
    <>
      <Body1Bold data-testid='profile-intro-step'>Klimatprofilen</Body1Bold>
      <InnerInnerBox>
        <Body1>Nu är det dags att fylla i klimatprofilen!</Body1>
        <Body1>
          Vi beräknar era utsläpp baserat på data från ert ekonomisystem och
          offentliga register. Hjälp oss genom att kontrollera och komplettera
          informationen där det behövs. Om ni inte har tillgång till all
          information just nu går det bra att hoppa över dessa steg och
          återkomma till profilen senare.
        </Body1>
      </InnerInnerBox>
      <ButtonGreen
        style={{ alignSelf: 'center' }}
        onClick={() => {
          goToNextStep()
        }}
        data-testid='start-profile-button'
      >
        Starta klimatprofilen
      </ButtonGreen>
    </>
  )
}
