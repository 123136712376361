import React from 'react'
import { AlertProps } from '@mui/material'
import { UpdatePaymentInfoForm } from './UpdatePaymentInfoForm'
import { Box } from '../../components/Box'
import { ProfileBoxContent } from '../landing/ProfileSteps/styles'

export function UpdatePaymentInfoBox({
  setSnackbar,
}: {
  setSnackbar: React.Dispatch<
    React.SetStateAction<Pick<AlertProps, 'children' | 'severity'> | null>
  >
}): React.JSX.Element {
  const onSuccess = () => {
    setSnackbar({
      severity: 'success',
      children: 'Tack, uppgifterna har sparats.',
    })
  }

  return (
    <Box style={{ width: '820px' }}>
      <ProfileBoxContent>
        <UpdatePaymentInfoForm
          setSnackbar={setSnackbar}
          onSuccess={onSuccess}
          hidePolicyBox
        />
      </ProfileBoxContent>
    </Box>
  )
}
