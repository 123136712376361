import {
  GridActionsCellItem,
  gridEditRowsStateSelector,
  GridRowId,
  GridRowModes,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid'
import React from 'react'

export function GridActionItem({
  modeToShowItemIn,
  rowId,
  ...props
}: React.ComponentProps<typeof GridActionsCellItem> & {
  rowId: GridRowId
  modeToShowItemIn: GridRowModes
}): React.JSX.Element | null {
  const apiRef = useGridApiContext()
  const rowModesModel = useGridSelector(apiRef, gridEditRowsStateSelector)

  const currentMode =
    typeof rowModesModel[rowId] === 'undefined'
      ? GridRowModes.View
      : GridRowModes.Edit

  if (currentMode !== modeToShowItemIn) {
    return null
  }

  return <GridActionsCellItem {...props} />
}
