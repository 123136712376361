import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { captureException } from '@sentry/browser'
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
} from '@mui/x-data-grid'
import SaveIcon from '@mui/icons-material/Save'
import EditIcon from '@mui/icons-material/Edit'
import CancelIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import RsvpIcon from '@mui/icons-material/Rsvp'
import {
  AlertProps,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Tooltip,
} from '@mui/material'
import { ApolloError } from '@apollo/client'
import {
  Flex,
  FlexingBox,
  FlexingBoxContent,
  HeadRow,
} from '../../components/Flex'
import { Body1 } from '../../components/Typography'
import {
  MeDocument,
  OrgType,
  Role,
  UsersDocument,
  useChangeRestrictionModeMutation,
  useDeleteUserMutation,
  useMeQuery,
  useUpdateRegistrationCodeMutation,
  useUpdateRoleMutation,
  useUsersQuery,
} from '../../graphql/generated'
import { TitleWithSub } from '../../components/TitleWithSub'
import { AddUserForm } from './AddUserForm'
import { ButtonGreen, ButtonLightBorder } from '../../components/Buttons'
import { FullWidthBox } from '../../components/Box'
import { TooltipInfo } from '../../components/InfoIcon'
import { spacing } from '../../theme'
import { SnackAlert } from '../../components/SnackAlert'
import { getAllRolesMap } from '../../utils/roles'
import { ErrorSnackBarContext } from '../../context/ErrorSnackBarContex'
import { apolloErrorHandler } from '../../utils/errors'

type Row = {
  id: string
  firstName: string
  lastName: string
  username: string
  organisation: {
    name: string
    id: string
  }
  role: Role
  invited: boolean
}

interface Props {
  sysAdmin?: boolean
}

export function UserManagement({ sysAdmin }: Props): React.JSX.Element {
  const { data } = useMeQuery() // this is called inside AppDataContext which will handle the error
  const user = data?.me
  const { data: usersData, error } = useUsersQuery({
    variables: { allOrgs: sysAdmin },
  })

  const { setError: setErrorSnackContext } = useContext(ErrorSnackBarContext)
  useEffect(() => {
    if (error) {
      captureException(error)
      setErrorSnackContext()
    }
  }, [error, setErrorSnackContext])

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {},
  )
  const [rows, setRows] = React.useState<GridRowsProp<Row>>([])
  const [updateRole] = useUpdateRoleMutation()
  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)
  const [userToDelete, setUserToDelete] = React.useState<Row | undefined>(
    undefined,
  )
  const [doDeleteUser] = useDeleteUserMutation({
    refetchQueries: [{ query: UsersDocument }],
  })
  const [changeRestrictionMode] = useChangeRestrictionModeMutation({
    refetchQueries: [{ query: MeDocument }],
  })

  const [updateRegistrationCode] = useUpdateRegistrationCodeMutation()

  useEffect(() => {
    setRows(usersData?.users ?? [])
  }, [usersData, setRows])

  const handleEditClick = useCallback((id: GridRowId) => {
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit },
    }))
  }, [])

  const handleSaveClick = useCallback((id: GridRowId) => {
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.View },
    }))
  }, [])

  const handleCancelClick = useCallback((id: GridRowId) => {
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }))
  }, [])

  const setError = (errorMessage: string) => {
    setSnackbar({
      children: <Body1 data-testid='error-msg'>{errorMessage}</Body1>,
      severity: 'error',
    })
  }

  const handleDeleteClick = useCallback((row: Row) => {
    setUserToDelete(row)
  }, [])

  const handleUpdateRegistrationCode = useCallback(
    async (row: Row) => {
      try {
        if (!row?.id) {
          // should never happen
          throw new Error('did not find the user in the dataGrid')
        }
        const result = await updateRegistrationCode({
          variables: {
            id: row.id,
            organisationId:
              user?.role === Role.SysAdmin ? row.organisation.id : undefined,
          },
        })
        if (result.data) {
          setSnackbar({
            severity: 'success',
            children: (
              <Body1 data-testid='link-sent'>
                Användaren har lagts till och ett mail har skickats till dennes
                inkorg.
              </Body1>
            ),
          })
          return
        }
        // we should never reach this point as if the data were not set an error should have been thrown
        throw new Error('No data returned')
      } catch (err) {
        captureException(err)
        setError('Något gick fel')
      }
    },
    [updateRegistrationCode, user?.role],
  )

  const roleOptions = getAllRolesMap(user)

  const columns: GridColDef<Row>[] = useMemo(
    () => [
      { field: 'firstName', headerName: 'Förnamn', flex: 1 },
      { field: 'lastName', headerName: 'Efternamn', flex: 1 },
      { field: 'username', headerName: 'Användarnamn', flex: 1 },
      {
        field: 'role',
        headerName: 'Roll',
        flex: 1,
        editable: true,
        type: 'singleSelect',
        valueOptions: roleOptions,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Handlingar',
        width: 120,
        cellClassName: 'actions',
        getActions: ({ id, row }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                key={`save-${id}`}
                icon={<SaveIcon />}
                label='Save'
                sx={{
                  color: 'primary.main',
                }}
                onClick={() => handleSaveClick(id)}
              />,
              <GridActionsCellItem
                key={`cancel-${id}`}
                icon={<CancelIcon />}
                label='Cancel'
                className='textPrimary'
                onClick={() => handleCancelClick(id)}
                color='inherit'
              />,
            ]
          }

          const actions = [
            <GridActionsCellItem
              key={`edit-${id}`}
              icon={<EditIcon />}
              label='Edit'
              className='textPrimary'
              onClick={() => handleEditClick(id)}
              color='inherit'
            />,
            <GridActionsCellItem
              key={`edit-${id}`}
              icon={<DeleteIcon />}
              label='Delete'
              className='textPrimary'
              onClick={() => handleDeleteClick(row)}
              color='inherit'
              disabled={id === user?.id}
              data-testid={`delete-button-${id}`}
            />,
          ]

          if (row.invited) {
            actions.push(
              <GridActionsCellItem
                key={`rsvp-${id}`}
                icon={
                  <Tooltip title='Uppdatera registreringslänk'>
                    <RsvpIcon />
                  </Tooltip>
                }
                label='invite'
                className='textPrimary'
                onClick={() => handleUpdateRegistrationCode(row)}
                color='inherit'
                data-testid={`rsvp-button-${id}`}
              />,
            )
          }

          return actions
        },
      },
    ],
    [
      handleCancelClick,
      handleDeleteClick,
      handleEditClick,
      handleSaveClick,
      handleUpdateRegistrationCode,
      roleOptions,
      rowModesModel,
      user?.id,
    ],
  )

  if (sysAdmin) {
    columns.unshift({
      field: 'organisation',
      headerName: 'organisation',
      flex: 1,
      valueGetter: (value, row) => {
        return row.organisation.name
      },
    })
  }

  const processRowUpdate = async (newRow: GridRowModel<Row>) => {
    setError('')
    const updatedRow = { ...newRow }

    // errors are handled by the datagrid and handleProcessRowUpdateError
    await updateRole({
      variables: { userId: updatedRow.id, newRole: updatedRow.role },
    })

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)))
    return updatedRow
  }

  const handleProcessRowUpdateError = React.useCallback((err: ApolloError) => {
    captureException(err)
    setError(apolloErrorHandler(err))
  }, [])

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event,
  ) => {
    // do not get out of edit mode when clicking out of the cell
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const deleteUser = async () => {
    if (!userToDelete?.id) {
      return
    }
    try {
      await doDeleteUser({
        variables: {
          id: userToDelete.id,
          organisationId:
            user?.role === Role.SysAdmin
              ? userToDelete.organisation.id
              : undefined,
        },
      })
      setUserToDelete(undefined)
    } catch (err) {
      captureException(err)
      setError('something went wrong when deleting the user')
    }
  }

  const handleRestrictedAccessClick = async (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    try {
      await changeRestrictionMode({ variables: { restrictedAccess: checked } })
    } catch (err) {
      captureException(err)
      setError('something went wrong')
    }
  }

  return (
    <Flex column stretchWidth style={{ gap: spacing.large }}>
      {user?.organisation.orgType &&
        [OrgType.Fortnox].includes(user?.organisation.orgType) && (
          <FullWidthBox>
            <HeadRow>
              <TitleWithSub title='Konfiguration' sub='' />
            </HeadRow>
            <FormGroup style={{ padding: spacing.medium }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={user?.organisation.restrictedAccess}
                    onChange={handleRestrictedAccessClick}
                    data-testid='restricted-mod-box'
                  />
                }
                label={
                  <Flex style={{ gap: spacing.small }}>
                    <Body1>Endast registrerade användare kan logga in </Body1>
                    <TooltipInfo
                      info={
                        <Flex column>
                          <Body1>
                            Om detta är markerat måste du registrera användarna
                            med formuläret nedan innan de kan logga in med
                            Fortnox
                          </Body1>
                          <Body1>
                            Om detta inte är markerat kan någon som är
                            registrerad som användare i ditt
                            Fortnox-organisationskonto använda Fortnox för att
                            logga in.
                          </Body1>
                        </Flex>
                      }
                    />
                  </Flex>
                }
              />
            </FormGroup>
          </FullWidthBox>
        )}

      <FlexingBox style={{ width: '100%' }}>
        <HeadRow>
          <TitleWithSub title='Användare' sub='' />
        </HeadRow>
        <SnackAlert
          open={!!snackbar}
          onClose={() => setSnackbar(null)}
          {...snackbar}
        />
        <FlexingBoxContent>
          <Flex column stretchWidth itemsStretch style={{ minHeight: 217 }}>
            <DataGrid
              rows={rows ?? []}
              columns={columns}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[5, 10, 25, 50, 100]}
              editMode='row'
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              disableVirtualization
            />
          </Flex>
          <Dialog
            open={!!userToDelete}
            onClose={() => setUserToDelete(undefined)}
          >
            <DialogTitle align='center'>User deletion confirmation</DialogTitle>
            <Flex
              column
              style={{ margin: spacing.medium, gap: spacing.medium }}
            >
              <Body1>
                <span>Är du säker på att du vill ta bort användare</span>{' '}
                <b>{userToDelete?.username}</b>
              </Body1>
              <Flex row justifyEnd stretchWidth style={{ gap: spacing.medium }}>
                <ButtonGreen onClick={deleteUser} data-testid='yes-delete'>
                  Ja
                </ButtonGreen>
                <ButtonLightBorder
                  onClick={() => {
                    setUserToDelete(undefined)
                  }}
                  data-testid='no-delete'
                >
                  Nej
                </ButtonLightBorder>
              </Flex>
            </Flex>
          </Dialog>

          <Flex
            column
            stretchWidth
            style={{ gap: spacing.large }}
            data-testid='add-user-form'
          >
            <AddUserForm
              roleOptions={roleOptions}
              setError={setError}
              sysAdmin={sysAdmin}
              setSnackbar={setSnackbar}
            />
          </Flex>
        </FlexingBoxContent>
      </FlexingBox>
    </Flex>
  )
}
