import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { captureException } from '@sentry/browser'
import { Form } from '../../components/Form'
import { ButtonGreen } from '../../../../components/Buttons'
import { colors, spacing } from '../../../../theme'
import {
  useGetSubscriptionPlansQuery,
  useRegisterOrganisationMutation,
} from '../../../../graphql/generated'
import { toErrorMap } from '../../../../utils/toErrorMap'
import { RegistrationViews } from '../RegistrationPage'
import { H5, S2Bold } from '../../../../components/Typography'
import { Loader } from '../../../../components/Loader'

const NOTSET = 'NotSet'

const BookkeepingSystems = [
  NOTSET,
  'Fortnox',
  'Bokio',
  'Visma',
  'Annat',
] as const

type BookkeepingSystem = (typeof BookkeepingSystems)[number]

const bookkeepingSystemRegex = new RegExp(
  BookkeepingSystems.filter((value) => value !== NOTSET).join('|'),
)

type FormValues = {
  orgNumber: string
  email: string
  bookkeepingSystem: BookkeepingSystem
  otherBookingSystem: string
  acceptPolicies: boolean
  subscriptionPlanName: string
}

const initialValues: FormValues = {
  orgNumber: '',
  email: '',
  bookkeepingSystem: NOTSET,
  otherBookingSystem: '',
  acceptPolicies: false,
  subscriptionPlanName: NOTSET,
}

export function RegistrationForm({
  setError,
  setView,
  setRegistrationCode,
  setConfirmationCode,
}: {
  setError: React.Dispatch<React.SetStateAction<string>>
  setView: React.Dispatch<React.SetStateAction<RegistrationViews>>
  setRegistrationCode: React.Dispatch<React.SetStateAction<string>>
  setConfirmationCode: React.Dispatch<React.SetStateAction<string>>
}): React.JSX.Element {
  const [registerOrganisation] = useRegisterOrganisationMutation()
  const {
    data: subscriptionPlanData,
    loading: subscriptionPlanLoading,
    error: subscriptionPlanError,
  } = useGetSubscriptionPlansQuery()

  const location = useLocation()

  useEffect(() => {
    if (subscriptionPlanError) {
      setError('något gick fel, vänligen ladda om sidan')
    }
  }, [subscriptionPlanError, setError])

  const registerSchema = Yup.object().shape({
    orgNumber: Yup.string()
      .matches(
        /^[0-9]{6}-[0-9]{4}$/,
        'Det är inte ett giltigt organisationsnummer',
      )
      .required('Nödvändig'),
    email: Yup.string().email('Ogiltig e-postadress').required('Nödvändig'),
    bookkeepingSystem: Yup.string()
      .notOneOf([NOTSET], 'Nödvändig')
      .matches(bookkeepingSystemRegex, 'felaktigt bokföringssystem')
      .required('Nödvändig'),
    otherBookingSystem: Yup.string().when('bookkeepingSystem', (val) => {
      if (val?.length > 0 && val[0] === 'Annat') {
        return Yup.string().max(255, 'max 255').required('Nödvändig')
      } else {
        return Yup.string().notRequired()
      }
    }),
    acceptPolicies: Yup.boolean()
      .isTrue('Du måste acceptera villkoren')
      .required('Du måste acceptera villkoren'),
    subscriptionPlanName: Yup.string()
      .notOneOf([NOTSET], 'Nödvändig')
      .oneOf(
        subscriptionPlanData?.getSubscriptionPlans.map((plan) => plan.name) ??
          [],
        'Ogiltig prisplan',
      )
      .required('Nödvändig'),
  })

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    isSubmitting,
    setFieldTouched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: registerSchema,
    onSubmit: async (v, { setErrors }) => {
      try {
        const result = await registerOrganisation({
          variables: {
            orgNumber: v.orgNumber,
            email: v.email,
            bookingSystem:
              v.bookkeepingSystem === 'Annat'
                ? v.otherBookingSystem
                : v.bookkeepingSystem,
            acceptPolicies: v.acceptPolicies,
            subscriptionPlanName: v.subscriptionPlanName,
          },
        })
        if (result.data) {
          if (result.data.registerOrganisation.errors) {
            setErrors(toErrorMap(result.data.registerOrganisation.errors))
            return
          }

          setRegistrationCode(result.data.registerOrganisation.id ?? '')
          setConfirmationCode(
            result.data.registerOrganisation.deletionConfirmationCode ?? '',
          )
          if (result.data.registerOrganisation.canRegister) {
            setView(RegistrationViews.canRegister)
          } else {
            setView(RegistrationViews.cannotRegister)
          }
          return
        }
        // we should never reach this point as if the data were not set an error should have been thrown
        throw new Error('No data returned')
      } catch (error) {
        captureException(error)
        setError('Något gick fel, försök igen senare.')
      }
    },
  })

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const plan = queryParams.get('plan')
    if (plan) {
      setFieldValue('subscriptionPlanName', plan)
    }
  }, [setFieldValue, location.search])

  // we want the form to display an error if the plan passed in the query params does not match any proper plan name
  // if we call setFieldTouched in the above useEffect then the validation is done before the value is set (even if we call the setFieldTouched after setFieldValue)
  // so we call it in its own useEffect
  useEffect(() => {
    if (
      values.subscriptionPlanName !== NOTSET &&
      subscriptionPlanData?.getSubscriptionPlans
    ) {
      setFieldTouched('subscriptionPlanName')
    }
  }, [
    setFieldTouched,
    subscriptionPlanData?.getSubscriptionPlans,
    values.subscriptionPlanName,
  ])

  return (
    <>
      <S2Bold>Skicka förfrågan om testkonto</S2Bold>
      <H5>Fyll i info om ditt företag</H5>
      <Form
        onSubmit={handleSubmit}
        style={{ width: '100%', gap: spacing.medium }}
      >
        <TextField
          type='text'
          name='orgNumber'
          label='Organisationsnummer'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.orgNumber}
          variant='standard'
          placeholder='xxxxxx-xxxx'
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
          error={!!touched.orgNumber && !!errors.orgNumber}
          helperText={touched.orgNumber && errors.orgNumber}
          required
        />

        <TextField
          type='email'
          name='email'
          label='Företagsmailadress'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          variant='standard'
          placeholder='namn@domän.se'
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
          error={!!touched.email && !!errors.email}
          helperText={touched.email && errors.email}
          required
        />

        <FormControl fullWidth variant='standard'>
          <InputLabel
            id='subscription-plan-label'
            required
            error={
              !!touched.subscriptionPlanName && !!errors.subscriptionPlanName
            }
          >
            Prisplan
          </InputLabel>
          <Select
            name='subscriptionPlanName'
            labelId='subscription-plan-label'
            id='subscription-plan-select'
            label='Prisplan'
            onChange={handleChange}
            value={values.subscriptionPlanName}
            displayEmpty
            renderValue={
              values.subscriptionPlanName !== NOTSET ? undefined : () => 'Välj'
            }
            error={
              !!touched.subscriptionPlanName && !!errors.subscriptionPlanName
            }
          >
            {subscriptionPlanLoading && (
              <MenuItem value={NOTSET} key={NOTSET}>
                <Loader size={20} borderSize={4} />
                <span>&nbsp;Laddar</span>
              </MenuItem>
            )}
            {subscriptionPlanData?.getSubscriptionPlans.map((plan) => {
              return (
                <MenuItem value={plan.name} key={plan.name}>
                  {plan.name}
                </MenuItem>
              )
            })}
            {
              // if we get a wrong value in the query param, set it as an option so that it gets displayed
              values.subscriptionPlanName !== NOTSET &&
                !subscriptionPlanData?.getSubscriptionPlans.find(
                  (p) => p.name === values.subscriptionPlanName,
                ) && (
                  <MenuItem
                    value={values.subscriptionPlanName}
                    key={values.subscriptionPlanName}
                  >
                    {values.subscriptionPlanName}
                  </MenuItem>
                )
            }
          </Select>
          {touched.subscriptionPlanName && errors.subscriptionPlanName && (
            <FormHelperText style={{ color: colors.red }}>
              {errors.subscriptionPlanName}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth variant='standard'>
          <InputLabel
            id='accounting-system-label'
            required
            error={!!touched.bookkeepingSystem && !!errors.bookkeepingSystem}
          >
            Bokföringssystem
          </InputLabel>
          <Select
            name='bookkeepingSystem'
            labelId='accounting-system-label'
            id='accounting-system-select'
            label='Bokföringssystem'
            onChange={handleChange}
            value={values.bookkeepingSystem}
            displayEmpty
            renderValue={
              values.bookkeepingSystem !== NOTSET ? undefined : () => 'Välj'
            }
            error={!!touched.bookkeepingSystem && !!errors.bookkeepingSystem}
          >
            {BookkeepingSystems.map((system) => {
              if (system !== NOTSET) {
                return (
                  <MenuItem value={system} key={system}>
                    {system}
                  </MenuItem>
                )
              }
              return undefined
            })}
          </Select>
          {touched.bookkeepingSystem && errors.bookkeepingSystem && (
            <FormHelperText style={{ color: colors.red }}>
              {errors.bookkeepingSystem}
            </FormHelperText>
          )}
        </FormControl>

        {values.bookkeepingSystem === 'Annat' && (
          <TextField
            type='text'
            name='otherBookingSystem'
            label='Bokföringssystemets namn'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.otherBookingSystem}
            variant='standard'
            placeholder='Bokföringssystem'
            InputLabelProps={{ shrink: true }}
            error={!!touched.otherBookingSystem && !!errors.otherBookingSystem}
            helperText={touched.otherBookingSystem && errors.otherBookingSystem}
            required
            data-testid='otherBookingSystem'
          />
        )}

        <FormControl
          required
          error={touched.acceptPolicies && !!errors.acceptPolicies}
        >
          <FormControlLabel
            name='acceptPolicies'
            required
            onChange={handleChange}
            control={<Checkbox />}
            label={
              <>
                <span>Jag godkänner Svalnas</span>{' '}
                <a href='/user-agreement' target='_blank'>
                  användarvillkor
                </a>{' '}
                <span>och</span>{' '}
                <a href='/privacy-policy' target='_blank'>
                  integritetspolicy
                </a>
              </>
            }
            data-testid='acceptPolicies'
          />
          {touched.acceptPolicies && (
            <FormHelperText>{errors.acceptPolicies}</FormHelperText>
          )}
        </FormControl>

        <ButtonGreen type='submit' disabled={isSubmitting} data-testid='submit'>
          Skicka förfrågan
        </ButtonGreen>
      </Form>
    </>
  )
}
