import React, { useEffect } from 'react'
import { AlertProps } from '@mui/material'
import { Flex } from '../../components/Flex'
import { Box } from '../../components/Box'
import { WelcomeStep } from './ProfileSteps/WelcomeStep'
import { ProfileStepper } from './ProfileSteps/ProfileStepper'
import { Layout } from '../../components/Layout'
import { ProfileBoxContent } from './ProfileSteps/styles'
import { SnackAlert } from '../../components/SnackAlert'
import { InviteAccountantStep } from './ProfileSteps/InviteAccountantStep'
import { AppDataContext } from '../../context/AppDataContext'
import { OrgType, Role, useUsersQuery } from '../../graphql/generated'
import { FortnoxContext } from '../../context/FortnoxContext'
import { ProfileIntroStep } from './ProfileSteps/ProfileIntroStep'
import { PaymentStep } from './ProfileSteps/PaymentStep'

export enum Steps {
  Payment,
  Welcome,
  Accounting,
  ProfileIntro,
  Profile,
}
export function ProfileCreationPage(): React.JSX.Element {
  const { organisation, orgHasInitializedData } =
    React.useContext(AppDataContext)

  const { permissionError } = React.useContext(FortnoxContext)
  const [currentStep, setCurrentStep] = React.useState<Steps>(Steps.Payment)
  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)

  const handleCloseSnackbar = () => setSnackbar(null)

  const {
    data: usersData,
    loading: usersLoading,
    error,
  } = useUsersQuery({
    variables: {},
  })

  useEffect(() => {
    if (error) {
      setSnackbar({
        children: 'Något gick fel, ladda om sidan och försök igen',
        severity: 'error',
      })
    }
  }, [error])

  const hasInvitedAccountants = !!usersData?.users.find(
    (u) => u.invited && [Role.Admin, Role.Accountant].includes(u.role),
  )

  const goToNextStep = React.useCallback(() => {
    switch (currentStep) {
      case Steps.Payment:
        setCurrentStep(Steps.Welcome)
        break
      case Steps.Welcome:
        setCurrentStep(Steps.Accounting)
        break
      case Steps.Accounting:
        setCurrentStep(Steps.ProfileIntro)
        break
      case Steps.ProfileIntro:
        setCurrentStep(Steps.Profile)
        break
      default:
        setCurrentStep(Steps.Welcome)
    }
  }, [currentStep])

  const getAccountingStep = () => {
    if (
      // the organisation is of type fortnox and does not have data yet
      organisation.orgType === OrgType.Fortnox &&
      !orgHasInitializedData &&
      // the user does not have the permission to pull the data from fortnox
      permissionError?.hasBeenSet &&
      permissionError.error &&
      // the orga has not invited accountant or admin
      // (if the company has other accontant or admin that have loged in
      // but still have no data then we can assume that these users don't have permissions in fortnox)
      !hasInvitedAccountants
    ) {
      // ask the user to invite an accountant
      return (
        <InviteAccountantStep
          goToNextStep={goToNextStep}
          setSnackbar={setSnackbar}
        />
      )
    }
    // TODO add accounting step for SIE files

    // the user does not need to add an accountant, we can directloy go to the profile intro
    setCurrentStep(Steps.ProfileIntro)
  }

  const getContent = () => {
    switch (currentStep) {
      case Steps.Payment:
        return (
          <PaymentStep goToNextStep={goToNextStep} setSnackbar={setSnackbar} />
        )
      case Steps.Welcome:
        return (
          <WelcomeStep goToNextStep={goToNextStep} loading={usersLoading} />
        )
      case Steps.Accounting:
        return getAccountingStep()
      case Steps.ProfileIntro:
        return <ProfileIntroStep goToNextStep={goToNextStep} />
      default:
        return <ProfileStepper setSnackbar={setSnackbar} isProfileCreation />
    }
  }

  const getTitle = () => {
    switch (currentStep) {
      case Steps.ProfileIntro:
      case Steps.Profile:
        return 'Klimatprofilen'
      default:
        return 'Välkommen'
    }
  }

  return (
    <Layout title={getTitle()} hideJobButton>
      <Flex column itemsCenter data-testid='profile-creation'>
        <Box style={{ width: '820px' }}>
          <ProfileBoxContent>{getContent()}</ProfileBoxContent>
        </Box>

        <SnackAlert
          open={!!snackbar}
          onClose={handleCloseSnackbar}
          {...snackbar}
        />
      </Flex>
    </Layout>
  )
}
