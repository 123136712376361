// we are not doing any logical operation in this file so we do not have risk of running into the
// error that require this check
/* eslint-disable @sayari/no-unwrapped-jsx-text */
import React from 'react'
import { LoginPageWrapper } from '../authentication/components/LoginPageWrapper'
import { Body1, H5, S2Bold } from '../../components/Typography'
import { LiSection, Ol, RubricTitle } from './components/Styles'
import { spacing } from '../../theme'

export function PrivacyPolicy(): React.JSX.Element {
  return (
    <LoginPageWrapper error=''>
      <div style={{ textAlign: 'justify', paddingBottom: spacing.medium }}>
        <S2Bold style={{ paddingBottom: spacing.xl }}>
          SVALNA CARBON INTELLIGENCE SYSTEM™
        </S2Bold>
        <div style={{ paddingBottom: spacing.xl }}>
          <H5>PRIVACY POLICY</H5>
          <S2Bold>Last Updated: 2024-04-16</S2Bold>
        </div>
        <Body1>
          This Privacy Policy outlines how we handle your Personal Data across
          our services and refers to our{' '}
          <a href='/data-processing-agreement'>
            Data Processing Agreement (DPA)
          </a>
          , which provides detailed information on specific data processing
          activities.
        </Body1>
        <Ol style={{ paddingLeft: 0 }}>
          <LiSection>
            <RubricTitle>Introduction</RubricTitle>
            <Body1>
              Svalna AB is committed to protecting your privacy and ensuring the
              security of your personal information. This policy describes the
              types of data we collect, how it’s used, and your rights regarding
              your data.
            </Body1>
          </LiSection>
          <LiSection>
            <RubricTitle>Collection of Personal Data</RubricTitle>
            <Body1>We collect Personal Data in two ways:</Body1>
            <ul>
              <Body1>
                <li>
                  Directly Provided Data: Information you provide when engaging
                  with our services, such as demo requests, contacting us, or
                  subscribing to newsletters.
                </li>
              </Body1>
              <Body1>
                <li>
                  Indirectly Provided Data: Data collected through your
                  interaction with our services, like your IP address, using
                  cookies and technologies provided by third parties.
                </li>
              </Body1>
            </ul>
            <Body1>
              For detailed categories of personal data we process, please refer
              to our{' '}
              <a href='/data-processing-agreement'>
                Data Processing Agreement (DPA)
              </a>
              .
            </Body1>
          </LiSection>
          <LiSection>
            <RubricTitle>Use of Your Data</RubricTitle>
            <Body1>Your data is used to:</Body1>
            <ul>
              <Body1>
                <li>
                  Service Engagement: Manage interactions such as demo requests
                  and downloads. Data is retained for 3 years from your last
                  contact or the end of our contractual relationship.
                </li>
              </Body1>
              <Body1>
                <li>
                  Direct Marketing: With your consent, for marketing
                  communications until you unsubscribe, or for 3 years from your
                  last interaction.
                </li>
              </Body1>
              <Body1>
                <li>
                  Website Analytics: Understand service usage, based on
                  legitimate interests or consent.
                </li>
              </Body1>
            </ul>
            <Body1>
              For specific purposes and legal bases for processing your data,
              see our <a href='/data-processing-agreement'>DPA</a>.
            </Body1>
          </LiSection>
          <LiSection>
            <RubricTitle>Cookies and Tracking Technologies</RubricTitle>
            <Body1>
              We use cookies and similar technologies for various purposes,
              including analytics and personalized advertising. For more
              information on how we use these technologies and how you can
              manage your preferences, please refer to our{' '}
              <a href='/data-processing-agreement'>DPA</a>.
            </Body1>
          </LiSection>
          <LiSection>
            <RubricTitle>Sharing Your Data</RubricTitle>
            <Body1>
              We share your data with service providers, internal teams, and
              legal authorities when required. All sharing is conducted in
              compliance with GDPR, ensuring the protection of your data. For
              more details, including the categories of third parties we share
              data with, please consult our{' '}
              <a href='/data-processing-agreement'>DPA</a>.
            </Body1>
          </LiSection>
          <LiSection>
            <RubricTitle>International Data Transfers</RubricTitle>
            <Body1>
              Your data is primarily stored within the EU. For any transfers
              outside the EEA, we ensure compliance with GDPR through mechanisms
              like adequacy decisions or standard contractual clauses. Refer to
              our <a href='/data-processing-agreement'>DPA</a> for more
              information.
            </Body1>
          </LiSection>
          <LiSection>
            <RubricTitle>Your Rights</RubricTitle>
            <Body1>
              You have the right to access, correct, delete, or restrict the use
              of your data, among others. To exercise these rights or withdraw
              consent, contact us at{' '}
              <a href='mailto: privacy@svalna.se'>privacy@svalna.se</a>. For a
              comprehensive list of your rights and how to exercise them, please
              see our <a href='/data-processing-agreement'>DPA</a>.
            </Body1>
          </LiSection>
          <LiSection>
            <RubricTitle>Updates to This Policy</RubricTitle>
            <Body1>
              We may update this policy to reflect changes in our practices.
              Significant changes will be communicated in advance through our
              website or via email.
            </Body1>
          </LiSection>
          <LiSection>
            <RubricTitle>Contact Us</RubricTitle>
            <Body1>
              For any privacy-related inquiries or concerns, please contact us
              at <a href='mailto: privacy@svalna.se'>privacy@svalna.se</a> or
              reach out to our Data Protection Officer, Ross Linscott, at Svalna
              AB, Södra Larmgatan 6, 411 16 Göteborg, Sweden.
            </Body1>
          </LiSection>
        </Ol>
        <Body1>
          Your trust is crucial to us as we work towards a sustainable future
          together. This Privacy Policy, along with our detailed Data Processing
          Agreement, ensures a transparent and secure handling of your personal
          data.
        </Body1>
      </div>
    </LoginPageWrapper>
  )
}
