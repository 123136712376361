import React, { Fragment, useContext, useState } from 'react'
import { AlertProps, SvgIcon, Tab, Tabs } from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined'
import PaymentIcon from '@mui/icons-material/Payment'
import { Flex } from '../../components/Flex'
import { Layout } from '../../components/Layout'
import { LayoutContent } from '../../components/LayoutContent'
import { SideColumn } from '../../components/TableOfContent'
import { AppDataContext } from '../../context/AppDataContext'
import { Role, OrgType, useMeQuery } from '../../graphql/generated'
import LeafSvg from '../../assets/icons/leaf.svg?svgr'
import { ProfileStepper } from '../landing/ProfileSteps/ProfileStepper'
import { Box } from '../../components/Box'
import { UserManagement } from './UserManagement'
import { FileUpload } from './FileUpload'
import { ChangePassword } from './ChangePassword'
import { spacing } from '../../theme'
import { ButtonBlack } from '../../components/Buttons'
import { ProfileBoxContent } from '../landing/ProfileSteps/styles'
import { Body2 } from '../../components/Typography'
import { SnackAlert } from '../../components/SnackAlert'
import { UpdatePaymentInfoBox } from './UpdatePaymentInfoBox'

const LogoutButton = styled(ButtonBlack)`
  align-self: flex-start;
  margin-top: ${spacing.xl};
`

const AccountInfoContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: ${spacing.small};
`

const AccountInfoRowCaption = styled(Body2)`
  grid-column: 1;
`

const AccountInfoRowText = styled(Body2)`
  grid-column: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledTab = styled(Tab)`
  min-height: 0;
  padding-bottom: 0px;
`

function AccountInfoBox() {
  const { data } = useMeQuery() // this is called inside AppDataContext which will handle the error
  const user = data?.me
  const history = useHistory()
  const { organisation } = useContext(AppDataContext)

  return (
    <Flex column>
      <AccountInfoRows
        rows={[
          {
            caption: 'Inloggad som',
            text: `${user?.firstName} ${user?.lastName}`,
          },
          // TODO: Do not display email for manually created test accounts,
          //        but do display for normal accounts (when implemented).
          // {
          //   caption: 'Mejladress',
          //   text: `${user?.email}`,
          // },
          {
            caption: 'Organisation',
            text: `${user?.organisation.name}`,
          },
        ]}
      />
      <LogoutButton
        onClick={() =>
          history.push({
            pathname: '/logout',
            state: { orgType: organisation.orgType },
          })
        }
      >
        Logga ut
      </LogoutButton>
    </Flex>
  )
}

type AccountInfoRowsProps = {
  rows: Array<{ caption: string; text: string }>
}
function AccountInfoRows({ rows }: AccountInfoRowsProps) {
  return (
    <AccountInfoContainer>
      {rows.map(({ caption, text }) => (
        <Fragment key={caption}>
          <AccountInfoRowCaption>{caption}:</AccountInfoRowCaption>
          <AccountInfoRowText>{text}</AccountInfoRowText>
        </Fragment>
      ))}
    </AccountInfoContainer>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  name: string
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, name, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`${name}-tabpanel`}
      aria-labelledby={`${name}-tabpanel`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

export function Settings(): React.JSX.Element {
  const { data } = useMeQuery() // this is called inside AppDataContext which will handle the error
  const user = data?.me
  const [tab, setTab] = useState(0)
  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  const handleCloseSnackbar = () => setSnackbar(null)

  // Fortnox organisation are using Fortnox as an identity provider, so their users don't have a password
  // Organisation with SSO enabled use their identity provider, so their users don't have a password
  const hasPassword =
    user?.organisation.orgType !== OrgType.Fortnox && !user?.organisation.idp

  let index = 0
  const getIndex = () => {
    return index++
  }

  // if the user is not an admin they can't mange the organisation users
  // if the organisation is using SSO, they should manage their users through their idp
  const canManageUsers =
    [Role.Admin, Role.SysAdmin].includes(user?.role ?? Role.User) &&
    !user?.organisation.idp

  const shouldSeeProfile =
    user?.organisation.orgType &&
    ![OrgType.RegionalGovernance, OrgType.University].includes(
      user?.organisation.orgType,
    )

  const canUploadFiles =
    user?.organisation.orgType &&
    [OrgType.Test, OrgType.SieFile].includes(user?.organisation.orgType)

  const canUpdatePaymentInfo =
    user?.organisation.orgType &&
    ![OrgType.RegionalGovernance, OrgType.University].includes(
      user?.organisation.orgType,
    ) &&
    [Role.Admin, Role.SysAdmin].includes(user?.role ?? Role.User)

  // Count number of active tabs for this user, to determine if the
  // tab bar should be displayed.
  // Note: when casting to number, take care to ensure the values are bool,
  //       not undefined, as `Number(undefined)` is NaN,
  //       NaN + anything = NaN, and NaN > 1 is false.
  const hasMoreThanOneTab =
    Number(!!hasPassword) +
      Number(!!canManageUsers) +
      Number(!!shouldSeeProfile) +
      Number(!!canUploadFiles) +
      Number(!!canUpdatePaymentInfo) >
    1

  return (
    <Layout title='Konto'>
      <Flex column stretchWidth style={{ gap: spacing.xxl }}>
        {hasMoreThanOneTab && (
          <Tabs
            value={tab}
            onChange={handleTabChange}
            aria-label='setting tabs'
            textColor='primary'
            indicatorColor='primary'
            data-testid='tabs'
            style={{ minHeight: 42, alignSelf: 'center' }}
          >
            {hasPassword && (
              <StyledTab
                label='Lösenord'
                icon={<LockOutlinedIcon />}
                iconPosition='start'
                data-testid='password-tab'
              />
            )}
            {shouldSeeProfile && (
              <StyledTab
                label='Klimatprofil'
                icon={
                  <SvgIcon>
                    <LeafSvg />
                  </SvgIcon>
                }
                iconPosition='start'
                data-testid='profile-tab'
              />
            )}
            {canManageUsers && (
              <StyledTab
                label='Användarhantering'
                icon={<SupervisorAccountOutlinedIcon />}
                iconPosition='start'
                data-testid='user-management'
              />
            )}
            {canUpdatePaymentInfo && (
              <StyledTab
                label='Fakturauppgifter'
                icon={<PaymentIcon />}
                iconPosition='start'
                data-testid='payment-tab'
              />
            )}
            {canUploadFiles && (
              <StyledTab
                label='Ladda upp filer'
                icon={<UploadFileIcon />}
                iconPosition='start'
                data-testid='upload-tab'
              />
            )}
          </Tabs>
        )}
        {/* Note on 'gap': Firefox does not seem to add space between left/right columns without this. */}
        <Flex row stretchWidth style={{ gap: spacing.large }}>
          <SideColumn>
            <AccountInfoBox />
          </SideColumn>
          <LayoutContent>
            {hasPassword && (
              <CustomTabPanel value={tab} index={getIndex()} name='Lösenord'>
                <ChangePassword />
              </CustomTabPanel>
            )}
            {shouldSeeProfile && (
              <CustomTabPanel
                value={tab}
                index={getIndex()}
                name='Klimatprofil'
              >
                <Box>
                  <ProfileBoxContent>
                    <ProfileStepper setSnackbar={setSnackbar} />
                  </ProfileBoxContent>
                </Box>
              </CustomTabPanel>
            )}
            {canManageUsers && (
              <CustomTabPanel
                value={tab}
                index={getIndex()}
                name='Användarhantering'
              >
                <UserManagement />
              </CustomTabPanel>
            )}
            {canUpdatePaymentInfo && (
              <CustomTabPanel
                value={tab}
                index={getIndex()}
                name='Fakturauppgifter'
              >
                <UpdatePaymentInfoBox setSnackbar={setSnackbar} />
              </CustomTabPanel>
            )}
            {canUploadFiles && (
              <CustomTabPanel
                value={tab}
                index={getIndex()}
                name='Ladda upp filer'
              >
                <FileUpload />
              </CustomTabPanel>
            )}
          </LayoutContent>
          {/* Empty ToC to have even spacing on the left/right side of main content. */}
          <SideColumn />
        </Flex>
        <SnackAlert
          open={!!snackbar}
          onClose={handleCloseSnackbar}
          {...snackbar}
        />
      </Flex>
    </Layout>
  )
}
