import React, { useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { AppDataContext } from './context/AppDataContext'
import { ForgotPassword } from './routes/authentication/ForgotPassword'
import { Fortnox } from './routes/authentication/Fortnox'
import { LoginPage } from './routes/authentication/LoginPage'
import { EmissionPage } from './routes/emission/EmissionPage'
import { HelpPage } from './routes/help/HelpPage'
import { Settings } from './routes/settings/Settings'
import { Sysadmin } from './routes/settings/Sysadmin'
import { RecategorisationPage } from './routes/recategorisation/RecategorisationPage'
import { RegistrationPage } from './routes/authentication/registration/RegistrationPage'
import { PublicLanding } from './routes/landing/PublicLanding'
import { UserAgreementPage } from './routes/policies/UserAgreementPage'
import { PrivacyPolicy } from './routes/policies/PrivacyPolicy'
import { ProfileCreationPage } from './routes/landing/ProfileCreationPage'
import { DataProcessingAgreement } from './routes/policies/DataProcessingAgreement'
import { Feature, Role, useMeQuery } from './graphql/generated'
import { GoalPage } from './routes/goal/GoalPage'
import { GhgpPage } from './routes/ghgp/GhgpPage'
import { PaymentTerms } from './routes/policies/PaymentTerms'
import { PageLoader } from './components/PageLoader'

export function AuthorizedRoutes(): React.JSX.Element {
  const { organisation } = useContext(AppDataContext)
  const { data: meData, loading } = useMeQuery() // this is called inside AppDataContext which will handle the error
  if (loading) {
    // This should never happen as we alway mount AuthorizedRoutes after we have meData set
    // But just in case and it help with the tests
    return <PageLoader delayMs={250} />
  }
  // Switich Route is like a switch case, so if an option is not in the switch and the user try to access it
  // the user will be redirected to the default route which is the Redirect at the end.
  // So by not putint a route when the user does not have the feature we ensure that if the user try to access this route
  // they will be redirected to the default route.
  return (
    <Switch>
      <Route exact path='/profile' component={ProfileCreationPage} />
      <Route exact path='/emission' component={EmissionPage} />
      {meData?.me.organisation.enabledFeatures?.features?.includes(
        Feature.Goal,
      ) && <Route exact path='/goal' component={GoalPage} />}
      {meData?.me.organisation.enabledFeatures?.features?.includes(
        Feature.Ghgp,
      ) && <Route exact path='/ghgp' component={GhgpPage} />}
      <Route exact path='/help' component={HelpPage} />
      <Route exact path='/settings' component={Settings} />
      <Route exact path='/sysadmin' component={Sysadmin} />
      {meData?.me.organisation.enabledFeatures?.features?.includes(
        Feature.Transactions,
      ) && <Route exact path='/recat' component={RecategorisationPage} />}
      <Route exact path='/user-agreement' component={UserAgreementPage} />
      <Route exact path='/privacy-policy' component={PrivacyPolicy} />
      <Route exact path='/payment-terms' component={PaymentTerms} />
      <Route
        exact
        path='/data-processing-agreement'
        component={DataProcessingAgreement}
      />
      {!organisation.profileCreated &&
      [Role.Admin, Role.SysAdmin].includes(meData?.me.role ?? Role.User) ? (
        <Redirect to='/profile' />
      ) : (
        <Redirect to='/emission' />
      )}
    </Switch>
  )
}

export function UnauthorizedRoutes(): React.JSX.Element {
  return (
    <Switch>
      <Route exact path='/landing' component={PublicLanding} />
      <Route exact path='/registration' component={RegistrationPage} />
      <Route exact path='/login' component={LoginPage} />
      <Route exact path='/forgot-password' component={ForgotPassword} />
      <Route exact path='/fortnox' component={Fortnox} />
      <Route exact path='/user-agreement' component={UserAgreementPage} />
      <Route exact path='/privacy-policy' component={PrivacyPolicy} />
      <Route exact path='/payment-terms' component={PaymentTerms} />
      <Route
        exact
        path='/data-processing-agreement'
        component={DataProcessingAgreement}
      />
      <Redirect to='/landing' />
    </Switch>
  )
}
