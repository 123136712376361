import React, { useContext, useEffect, useState } from 'react'
import { AlertProps } from '@mui/material'
import { captureException } from '@sentry/browser'
import { Body1, Body1Bold, H6Bold } from '../../../components/Typography'
import { ButtonGreen } from '../../../components/Buttons'
import { InnerBoxText, InnerInnerBox } from '../../../components/Flex'
import { useGetPaymentInfoQuery } from '../../../graphql/generated'
import { UpdatePaymentInfoForm } from '../../settings/UpdatePaymentInfoForm'
import { ErrorSnackBarContext } from '../../../context/ErrorSnackBarContex'

export function PaymentStep({
  goToNextStep,
  setSnackbar,
}: {
  goToNextStep: () => void
  setSnackbar: React.Dispatch<
    React.SetStateAction<Pick<AlertProps, 'children' | 'severity'> | null>
  >
}): React.JSX.Element {
  const [initialLoading, setInitialLoading] = useState(true)
  const [success, setSuccess] = useState(false)

  // the error case is handled in UpdatePaymentInfoForm
  const { data, loading: paymentInfoLoading, error } = useGetPaymentInfoQuery()

  const { setError } = useContext(ErrorSnackBarContext)
  useEffect(() => {
    if (error) {
      captureException(error)
      setError()
    }
  }, [error, setError])

  useEffect(() => {
    // there are some data
    if (data?.getPaymentInfo.acceptPolicies) {
      // we are still in the initial loading, so the data were not updated by the user
      if (initialLoading) {
        goToNextStep()
      }
    }
    if (!paymentInfoLoading) {
      // this way the initial loading is set to false the first time we get the payment
      // and remain false afterward, even if the payment info are reloaded
      setInitialLoading(false)
    }
  }, [
    data?.getPaymentInfo.acceptPolicies,
    goToNextStep,
    initialLoading,
    paymentInfoLoading,
  ])

  if (success) {
    return (
      <InnerInnerBox>
        <Body1Bold>Betalning</Body1Bold>
        <InnerBoxText>
          <H6Bold data-testid='success'>Tack, uppgifterna har sparats.</H6Bold>
          <Body1>
            Fakturauppgifterna har sparats och en faktura kommer skickas till
            den angivna e-postadressen enligt villkoren. Om du behöver ändra
            uppgifterna kan du göra det senare under Konto.
          </Body1>
        </InnerBoxText>
        <ButtonGreen onClick={() => goToNextStep()} data-testid='continue'>
          Fortsätt
        </ButtonGreen>
      </InnerInnerBox>
    )
  }

  return (
    <InnerInnerBox>
      <Body1Bold data-testid='payment-step'>Betalning</Body1Bold>

      <H6Bold>Välkommen! Fyll i era fakturauppgifter för att fortsätta.</H6Bold>

      <UpdatePaymentInfoForm
        setSnackbar={setSnackbar}
        onSuccess={() => setSuccess(true)}
      />
    </InnerInnerBox>
  )
}
