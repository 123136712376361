import React from 'react'
import { useFormik } from 'formik'
import { CircularProgress, TextField } from '@mui/material'
import axios from 'axios'
import { captureException } from '@sentry/browser'
import { Body1, H5Bold } from '../../components/Typography'
import { Box } from '../../components/Box'
import { Flex } from '../../components/Flex'
import { ButtonGreen } from '../../components/Buttons'
import { useGetUploadUrlLazyQuery } from '../../graphql/generated'
import { colors, spacing } from '../../theme'
import { SnackAlert } from '../../components/SnackAlert'

interface uploadInfo {
  loaded: number
  total: number
}

export function FileUpload(): React.JSX.Element {
  const [getUploadUrl] = useGetUploadUrlLazyQuery({ fetchPolicy: 'no-cache' })
  const [uploadProgress, setUploadProgress] = React.useState<Array<uploadInfo>>(
    [],
  )
  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState(false)

  type FormValues = {
    files: File[]
  }

  const initialValues: FormValues = {
    files: [],
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      setError('')
      setUploadProgress(
        values.files.map((file) => ({
          loaded: 0,
          total: file.size,
        })),
      )
      try {
        const promises = values.files.map(async (file, index) => {
          const uploadUrlResult = await getUploadUrl({
            variables: { fileName: file.name, fileType: file.type },
          })
          if (uploadUrlResult.error) {
            captureException(uploadUrlResult.error)
            throw new Error('error while getting the upload url')
          }
          const uploadUrl = uploadUrlResult.data?.getUploadUrl
          if (!uploadUrl) {
            throw new Error('error while getting the upload url')
          }

          await axios.put(uploadUrl, file, {
            headers: {
              'Content-Type': file.type,
            },
            onUploadProgress: (progressEvent) => {
              const total = progressEvent.total
              if (total) {
                setUploadProgress((prevState) => {
                  const newState = [...prevState]
                  newState[index] = {
                    loaded: progressEvent.loaded,
                    total,
                  }
                  return newState
                })
              }
            },
          })
        })
        await Promise.all(promises)
        setSuccess(true)
      } catch (err) {
        setError('Något gick fel vid uppladdningen, försök igen')
      }
      setUploadProgress([])
    },
  })

  const computeProgress = () => {
    const [total, loaded] = uploadProgress.reduce(
      (agg, fileInfo) => [agg[0] + fileInfo.total, agg[1] + fileInfo.loaded],
      [0, 0],
    )
    return Math.round((loaded * 100) / total)
  }

  return (
    <>
      <SnackAlert open={!!error} severity='error' onClose={() => setError('')}>
        <Body1>{error}</Body1>
      </SnackAlert>
      <SnackAlert
        open={success}
        severity='success'
        onClose={() => setSuccess(false)}
      >
        <Body1>filerna har laddats upp</Body1>
      </SnackAlert>
      <Box>
        <Flex column style={{ padding: spacing.large, gap: spacing.large }}>
          <H5Bold>Ladda upp filer</H5Bold>
          <form onSubmit={formik.handleSubmit}>
            <Flex column style={{ gap: spacing.medium }}>
              <TextField
                type='file'
                name='files'
                onChange={(event) => {
                  formik.setFieldValue(
                    'files',
                    Array.from((event.target as any).files),
                  )
                }}
                inputProps={{
                  multiple: true,
                }}
              />

              <ButtonGreen
                type='submit'
                endIcon={
                  uploadProgress.length > 0 && (
                    <CircularProgress
                      variant='determinate'
                      value={computeProgress()}
                      style={{ color: colors.white }}
                      size={20}
                    />
                  )
                }
                disabled={uploadProgress.length > 0}
              >
                Ladda upp
              </ButtonGreen>
            </Flex>
          </form>
        </Flex>
      </Box>
    </>
  )
}
