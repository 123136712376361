import React from 'react'
import alertCircleOutlineSvg from '../../../assets/icons/alert-circle-outline.svg'
import { AlertIcon, AlertWarning } from '../../../components/alert'
import { CaptionBold, H5, H6Bold } from '../../../components/Typography'
import { LogoutSuccessAlert } from './LogoutSuccessAlert'
import { Flex } from '../../../components/Flex'
import logo from '../../../assets/icons/logoOriginal.svg'
import placeholder from '../../../assets/images/Placeholder.jpg'
import { colors, spacing } from '../../../theme'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  error?: string
  redirectBlock?: React.JSX.Element
}
export function LoginPageWrapper({
  children,
  error,
  redirectBlock,
}: Props): React.JSX.Element {
  return (
    <Flex spaceBetween style={{ height: '100vh' }}>
      <Flex
        column
        style={{
          margin: spacing.medium,
          minWidth: '300px',
          width: '50%',
          height: '90%',
        }}
      >
        <Flex itemsCenter>
          <img src={logo} alt='logo' />
          <H6Bold>SVALNA</H6Bold>
        </Flex>
        <Flex
          column
          stretchWidth
          itemsCenter
          style={{
            marginTop: '10vh',
            height: 'inherit',
          }}
        >
          <Flex
            column
            style={{ gap: spacing.medium, width: '80%', height: '100%' }}
          >
            {error && (
              <AlertWarning
                style={{ width: '100%', position: 'inherit' }}
                data-testid='error-msg'
              >
                <AlertIcon src={alertCircleOutlineSvg} />
                <CaptionBold>{error}</CaptionBold>
              </AlertWarning>
            )}
            <LogoutSuccessAlert />
            {children}
          </Flex>
          {redirectBlock}
        </Flex>
      </Flex>
      <Flex
        style={{
          width: '50%',
          height: '100%',
          backgroundImage: `url(${placeholder})`,
          backgroundSize: 'cover',
          position: 'fixed',
          right: 0,
        }}
      >
        <H5
          style={{
            color: colors.white,
            margin: spacing.xxxl,
            alignSelf: 'end',
          }}
        >
          Kartlägg verksamhetens klimatavtryck och nå era klimatmål med Svalnas
          Carbon Intelligence System™
        </H5>
      </Flex>
    </Flex>
  )
}
