import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEditInputCell,
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { GridApiCommunity } from '@mui/x-data-grid/internals'
import { Tooltip } from '@mui/material'
import _ from 'lodash'
import dayjs from 'dayjs'
import { captureException } from '@sentry/browser'
import { Flex, InnerInnerBox } from '../../../components/Flex'
import { Body1 } from '../../../components/Typography'
import { ButtonLightBorder } from '../../../components/Buttons'
import {
  EnumTextTuple,
  Fuel,
  GetVehiclesDocument,
  Vehicle,
  useGetFuelTextQuery,
  useGetVehicleInfoLazyQuery,
  useGetVehiclesQuery,
  useReplaceVehiclesMutation,
} from '../../../graphql/generated'
import {
  preProcessEditNumber,
  renderEditCell,
  StyledTooltip,
  validateNumber,
} from '../utils/Checkers'
import { Stepprops } from './ProfileStepper'
import { ProfileTableBox } from './styles'
import { useTableCardHelper } from '../utils/TableCardHelper'
import { spacing } from '../../../theme'
import { ErrorSnackBarContext } from '../../../context/ErrorSnackBarContex'
import { GridActionItem } from '../../../components/GridActionItem'

function validateRegNum(value: string) {
  const numReg = /^[a-zA-ZäöåÄÖÅ0-9]{2,7}$/
  if (!numReg.test(value)) {
    return 'ogiltigt registreringsnummer'
  }
  return undefined
}

function preProcessRegNum(params: GridPreProcessEditCellProps) {
  const errorMessage = validateRegNum(params.props.value)
  return { ...params.props, error: errorMessage }
}

interface VehiclesRow extends Vehicle {
  id: string
  isNew?: boolean
}

function EditVehicleInputCell(props: GridRenderEditCellParams) {
  const { error, fetchVehicleInfo, value, id, api } = props

  return (
    <StyledTooltip open={!!error} title={error}>
      <div>
        <Flex itemsCenter>
          <GridEditInputCell
            {..._.omit(props, 'fetchVehicleInfo')}
            inputProps={{ 'data-testid': 'reg-number-input' }}
          />
          <Tooltip title='Sök efter fordonsinformation'>
            <span>
              <GridActionsCellItem
                icon={<SearchIcon />}
                label='Sök'
                onClick={() => {
                  fetchVehicleInfo(value, id, api)
                }}
                color='inherit'
                key='save'
                disabled={error || !value}
                data-testid='search-vehicle-info'
              />
            </span>
          </Tooltip>
        </Flex>
      </div>
    </StyledTooltip>
  )
}

export function VehiclesStep({
  setSnackbar,
  editEnabled,
  setLoading,
  setSaveAndClose,
  setSaveAndContinue,
  forward,
  close,
  setReset,
}: Stepprops): React.JSX.Element {
  const {
    data: vehiclesData,
    loading,
    error: vehcilesError,
  } = useGetVehiclesQuery()
  const [rows, setRows] = useState<VehiclesRow[]>([])
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'regNumber',
      sort: 'asc',
    },
  ])
  const [submitting, setSubmitting] = useState(false)
  const [replaceVehicles] = useReplaceVehiclesMutation({
    refetchQueries: [{ query: GetVehiclesDocument }],
  })
  const {
    data: fuelTextData,
    loading: loadingText,
    error: fuelTextError,
  } = useGetFuelTextQuery()
  const [fuelText, setFuelText] = useState<EnumTextTuple[]>([])

  const [getVehicleInfo] = useGetVehicleInfoLazyQuery()
  const apiRef = useGridApiRef()

  const { setError } = useContext(ErrorSnackBarContext)
  useEffect(() => {
    if (vehcilesError || fuelTextError) {
      captureException(vehcilesError || fuelTextError)
      setError()
    }
  }, [vehcilesError, fuelTextError, setError])

  useEffect(() => {
    setLoading(submitting || loading || loadingText)
  }, [loading, loadingText, setLoading, submitting])

  const setInitialRows = useCallback(() => {
    setRows(
      vehiclesData?.getVehicles.map((v) => {
        const r: VehiclesRow = {
          ...v,
          id: v.regNumber,
          mileage: v.mileage / 10,
        }
        return r
      }) ?? [],
    )
  }, [vehiclesData?.getVehicles])

  useEffect(() => {
    setFuelText(fuelTextData?.getFuelText ?? [])
  }, [fuelTextData])

  const handleEditClick = useCallback((id: GridRowId) => {
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'regNumber' },
    }))
  }, [])

  const validateRow = useCallback((row: VehiclesRow) => {
    let errorMessage = validateNumber(row.consumption)
    if (errorMessage) {
      return `ogiltig förbrukning: ${errorMessage}`
    }
    errorMessage = validateNumber(row.mileage)
    if (errorMessage) {
      return `ogiltig körsträcka: ${errorMessage}`
    }
    errorMessage = validateRegNum(row.regNumber)
    if (errorMessage) {
      return errorMessage
    }
    return undefined
  }, [])

  const fetchVehicleInfo = useCallback(
    async (regNumber: string, id: string, api: GridApiCommunity) => {
      const response = await getVehicleInfo({ variables: { regNumber } })
      const vehicle = response.data?.getVehicleInfo
      if (vehicle) {
        await apiRef.current.setEditCellValue({
          id,
          field: 'primaryFuel',
          value: vehicle.primaryFuel,
        })
        await apiRef.current.setEditCellValue({
          id,
          field: 'secondaryFuel',
          value: vehicle.secondaryFuel,
        })

        await apiRef.current.setEditCellValue({
          id,
          field: 'mileage',
          value: vehicle.mileage,
        })
        await apiRef.current.setEditCellValue({
          id,
          field: 'consumption',
          value: vehicle.consumption,
        })
      } else if (response.error) {
        captureException(response.error)
        setSnackbar({
          children: 'Vi hittade ingen information om detta fordon',
          severity: 'error',
        })
      }
    },
    [apiRef, getVehicleInfo, setSnackbar],
  )

  // The dataGrid is in loading mode until fuelText get set so it is ok for the columns' definitions to change when it get set.
  // EditEnabled changes will trigger the all component to rerender, so we will loose the sate of the datagrid anyway.
  // We could work on changing the way the component is implemented to not do a full rerender. But the GridColDef only accept boolean
  // for editable so I don't see how we would make to change the value without redefining the columns.

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'regNumber',
        headerName: 'Reg. nummer',
        flex: 2,
        editable: editEnabled,
        preProcessEditCellProps: preProcessRegNum,
        renderEditCell: (params: GridRenderEditCellParams) => (
          <EditVehicleInputCell
            {...params}
            fetchVehicleInfo={fetchVehicleInfo}
          />
        ),
      },
      {
        field: 'primaryFuel',
        headerName: 'Drivmedel',
        flex: 2,
        editable: editEnabled,
        type: 'singleSelect',
        valueOptions: fuelText,
        getOptionValue: (value: any) => value.key,
        getOptionLabel: (value: any) => value.value,
      },
      {
        field: 'secondaryFuel',
        headerName: 'Sekundärt drivmedel',
        flex: 2,
        editable: editEnabled,
        type: 'singleSelect',
        valueOptions: fuelText,
        getOptionValue: (value: any) => value.key,
        getOptionLabel: (value: any) => value.value,
      },
      {
        field: 'consumption',
        headerName: 'Förbrukning (l/100km)',
        flex: 2,
        editable: editEnabled,
        type: 'number',
        preProcessEditCellProps: preProcessEditNumber,
        renderEditCell,
      },
      {
        field: 'mileage',
        headerName: 'Körsträcka (mil/år)',
        flex: 2,
        editable: editEnabled,
        type: 'number',
        preProcessEditCellProps: preProcessEditNumber,
        renderEditCell,
      },
      {
        field: 'actions',
        headerName: '',
        type: 'actions',
        cellClassName: 'actions',
        flex: 1,
        getActions: ({ id }) => {
          return [
            <GridActionItem
              icon={<EditIcon />}
              label='Ändra'
              onClick={() => handleEditClick(id)}
              color='inherit'
              key='edit'
              disabled={!editEnabled}
              rowId={id}
              modeToShowItemIn={GridRowModes.View}
            />,
          ]
        },
      },
    ],
    [editEnabled, fetchVehicleInfo, fuelText, handleEditClick],
  )

  const handleAddRow = () => {
    const id = `${dayjs.utc().valueOf()}`
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        orgId: '',
        regNumber: '',
        primaryFuel: Fuel.Bensin,
        mileage: 0,
        consumption: 0,
        isNew: true,
        secondaryFuel: Fuel.None,
      },
    ])
    apiRef.current.startRowEditMode({ id, fieldToFocus: 'regNumber' })

    //reset the sort model to make sure that the new row is at the top
    setSortModel([
      {
        field: 'regNumber',
        sort: 'asc',
      },
    ])
  }

  const save = useCallback(
    async (toSave: VehiclesRow[]): Promise<boolean> => {
      try {
        setSubmitting(true)
        const invalidRows = toSave.filter((r) => validateRow(r))
        if (invalidRows.length > 0) {
          setSnackbar({
            children: `Följande fordon har ogiltiga poster, vänligen åtgärda dem: ${invalidRows
              .map((r) => r.regNumber)
              .join(', ')}`,
            severity: 'error',
          })
          return false
        }
        await replaceVehicles({
          variables: {
            vehicles: toSave.map((r) => {
              // remove the fields that are not part of Vehicle
              const vehicle = _.omit(r, 'id', 'isNew')
              vehicle.mileage = r.mileage * 10
              return vehicle
            }),
          },
        })
        return true
      } catch (error) {
        captureException(error)
        setSnackbar({
          children: `Det gick inte att spara fordonen`,
          severity: 'error',
        })
      } finally {
        setSubmitting(false)
      }
      return false
    },
    [replaceVehicles, setSnackbar, validateRow],
  )

  const {
    processRowUpdate,
    selectedRows,
    setSelectedRows,
    handleDeleteClick,
    handleProcessRowUpdateError,
  } = useTableCardHelper({
    rowModesModel,
    setRowModesModel,
    setSaveAndContinue,
    setSaveAndClose,
    forward,
    close,
    setReset,
    setInitialRows,
    rows,
    save,
    validateRow,
    setSnackbar,
    setRows,
    apiRef,
  })

  return (
    <InnerInnerBox>
      <Body1>
        Nedan listas de fordon som är registrerade på er organisation enligt
        Vägtrafikregistret. Notera att fordon med sk. &quot;operationell
        leasing&quot; inte omfattas av registret och att ni behöver lägga in
        dessa manuellt. Ändra om något inte stämmer. Uppgifterna hjälper oss att
        vid behov kontrollera och verifiera uppskattningarna av era Scope
        1-utsläpp.
      </Body1>
      <ProfileTableBox>
        <Flex
          style={{
            gap: spacing.small,
            alignSelf: 'end',
            opacity: editEnabled ? 1 : 0,
          }}
        >
          <ButtonLightBorder
            startIcon={<DeleteOutlinedIcon />}
            onClick={handleDeleteClick}
            disabled={!editEnabled || !selectedRows.length}
          >
            Radera valda rader
          </ButtonLightBorder>
          <ButtonLightBorder
            startIcon={<AddIcon />}
            onClick={handleAddRow}
            disabled={!editEnabled}
            data-testid='add-vehicle'
          >
            LÄGG TILL
          </ButtonLightBorder>
        </Flex>
        <Flex stretchWidth style={{ minHeight: '150px' }}>
          <DataGrid
            apiRef={apiRef}
            rows={rows ?? []}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            style={{
              width: '100%',
              height: !rows?.length ? '150px' : 'auto',
            }}
            rowModesModel={rowModesModel}
            editMode='row'
            onRowModesModelChange={setRowModesModel}
            processRowUpdate={processRowUpdate}
            sortModel={sortModel}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            loading={loading || loadingText}
            sx={{
              '& .MuiDataGrid-columnHeaderTitle': {
                whiteSpace: 'break-spaces',
                lineHeight: 1,
              },
              '&.MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer':
                {
                  pl: 1,
                },
            }}
            disableVirtualization
            checkboxSelection
            onRowSelectionModelChange={(ids) => {
              setSelectedRows(ids as string[])
            }}
          />
        </Flex>
      </ProfileTableBox>
    </InnerInnerBox>
  )
}
