import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { captureException } from '@sentry/browser'
import { Body1, H5Bold } from '../../../components/Typography'
import { ButtonGreen } from '../../../components/Buttons'
import { InnerInnerBox } from '../../../components/Flex'
import {
  MeDocument,
  Role,
  useFinishProfileCreationMutation,
  useMeQuery,
} from '../../../graphql/generated'
import { Loader } from '../../../components/Loader'
import { spacing } from '../../../theme'
import { ErrorSnackBarContext } from '../../../context/ErrorSnackBarContex'

export function EndStep(): React.JSX.Element {
  const { data: meData } = useMeQuery() // this is called inside AppDataContext which will handle the error
  const me = meData?.me

  const { setError } = useContext(ErrorSnackBarContext)

  const history = useHistory()
  const [finishProfileCreation] = useFinishProfileCreationMutation({
    refetchQueries: [{ query: MeDocument }],
  })
  const [loading, setLoading] = useState(false)
  const handleClick = async () => {
    setLoading(true)
    if ([Role.Admin, Role.SysAdmin].includes(me?.role ?? Role.User)) {
      try {
        await finishProfileCreation()
      } catch (error) {
        captureException(error)
        setError()
      }
    }
    history.push('/emission')
    setLoading(false)
  }
  return (
    <>
      <InnerInnerBox>
        <H5Bold>Snyggt! Klimatprofilen är ifylld och klar! 🎉</H5Bold>

        <Body1>
          Svaren från klimatprofilen används för att anpassa
          utsläppsberäkningarna och identifiera åtgärder ni kan genomföra för
          att minska ert klimatavtryck.
        </Body1>
        <Body1>
          Du kan alltid hitta Klimatprofilen igen under Konto om du skulle
          behöva uppdatera svaren.
        </Body1>
      </InnerInnerBox>
      <ButtonGreen
        style={{ alignSelf: 'center' }}
        onClick={handleClick}
        disabled={loading}
        startIcon={
          loading && (
            <Loader
              size={14}
              borderSize={2}
              style={{ marginRight: spacing.small }}
            />
          )
        }
      >
        {loading ? <span>Laddar...</span> : <span>UTFORSKA UTSLÄPPEN</span>}
      </ButtonGreen>
    </>
  )
}
