import React, { ReactNode, useContext } from 'react'
import { OnboardBoldText, OnboardSnack } from './OnboardSnack'
import { Body1, H6Bold } from '../Typography'
import { FooterType, OnboardFooter } from './OnboardFooter'
import { AppDataContext } from '../../context/AppDataContext'

export const LOCALSTORAGE_KEY_SHOW_RECAT_INTRO = 'show-recat-intro'

const OnboardTopics: Array<{
  head: ReactNode
  body: ReactNode
  footer: ReturnType<typeof OnboardFooter>
}> = [
  {
    head: <H6Bold>Det här är omkategoriseringssystemet. Välkommen!</H6Bold>,
    body: (
      <>
        <Body1>
          På den här sidan kan du söka efter transaktioner och korrigera deras
          utsläppsvärden.
        </Body1>
        <Body1>
          Det kan vara bra att korrigera transaktioner både för att de kan ha
          kategoriserats felaktigt och för att du kan ha mer exakt information
          om utsläppen kopplade till just denna transaktion.
        </Body1>
        <Body1>
          Det är viktigt att transaktionerna har rätt kategorier för att era
          utsläppsresultat ska vara korrekta.
        </Body1>
      </>
    ),
    footer: OnboardFooter(FooterType.Start),
  },

  {
    head: <H6Bold>Så här kan du söka efter transaktioner</H6Bold>,
    body: (
      <>
        <Body1>
          Du kan söka efter transaktioner med valfri kombination av parametrar.
        </Body1>
        <Body1>
          <span>
            För vart och ett av sökkriterierna kan du välja vilken typ av
            sökning du vill göra:
          </span>
          <br />
          <ul>
            <li>
              <strong>börjar med</strong>
              <span>
                : sök efter alla transaktioner där fältet börjar med din
                söksträng
              </span>
            </li>
            <li>
              <strong>innehåller</strong>
              <span>
                : sök efter alla transaktioner där fältet innehåller din
                söksträng
              </span>
            </li>
            <li>
              <strong>lika med</strong>
              <span>
                : sök efter alla transaktioner där fältet är exakt lika din
                söksträng
              </span>
            </li>
            <li>
              <strong>regexp</strong>
              <span>
                : låter dig söka i fältets text med ett reguljärt uttryck du
                anger som söksträng
              </span>
            </li>
          </ul>
        </Body1>
      </>
    ),
    footer: OnboardFooter(FooterType.BackForward),
  },

  {
    head: <H6Bold>Så här kan du skapa en regel</H6Bold>,
    body: (
      <>
        <Body1>
          <span>
            När du har hittat transaktionerna du vill rätta, klicka på knappen
          </span>{' '}
          <OnboardBoldText>Skapa regel för transaktioner</OnboardBoldText>
          <span>.</span>
        </Body1>
        <Body1>
          I denna ruta kan du välja om du vill ändra transaktionernas kategori
          eller ställa in utsläppsvärde för transaktionerna
        </Body1>

        {/* <Body1>
          Detta öppnar en ny dialogruta. I den här rutan ser du alla
          leverantörers och kontons ID som matchar din sökning. Du kan förfina
          regeln genom att avmarkera de ID:n som inte ska omkategoriseras.
        </Body1>
        <Body1>
          Du kan sedan välja den kategori du vill tillämpa på transaktionerna
          som matchar kriterierna. Du kan också lägga till en kommentar.
          Kommentares syfte är att hjälpa dig komma ihåg varför du skapade den
          här regeln.
        </Body1> */}
      </>
    ),
    footer: OnboardFooter(FooterType.BackForward),
  },

  {
    head: <H6Bold>Så här kan du skapa en omkategoriseringsregel</H6Bold>,
    body: (
      <>
        <Body1>
          Om du väljer att ändra kategori öppnas en ny dialogruta. I den här
          rutan ser du alla leverantörers och kontons ID som matchar din
          sökning.
        </Body1>
        <Body1>
          Du kan sedan välja den kategori du vill tillämpa på transaktionerna
          som matchar kriterierna. Du kan också lägga till en kommentar.
          Kommentares syfte är att hjälpa dig komma ihåg varför du skapade den
          här regeln.
        </Body1>
        <Body1>
          Reglerna du skapar kommer också att tillämpas på alla framtida
          transaktioner de matchar.
        </Body1>
      </>
    ),
    footer: OnboardFooter(FooterType.BackForward),
  },

  {
    head: <H6Bold>Så här kan du skapa en utsläppsregel</H6Bold>,
    body: (
      <>
        <Body1>
          Om du väljer att ändra utsläppsvärde öppnas en ny dialogruta. I den
          här rutan ser du sammanfattning av information om transaktionerna som
          matchar din sökning. Detta för att hjälpa dig att säkerställa att du
          bara kommer att påverka de transaktioner som du har ett utsläppsvärde
          för.
        </Body1>
        <Body1>
          Du kan sedan ange den totala mängden utsläpp som skapas av
          transaktionerna. Och ange var detta utsläppsvärde kommer ifrån. Detta
          för att vi ska kunna verifiera siffrorna du anger.
        </Body1>
        <Body1>
          Denna operation kommer endast att gälla för de befintliga
          transaktioner som matchar sökningen.
        </Body1>
      </>
    ),
    footer: OnboardFooter(FooterType.BackForward),
  },

  {
    head: <H6Bold>Så här fungerar omkategoriseringen</H6Bold>,
    body: (
      <>
        <Body1>
          <span>
            När du skapar regler kommer de att sparas, men de tillämpas först
            när du klickar på&nbsp;
          </span>
          <OnboardBoldText>Tillämpa regler</OnboardBoldText>
          <span>
            &nbsp;eller när en ny import av data görs för din organisation.
          </span>
        </Body1>

        <Body1>
          När du tillämpar dina regler kommer vi att uppdatera de transaktioner
          som matchar reglernas kriterier och påbörja en ny beräkning för att
          uppdatera era resultat.
        </Body1>
        <Body1>
          Att uppdatera resultaten kan ta några minuter, och under tiden kan du
          inte skapa nya kategoriseringsregler. Det är därför vi inte tillämpar
          reglerna omedelbart och uppmuntrar dig att skapa alla dina regler
          innan du tillämpar dem.
        </Body1>
      </>
    ),
    footer: OnboardFooter(FooterType.BackForward),
  },

  {
    head: <H6Bold>Så här kan du hantera dina regler</H6Bold>,
    body: (
      <Body1>
        <span>
          Om du scrollar ner kan du se listan över alla dina befintliga regler.
          Där kan du välja de regler som du vill radera och ta bort dem genom
          att klicka på&nbsp;
        </span>
        <OnboardBoldText>Radera valda regler</OnboardBoldText>
        <span>.</span>
      </Body1>
    ),
    footer: OnboardFooter(FooterType.BackForward),
  },

  {
    head: <H6Bold>Vissa transaktioner kan inte kategoriseras om</H6Bold>,
    body: (
      <>
        <Body1>
          Vissa transaktioner kräver särskild behandling för att kategorisera.
          Detta gäller vanligtvis energitransaktioner och transaktioner för
          vilka din organisation har särskilda behandlingsregler. Dessa
          transaktioner kan inte kategoriseras om genom regler, och de visas i
          grått i sökresultatet.
        </Body1>
        <Body1>
          Transaktioner som kräver särskild behandling kommer att visas i
          sökresultatet om de matchar sökkriterierna i dina regler, men de
          kommer inte att påverkas om du sparar dessa regler. Reglerna kommer
          endast tillämpas på de vanliga transaktionerna som matchar dem.
        </Body1>
        <Body1>
          Om du behöver kategorisera om några av de transaktioner som kräver
          särskild behandling, kontakta oss så fixar vi det.
        </Body1>
      </>
    ),
    footer: OnboardFooter(FooterType.Done),
  },
]

export function RecatOnboardSnack(): React.JSX.Element | null {
  const { orgHasInitializedData, organisation } = useContext(AppDataContext)
  // Hide tour if the org lacks data or the profile is not completed.
  // At the time of writing, the recat page is also covered in an overlay
  // if the org does not have transactions.
  if (!orgHasInitializedData || !organisation.profileCreated) {
    return null
  }
  return (
    <OnboardSnack
      onboardTopics={OnboardTopics}
      displayKey={LOCALSTORAGE_KEY_SHOW_RECAT_INTRO}
    />
  )
}
