import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridToolbar,
  GridValidRowModel,
  useGridApiContext,
} from '@mui/x-data-grid'
import {
  AlertProps,
  Dialog,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import _ from 'lodash'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import { captureException } from '@sentry/browser'
import { Section, SectionId, SysadminSectionId } from '../../emission/Section'
import {
  Flex,
  FlexingBox,
  FlexingBoxContent,
  HeadRow,
} from '../../../components/Flex'
import { TitleWithSub } from '../../../components/TitleWithSub'
import {
  Feature,
  Maybe,
  OrganisationFragment,
  OrganisationsQuery,
  useGetAllPrimaryEmissionsQuery,
  useGetAllUncategorisedEmissionsQuery,
  PaymentInformation,
  useUpdateEnabledFeaturesMutation,
  useUpdateHistoricYearsMutation,
} from '../../../graphql/generated'
import { spacing } from '../../../theme'
import { ProgressBar, reverseGetStatus } from '../../../components/ProgressBar'
import { AppDataContext } from '../../../context/AppDataContext'
import { UpdatePaymentInfoForm } from '../UpdatePaymentInfoForm'
import { SnackAlert } from '../../../components/SnackAlert'
import { Body1Bold } from '../../../components/Typography'
import { ErrorSnackBarContext } from '../../../context/ErrorSnackBarContex'

interface CustomProps {
  id: GridRowId
  value?: string[] | undefined
  field: string
}
function CustomEditComponent(props: CustomProps) {
  const { id, value, field } = props
  const apiRef = useGridApiContext()
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const eventValue = event.target.value // The new value entered by the user
    const newValue =
      typeof eventValue === 'string' ? eventValue.split(',') : eventValue
    apiRef.current.setEditCellValue({
      id,
      field,
      value: newValue,
    })
  }

  return (
    <Select
      multiple
      value={value ?? []}
      onChange={handleChange}
      sx={{ width: '100%' }}
    >
      {Object.values(Feature).map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  )
}

function RenderProgress({
  value,
}: GridRenderCellParams<any, number, any>): React.JSX.Element | null {
  if (value == null) {
    return null
  }

  return (
    <Flex stretchWidth itemsCenter style={{ height: '100%' }}>
      <ProgressBar value={value} getStatus={reverseGetStatus} />
    </Flex>
  )
}

interface Props {
  active: {
    scroll: boolean
    sectionId: SectionId
  }
  handleSectionVisibilityChange: (
    sectionId: SectionId,
    isVisible: boolean,
  ) => void
  orgData: OrganisationsQuery | undefined
}

export function Organisations({
  active,
  handleSectionVisibilityChange,
  orgData,
}: Props): React.JSX.Element {
  const organisations = orgData?.organisations
  const [updateEnabledFeatures] = useUpdateEnabledFeaturesMutation()
  const [updateHistoricYears] = useUpdateHistoricYearsMutation()
  const { data: orgsPrimaryEmissions, error: primaryEmssionsError } =
    useGetAllPrimaryEmissionsQuery()
  const {
    data: orgsUncategorisedEmissions,
    error: uncategorisedEmissionsError,
  } = useGetAllUncategorisedEmissionsQuery()
  const { categoryMap } = useContext(AppDataContext)

  const { setError } = useContext(ErrorSnackBarContext)
  useEffect(() => {
    if (primaryEmssionsError || uncategorisedEmissionsError) {
      captureException(primaryEmssionsError || uncategorisedEmissionsError)
      setError()
    }
  }, [primaryEmssionsError, uncategorisedEmissionsError, setError])

  const rows = useMemo(() => {
    if (!organisations) {
      return []
    }
    if (!orgsPrimaryEmissions || !orgsUncategorisedEmissions) {
      return organisations
    }
    return organisations.map((o) => {
      const primaryEmissions =
        orgsPrimaryEmissions.getAllPrimaryEmissions.filter(
          (e) => e.aggregationKeys[0] === o.id,
        )

      const totalEmissions = _.sumBy(primaryEmissions, (x) => x.totalCO2e)

      const emissionsPercent: { [key: string]: number } = {}
      primaryEmissions.forEach((e) => {
        emissionsPercent[categoryMap[e.aggregationKeys[1]]?.name ?? 'unknown'] =
          e.totalCO2e / totalEmissions
      })

      const uncategorised =
        orgsUncategorisedEmissions.getAllUncategorisedEmissions.find(
          (e) => e.aggregationKeys[0] === o.id,
        )?.totalCO2e ?? 0
      let uncategorisedPercent
      if (totalEmissions > 0) {
        uncategorisedPercent = uncategorised / totalEmissions
      }
      return {
        ...o,
        ...emissionsPercent,
        uncategorised: uncategorisedPercent,
      }
    })
  }, [
    organisations,
    orgsPrimaryEmissions,
    orgsUncategorisedEmissions,
    categoryMap,
  ])

  const [paymentInfo, setPaymentInfo] = useState<
    | {
        paymentInfo: Maybe<PaymentInformation> | undefined
        orgName: string
      }
    | undefined
  >(undefined)

  const getFeatures = (value: OrganisationFragment, row: GridValidRowModel) => {
    const enabledFeatures = row.enabledFeatures?.features
    if (enabledFeatures) {
      return [...enabledFeatures].sort()
    }
    return []
  }

  const setFeatures = (
    value: Array<Feature>,
    row: OrganisationFragment,
  ): OrganisationFragment => {
    return {
      ...row,
      enabledFeatures: {
        id: row.id,
        features: value,
      },
    }
  }

  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)

  // According to the mui documentation the columns prop should keep the same reference between two renders
  // https://mui.com/x/react-data-grid/column-definition/
  // In this case we build the propre dynamicaly based on the cateoryMap to avoid hardcoding it and having problems
  // when changing the map. But in any case, this map should not change during a user session.
  // So by using a useMemo we ensure that the reference stays the same between rendres
  const [columns, columnVisibilityModel] = useMemo(() => {
    const cols: GridColDef<OrganisationFragment>[] = [
      { field: 'id', headerName: 'id', flex: 1, minWidth: 100 },
      { field: 'name', headerName: 'name', flex: 3, minWidth: 200 },
      { field: 'orgType', headerName: 'type', flex: 1, minWidth: 100 },
      { field: 'idp', headerName: 'idp', flex: 1, minWidth: 100 },
      {
        field: 'enabledFeatures',
        headerName: 'features',
        flex: 2,
        minWidth: 150,
        editable: true,
        type: 'singleSelect',
        renderEditCell: (params) => <CustomEditComponent {...params} />,
        valueGetter: getFeatures,
        valueSetter: setFeatures,
        filterable: false,
      },
      {
        field: 'historicYears',
        headerName: 'historic',
        flex: 1,
        editable: true,
        type: 'number',
      },
      {
        field: 'hasTransactions',
        headerName: 'has transactions',
        flex: 1,
        minWidth: 100,
        type: 'boolean',
      },
      {
        field: 'money',
        headerName: 'payment',
        flex: 1,
        type: 'actions',
        getActions: ({ id, row }) => {
          if (row.paymentInfo) {
            return [
              <GridActionsCellItem
                icon={<CreditCardIcon data-testid={`payment-${row.id}`} />}
                label='Payment Info'
                onClick={() =>
                  setPaymentInfo({
                    paymentInfo: row.paymentInfo,
                    orgName: row.name,
                  })
                }
                color='inherit'
                key='edit'
              />,
            ]
          }
          return []
        },
      },
      {
        field: 'uncategorised',
        headerName: 'uncategorised',
        type: 'number',
        renderCell: RenderProgress,
        flex: 1,
        minWidth: 100,
      },
    ]

    const visibilityModel: { [key: string]: boolean } = {
      idp: false,
      id: false,
    }

    Object.values(categoryMap)
      .filter((cat) => !cat?.parentId)
      .forEach((cat) => {
        const name = cat?.name ?? 'unknown'
        cols.push({
          field: name,
          headerName: name,
          type: 'number',
          renderCell: RenderProgress,
          flex: 1,
          minWidth: 100,
        })
        if (cat?.id !== 4) {
          // hide categories others than other by default to not have too many columns
          visibilityModel[name] = false
        }
      })

    return [cols, visibilityModel]
  }, [categoryMap])

  const handleCellEditStop = async (
    newRow: OrganisationFragment,
    oldRow: OrganisationFragment,
  ): Promise<OrganisationFragment> => {
    if (
      !_.isEqual(
        newRow.enabledFeatures?.features,
        oldRow.enabledFeatures?.features,
      )
    ) {
      try {
        // the feature have been updated, persist to server

        await updateEnabledFeatures({
          variables: {
            orgId: newRow.id,
            features: newRow.enabledFeatures?.features,
          },
        })
      } catch (e) {
        captureException(e)
        setSnackbar({
          severity: 'error',
          children: 'Failed to update features',
        })
        return oldRow
      }
    }
    if (newRow.historicYears !== oldRow.historicYears) {
      try {
        await updateHistoricYears({
          variables: {
            orgId: newRow.id,
            historicYears: newRow.historicYears,
          },
        })
      } catch (error) {
        captureException(error)
        setSnackbar({
          children: `Failed to update historic years`,
          severity: 'error',
        })
        return oldRow
      }
    }
    return newRow
  }

  return (
    <Section
      sectionId={SysadminSectionId.organisations}
      active={active}
      onVisibilityChange={handleSectionVisibilityChange}
      key='organisation-section'
    >
      <FlexingBox>
        <HeadRow>
          <TitleWithSub
            title='Organisations'
            sub=''
            infoDescription='list of organisations'
          />
        </HeadRow>
        <Flex itemsStretch style={{ padding: spacing.large }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
              columns: {
                columnVisibilityModel,
              },
            }}
            slots={{
              toolbar: GridToolbar,
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            processRowUpdate={handleCellEditStop}
          />
        </Flex>
      </FlexingBox>
      <Dialog
        open={!!paymentInfo?.paymentInfo}
        onClose={() => setPaymentInfo(undefined)}
      >
        <FlexingBoxContent>
          <Body1Bold data-testid='payment-info-dialog'>
            Payment info for {paymentInfo?.orgName}
          </Body1Bold>
          <UpdatePaymentInfoForm
            onSuccess={() => {
              setSnackbar({
                severity: 'success',
                children: 'Payment info updated',
              })
              setPaymentInfo(undefined)
            }}
            setSnackbar={setSnackbar}
            hidePolicyBox
            paymentInfo={paymentInfo?.paymentInfo ?? undefined}
          />
        </FlexingBoxContent>
      </Dialog>
      <SnackAlert
        open={!!snackbar}
        onClose={() => setSnackbar(null)}
        {...snackbar}
      />
    </Section>
  )
}
