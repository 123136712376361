// we are not doing any logical operation in this file so we do not have risk of running into the
// error that require this check
/* eslint-disable @sayari/no-unwrapped-jsx-text */
import React from 'react'
import { LoginPageWrapper } from '../authentication/components/LoginPageWrapper'
import { Body1, H5, S2Bold } from '../../components/Typography'
import { Li, LiSection, Ol, RubricTitle } from './components/Styles'
import { spacing } from '../../theme'

export function UserAgreementPage(): React.JSX.Element {
  return (
    <LoginPageWrapper error=''>
      <div style={{ textAlign: 'justify', paddingBottom: spacing.medium }}>
        <S2Bold style={{ paddingBottom: spacing.xl }}>
          SVALNA CARBON INTELLIGENCE SYSTEM™
        </S2Bold>
        <H5 style={{ paddingBottom: spacing.xl }}>SERVICE AGREEMENT</H5>
        <Body1>
          This Service Agreement together with its appendices (the “Agreement”)
          is entered into between Svalna AB, org. no. 559040-9016, with its
          registered address at Södra Larmgatan 6, 411 16 Göteborg, Sweden (the
          “Service Provider”), and the entity agreeing to these terms (the
          “Customer”). The Service Provider offers the Carbon Intelligence
          System™, designed to estimate and manage Customers’ carbon footprint.
          The Customer, having reviewed the Service, agrees to engage with the
          Service Provider under the terms outlined herein.
        </Body1>
        <Body1>
          The Service Provider and the Customer are both referred to as the
          Parties and individually as a Party.
        </Body1>
        <Ol>
          <LiSection>
            <RubricTitle>GENERAL</RubricTitle>

            <Ol>
              <Body1>
                <Li>
                  <span>
                    Service Description: Svalna offers the Carbon Intelligence
                    System™, a specialized service aimed at estimating the
                    total carbon footprint of its clients, accessed via a
                    dedicated website. Through this Agreement, the Service
                    Provider undertakes to provide the service as described on
                    its <a href='/privacy-policy'>website</a>.
                  </span>
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Acceptance: By accepting this Agreement upon registration, the
                  Customer affirms to have read, understood, and agreed to
                  comply with its terms. After the Customer has accepted the
                  Agreement, the Customer may use the Service.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Authority: The individual accepting this Agreement on behalf
                  of the Customer confirms to be authorized to represent the
                  Customer in this contractual relationship and possess the
                  legal authority to bind the Customer to this Agreement.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Agreement: The Agreement consists of the following documents:
                </Li>
              </Body1>
              <ol type='i' style={{ marginLeft: spacing.medium }}>
                <Body1>
                  <li>this Service Agreement</li>
                </Body1>
                <Body1>
                  <li>
                    <a href='/data-processing-agreement'>
                      Appendix 1 – Data Processing Agreement
                    </a>
                  </li>
                </Body1>
              </ol>
            </Ol>

            <Body1>
              In the event of conflict between the provisions in the Agreement,
              the documents shall take precedence in the order set out above,
              unless circumstances clearly indicate otherwise. If the terms
              concerning processing of personal data in the Data Processing
              Agreement are in conflict with other terms, the Parties shall
              apply the terms in the Data Processing Agreement.
            </Body1>
          </LiSection>
          <LiSection>
            <RubricTitle>SERVICE USAGE</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  Account Creation: The Customer must provide accurate,
                  complete, and up-to-date organizational information. Failure
                  to comply may result in limited service accessibility or
                  termination of the account.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Data Aggregation: The Customer agrees to data aggregation from
                  various sources, including but not limited to utility
                  companies and official registers, in compliance with
                  applicable laws, the Data Processing Agreement and the Service
                  Provider’s Privacy Policy.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Confidentiality: The Customer is responsible for maintaining
                  the confidentiality of its login information, security methods
                  and other information provided by the Service Provider to
                  access the Service. The Customer shall immediately notify the
                  Service Provider of any unauthorized use of, or access to,
                  such information or the Service.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Fees: The Customer shall, for its use of the Service, pay
                  Service Fees to the Service Provider. The Service Provider has
                  the right to annually adjust the Service Fees upon notice to
                  the Customer. The Customer has the right to terminate the
                  Agreement if the Customer does not accept the new Service
                  Fees.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Payment: The Service Fees are specified upon registration. The
                  Customer will pay the initial Service Fees by credit card or,
                  if offered by the Service Provider, by invoice, upon
                  registration and thereafter. The Service Fees are paid in full
                  at registration or in monthly instalments, depending on what
                  is declared upon registration. The individual making the
                  payment on behalf of the Customer confirms to be authorized to
                  represent the Customer in this contractual relationship and
                  possess the legal authority to make the payment.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Late Payment: If the Customer’s payment is overdue, the
                  Service Provider has the right to, without prior notice, cease
                  the Service to the Customer until full payment has been made
                  or terminate the Agreement with immediate effect. Late payment
                  shall carry interest according to the Swedish Interest Act
                  (1975:635) on the outstanding amount until paid in full.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Third-party Services: The Service may include links to
                  third-party partners, governed by separate terms, which the
                  Customer should review independently.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Modifications: The Service Provider is entitled to modify the
                  Service, without prior notice to the Customer. The Service
                  Provider will endeavor to provide notice for significant
                  changes.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Disclaimer: Unless expressly described in the Agreement, no
                  other conditions, warranties, or terms apply to the provision
                  of the Service. The Service Provider does not warrant that the
                  Service will be error-free or uninterrupted.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>LICENSE & RESTRICTIONS</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  License Grant: A non-exclusive, non-transferable license to
                  use the Service is granted to the Customer under this
                  Agreement.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Restrictions: The Customer shall not sublicense, copy, or
                  distribute the Service.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>INTELLECTUAL PROPERTY RIGHTS</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  Intellectual Property Rights: As between the Parties, the
                  Service Provider is the owner of all intellectual property
                  rights in the Service and the Carbon Intelligence System™,
                  including but not limited to copyrights and trademarks.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  No transfer: Nothing in this Agreement or otherwise between
                  the Service Provider and the Customer shall be construed as
                  transferring or assigning the title or ownership of any
                  intellectual property rights in the Service or the Carbon
                  Intelligence System™ from the Service Provider to the
                  Customer.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Customer Data: As between the Parties, the Customer owns the
                  data provided in the Service by the Customer. The copyright to
                  the report created by the Carbon Intelligence System™ shall,
                  without restrictions, be owned by the Service Provider. The
                  Customer has the right to use the report and specified key
                  indicators herein for its own business purposes. The Service
                  Provider is entitled to use Customer’s data to the extent
                  required for the Service Provider to fulfill its obligations
                  in accordance with this Agreement. However, the Service
                  Provider has the right, without restriction, to use the
                  information about processes’ features, performance, capacity
                  and other statistics or similar details based on the Service’s
                  processing of Customer’s data.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  No other rights: Except as expressly described, the Agreement
                  does not grant either party any rights to the other party’s
                  intellectual property rights.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>SERVICE AVAILABILITY</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  The Service is provided “as is” and on an “as available”
                  basis. To the extent permitted by applicable law, the Service
                  Provider makes no representations or warranties of any kind,
                  express or implied, regarding the suitability, reliability,
                  availability, security, or accuracy of the Service. No
                  information, whether oral or written, will create any
                  representation or warranty not expressly made herein. The
                  Service Provider shall not be responsible for deficiencies in
                  the Service unless the Service Provider has acted negligently
                  or with intentional misconduct.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  The Service Provider is entitled to take measures affecting
                  the access to the Service, if necessary for technical,
                  maintenance or safety reasons and will strive to schedule any
                  planned maintenance or updates of the Service outside of
                  normal working hours as well as to notify the Customer of such
                  activities in advance.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  The Service Provider always has the right to immediately
                  suspend or restrict access to the Service, if use of the
                  Service harms or risks to harm the Service Provider or
                  Customer.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Uptime and Downtime: While the Service Provider strives for
                  uninterrupted Service, there’s no guarantee against occasional
                  downtimes or errors.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Maintenance: Maintenance and upgrades may cause interruptions,
                  for which the Service Provider will attempt to provide prior
                  notice.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>LIMITATION OF LIABILITY</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  General Limitations: The Service Provider shall not be liable
                  for indirect losses or consequential damages, such as loss of
                  production, profit, savings, business, opportunities, data, or
                  revenue.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Cap on Liability: In the event liability for damages would
                  arise, the total liability of the Service Provider for any
                  claims, damages, or losses arising under or in connection with
                  this Agreement, regardless of the cause, shall be limited to
                  direct damages and shall not exceed the total fees paid by the
                  Customer for the Services during the twelve (12) months
                  immediately preceding the incident that gave rise to the
                  claim.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Exclusions from Limitation: The limitations of liability
                  stated in Section 6.1 and 6.2 shall not apply to:
                </Li>
              </Body1>
              <ul style={{ marginLeft: spacing.medium }}>
                <Body1>
                  <li>
                    Liability for death or bodily injury caused by the Service
                    Providers’ negligence;
                  </li>
                </Body1>
                <Body1>
                  <li>Gross negligence or willful misconduct;</li>
                </Body1>
                <Body1>
                  <li>
                    Any other liability which cannot be excluded or limited
                    under applicable law.
                  </li>
                </Body1>
              </ul>
              <Body1>
                <Li>
                  The Customer shall, in order to keep its right to
                  compensation, make claims for damages to the Service Provider
                  within six (6) months from the time of the damage.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Duty to Mitigate: Both Parties agree to take reasonable steps
                  to mitigate the effects of any damage, loss, or expense
                  incurred as a result of the other Party’s breach of this
                  Agreement.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>TRIALS AND BETA TESTING</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  Description: The Service Provider may, from time to time,
                  offer the Customer access to trials of new features or
                  services (“Trials”) or beta versions of the Carbon
                  Intelligence System™ (“Betas”) for the purpose of evaluation
                  and feedback. Such access will be granted on a temporary basis
                  and may be subject to reduced or no charges at the discretion
                  of the Service Provider.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Terms of Use: Access to Trials and Betas is provided “as is”
                  and may include features that are not fully tested or
                  operational. The duration of access, specific functionality
                  available, and any other conditions of use will be
                  communicated to the Customer at the time of the offer. The
                  Customer’s use of Trials and Betas is subject to the terms of
                  this Agreement, except as otherwise specified in this section.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Feedback and Improvements: The Customer is encouraged to
                  provide feedback on their experience with Trials and Betas,
                  including reporting any bugs, errors, or suggestions for
                  improvement. The Service Provider will use this feedback to
                  enhance the Service. Participation in Trials and Betas does
                  not grant the Customer any rights to the final version of the
                  tested features or services, nor does it obligate the Service
                  Provider to adopt any suggested improvements.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Disclaimer and Liability: The Service Provider does not
                  guarantee the stability, functionality, or availability of the
                  Service during Trials and Betas. The standard service level
                  agreements and warranties do not apply to Trials and Betas.
                  The Service Provider’s liability for any issues arising from
                  the use of Trials and Betas is limited as set forth in Section
                  6.2 of this Agreement.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>THIRD-PARTY SERVICES</RubricTitle>
            <Body1>
              Disclaimer: The Service Provider is not responsible for
              third-party services that the Customer may access through the
              Service.
            </Body1>
          </LiSection>
          <LiSection>
            <RubricTitle>DATA PROTECTION & PRIVACY</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  Compliance with Data Protection Laws: The Service Provider and
                  the Customer commit to adhering to all applicable data
                  protection laws and regulations, including but not limited to
                  the EU General Data Protection Regulation (GDPR), in relation
                  to the personal data processed as part of the Services
                  provided under this Agreement.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  <span>
                    Data Processing Agreement: This Agreement includes an{' '}
                    <a href='/data-processing-agreement'>Appendix 1</a>,
                    specifically the Data Processing Agreement, which details
                    the obligations and rights of both the Service Provider (as
                    the Processor) and the Customer (as the Controller)
                    regarding the processing of personal data.
                  </span>
                </Li>
              </Body1>
              <Body1>
                <Li>
                  <span>
                    Privacy Policy: the Service Provider maintains a Privacy
                    Policy, accessible on its{' '}
                    <a href='/privacy-policy'>website</a>, which outlines how
                    personal data from end-users of the Carbon Intelligence
                    System™ is collected, used, and safeguarded. The Privacy
                    Policy provides transparency to data subjects about their
                    data rights and how they can exercise these rights. The
                    Customer ensures that the data subjects have read,
                    understood, and agreed to the Privacy Policy and that the
                    Customer’s use of the Svalna Carbon Intelligence System™
                    aligns with these practices.
                  </span>
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Assurance of Compliance: The Customer agrees to review and
                  adhere to the stipulations of both the Data Processing
                  Agreement and the Privacy Policy. This ensures that the
                  processing of personal data under this Agreement is executed
                  in a lawful, fair, and transparent manner, safeguarding the
                  rights of data subjects. The Service Provider commits to
                  keeping its data protection practices up to date and will
                  notify the Customer of any significant amendments to the Data
                  Processing Agreement or Privacy Policy that may impact the
                  processing activities under this Agreement.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>TERM AND TERMINATION</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  This Agreement commences when duly executed (the “Effective
                  Date”) and continues for an initial term of twelve (12)
                  months. After the initial term, the Agreement continues until
                  terminated by either Party with a thirty (30) days prior
                  written notice to the other Party.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Prorated Refund Policy: Customer is eligible to cancel its
                  subscription within the first three months of the initial term
                  for a prorated refund, which will be calculated as the total
                  fees paid minus the cost of the first three months. Customer
                  is encouraged to provide feedback before cancellation, as the
                  Service Provider aims to resolve any issues. Customer who has
                  previously received a prorated refund is not eligible for
                  another refund if the Customer re-subscribe within 18 months
                  of its initial cancellation. Continuation beyond the initial
                  three months without cancellation signifies acceptance of the
                  Service, barring any previously reported unresolved issues,
                  and the Agreement shall thereafter continue.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Either Party may always terminate this Agreement with written
                  notice if the other Party materially breaches any term of this
                  Agreement and fails to cure such breach within 30 days of
                  notice. However, the Service Provider always reserves the
                  right to, without prior notice and with immediate effect, at
                  any time, modify, limit, suspend or terminate the use of the
                  Service due to a breach of this Agreement or other
                  inappropriate use of the Service, as deemed by the Service
                  Provider, without incurring any liability.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Upon termination of this Agreement, all rights granted by the
                  Service Provider to the Customer shall immediately cease. For
                  the avoidance of doubt, the Customer may not use the Service
                  after this Agreement is terminated or expired.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>FORCE MAJEURE</RubricTitle>
            <Body1>
              Neither Party will be liable for any delay or non-performance of
              its obligations under this Agreement to the extent that such delay
              or non-performance is a result of any condition beyond its
              reasonable control, including but not limited to, governmental
              action, acts of terrorism, war, warlike hostilities, disasters,
              catastrophes, epidemics, quarantines, pandemics, earthquake, fire,
              flood, labor conditions, power failures, and internet
              disturbances. Either party that is prevented or delayed in the
              performance of any of its obligations under this Agreement, shall
              notify the other Party in writing thereof without delay.
            </Body1>
          </LiSection>
          <LiSection>
            <RubricTitle>GOVERNING LAW</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  This Agreement shall be governed by and construed in
                  accordance with the laws of Sweden, without regard to its
                  conflict of laws principles.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Any dispute, controversy or claim arising out of or in
                  connection with this Agreement, shall be settled in general
                  court in Sweden.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>CONFIDENTIALITY</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  Obligation of Confidentiality: Both Parties agree to keep all
                  confidential information received from each other in
                  connection with this Agreement strictly confidential. This
                  includes protecting such information with the same care as
                  one’s own confidential information and disclosing it only to
                  personnel or subcontractors who need to know it for the
                  purpose of this Agreement and are bound by similar
                  confidentiality obligations.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Permitted Disclosures: Confidential information may be
                  disclosed if required by law or for the purpose of enforcing
                  this Agreement, provided the disclosing Party consults with
                  the other Party, when possible, before making such a
                  disclosure.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Exceptions: The confidentiality obligations do not apply to
                  information that is publicly available (unless made public as
                  a breach of this Agreement), already known to the receiving
                  Party without confidentiality restrictions or obtained from a
                  third party without breach of any confidentiality obligation.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Post-Termination: Upon termination or expiration of this
                  Agreement, each Party must return or destroy the other Party’s
                  confidential information, except as required to comply with
                  legal obligations.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Duration: The confidentiality obligations continue for five
                  years after the termination or expiration of this Agreement.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>MISCELLANEOUS</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  This Agreement constitutes the entire agreement between the
                  Parties regarding its subject matter and supersedes all prior
                  agreements, understandings, negotiations, and discussions,
                  whether oral or written. Any amendment to the Agreement must
                  be in writing and signed by both Parties.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  The Agreement, in whole or in part, may not be assigned or
                  otherwise transferred by any Party without the prior written
                  consent of the other Party.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  The Agreement does not create any agency, partnership, or
                  joint venture between the Parties.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  The Parties understand and agree that no failure or delay in
                  exercising any right, power or privilege hereunder will
                  operate as a waiver thereof, nor will any single or partial
                  exercise of such rights, powers or privileges preclude any
                  other or further exercise thereof. To be effective, any waiver
                  of any right, power or privilege under the Agreement shall be
                  in writing and signed by the Party against whom the waiver is
                  sought to be enforced.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  If any terms of the Agreement or the application of it shall
                  be declared or deemed void, invalid, or unenforceable in whole
                  or in part for any reason, the remaining terms of the
                  Agreement shall continue in full force and effect.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
        </Ol>
      </div>
    </LoginPageWrapper>
  )
}
