import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined'
import BarChartIcon from '@mui/icons-material/BarChart'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import PieChartIcon from '@mui/icons-material/PieChart'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import { SvgIconTypeMap } from '@mui/material'
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined'
import { Icon } from '../Icon'
import { CaptionBold } from '../Typography'
import { Feature, Role, useMeQuery } from '../../graphql/generated'
import { colors, spacing } from '../../theme'
import { Flex } from '../Flex'
import { StepId, emissionTourClassNames } from '../tour/tourConstants'

const Nav = styled.nav`
  width: 100%;
  height: 100%;
`

const NavList = styled.ul`
  height: 100%;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
`

const Item = styled.li`
  display: inline-flex;
  padding: 10px 21px 10px 16px;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  list-style-type: none;
  text-decoration: none;
  cursor: pointer;
  width: 100%;

  &:hover {
    opacity: 1;
    background-color: #505060;
  }
  &:focus-visible {
    outline: 2px solid rgba(0, 169, 106, 1);
    opacity: 1;
    background-color: #505060;
  }
`

const ListItem = styled(Item).withConfig({
  shouldForwardProp: (prop) => !['isSelected', 'autoTopMargin'].includes(prop),
})<{ isSelected: boolean; autoTopMargin?: boolean }>`
  border-left: 2px solid
    ${({ isSelected }) => (isSelected ? ` ${colors.white}` : 'transparent')};
  background-color: ${({ isSelected }) =>
    isSelected ? `#26262E` : 'transparent'};
  opacity: ${({ isSelected }) => (isSelected ? `1` : '0.6')};
  ${({ autoTopMargin }) => (autoTopMargin ? 'margin-top: auto;' : '')}
`

const DisplayName = styled(CaptionBold).withConfig({
  shouldForwardProp: (prop) => !['isSelected'].includes(prop),
})<{ isSelected: boolean }>`
  color: ${({ isSelected }) => (isSelected ? colors.white : colors.white)};
`

function Header({ path }: { path: string }) {
  const history = useHistory()

  return (
    <Item
      onClick={() => history.push(path.slice(1, path.length))}
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          history.push(path.slice(1, path.length))
        }
      }}
      style={{
        marginTop: spacing.medium,
      }}
      role='button'
    >
      <Icon
        src='https://svalna.se/web/user/themes/svalna/img/logo_white.png'
        alt={path}
        width={97}
        height={45}
      />
    </Item>
  )
}

function NavItem({
  IconSrc,
  path,
  displayName,
  autoTopMargin,
  className,
}: {
  IconSrc: OverridableComponent<SvgIconTypeMap> & { muiName: string }
  path: string
  displayName: string
  autoTopMargin?: boolean
  className?: string
}) {
  const history = useHistory()
  const { pathname } = useLocation()
  const isSelected = pathname === path

  return (
    <ListItem
      onClick={() => history.push(path.slice(1, path.length))}
      isSelected={isSelected}
      autoTopMargin={autoTopMargin}
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          history.push(path.slice(1, path.length))
        }
      }}
      role='button'
      className={className}
    >
      <IconSrc style={{ color: colors.white, fontSize: 20 }} />
      <DisplayName isSelected={isSelected}>{displayName}</DisplayName>
    </ListItem>
  )
}

export function Navigation(): React.JSX.Element {
  const { data } = useMeQuery() // this is called inside AppDataContext which will handle the error
  const user = data?.me

  const displayRecat =
    user?.role &&
    [Role.SysAdmin, Role.Admin].includes(user?.role) &&
    user?.organisation.enabledFeatures?.features?.includes(Feature.Transactions)

  const displayGhgp =
    user?.role &&
    [Role.SysAdmin, Role.Admin].includes(user?.role) &&
    user?.organisation.enabledFeatures?.features?.includes(Feature.Ghgp)

  return (
    <Nav>
      <NavList>
        {/* redirect to root so that it goes to the landing page or dashboard deppending of the user's state */}
        <Header path='/' />
        <Flex column spaceBetween style={{ paddingTop: 100, height: '100%' }}>
          <Flex column stretchWidth>
            <NavItem
              IconSrc={BarChartIcon}
              path='/emission'
              displayName='Utsläpp'
              className={emissionTourClassNames[StepId.e1EmissionNav]}
            />
            {user?.organisation.profileCreated && (
              <>
                {user?.organisation.enabledFeatures?.features?.includes(
                  Feature.Goal,
                ) && (
                  <NavItem
                    IconSrc={FlagOutlinedIcon}
                    path='/goal'
                    displayName='Mål'
                  />
                )}
                {displayRecat && (
                  <NavItem
                    IconSrc={SwapHorizOutlinedIcon}
                    path='/recat'
                    displayName='Transaktioner'
                  />
                )}
                {displayGhgp && (
                  <NavItem
                    IconSrc={PieChartIcon}
                    path='/ghgp'
                    // Using a non-breaking hyphen for "GHG-scopes".
                    displayName={`GHG\u2011scopes`}
                  />
                )}
              </>
            )}
          </Flex>
          <Flex column stretchWidth>
            {user?.role === Role.SysAdmin && (
              <NavItem
                IconSrc={SettingsOutlinedIcon}
                path='/sysadmin'
                displayName='Sysadmin'
              />
            )}
            <NavItem
              autoTopMargin
              IconSrc={HelpOutlineIcon}
              path='/help'
              displayName='Hjälp'
              className={emissionTourClassNames[StepId.e5SupportNav]}
            />
            <NavItem
              IconSrc={PersonOutlineOutlinedIcon}
              path='/settings'
              displayName='Konto'
            />
          </Flex>
        </Flex>
      </NavList>
    </Nav>
  )
}
