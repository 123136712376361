import React from 'react'
import { Body1, Body1Bold } from '../../../components/Typography'
import { ButtonGreen } from '../../../components/Buttons'
import onboardingImage from '../../../assets/images/onboarding.png'
import { InnerInnerBox } from '../../../components/Flex'
import { Loader } from '../../../components/Loader'

export function WelcomeStep({
  goToNextStep,
  loading,
}: {
  goToNextStep: () => void
  loading: boolean
}): React.JSX.Element {
  return (
    <>
      <Body1Bold data-testid='welcome-step'>Välkommen!</Body1Bold>
      <InnerInnerBox>
        <img
          src={onboardingImage}
          alt=''
          style={{
            alignSelf: 'center',
            maxHeight: 328,
            minHeight: 200,
            height: '30vh',
          }}
        />
        <Body1>
          Välkommen till Svalnas Carbon Intelligence System™ - er
          forskningsbaserade klimatkalkylator!
        </Body1>
        <Body1>
          På bara några steg får ni en tydlig och tillförlitlig kartläggning av
          företagets klimatutsläpp, direkt baserat på företagets faktiska
          ekonomiska data. Använd verktyget för att förstå och hitta utrymmen
          för att ni era klimatmål!
        </Body1>
      </InnerInnerBox>
      <ButtonGreen
        style={{ alignSelf: 'center' }}
        onClick={() => {
          goToNextStep()
        }}
        endIcon={
          loading && <Loader size={14} borderSize={2} data-testid='loader' />
        }
        data-testid='next-button'
        disabled={loading}
      >
        Kom igång
      </ButtonGreen>
    </>
  )
}
