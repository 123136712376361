import { ApolloError } from '@apollo/client'

// For some reason apolloError does not have the code field in its type while it is in the object we get
export type CustomerGraphQLError = {
  code: number
  message: string
  path: string
}

export function apolloErrorHandler(error: ApolloError): string {
  if ((error.cause as unknown as CustomerGraphQLError).code === 400) {
    return `Något gick fel: ${error.message}`
  } else {
    return `Något gick fel`
  }
}
