import React from 'react'
import { LoginPageWrapper } from '../authentication/components/LoginPageWrapper'
import { Body1, H5, S2Bold } from '../../components/Typography'
import { spacing } from '../../theme'
import { RubricTitle } from './components/Styles'

export function PaymentTerms(): React.JSX.Element {
  return (
    <LoginPageWrapper error=''>
      <div style={{ textAlign: 'justify', paddingBottom: spacing.medium }}>
        <S2Bold style={{ paddingBottom: spacing.xl }}>
          SVALNA CARBON INTELLIGENCE SYSTEM™
        </S2Bold>
        <div style={{ paddingBottom: spacing.xl }}>
          <H5>Betalningsvillkor</H5>
          <S2Bold>Last Updated: 2024-12-17</S2Bold>
        </div>
        <Body1>
          <span>
            Dessa betalningsvillkor utgör en del av och ska tolkas i enlighet
            med det tillhörande&nbsp;
          </span>
          <a href='/user-agreement'>serviceavtalet</a>
          <span>
            . Vid eventuella konflikter mellan betalningsvillkoren och
            serviceavtalet gäller det som anges i serviceavtalet.
          </span>
        </Body1>
        <RubricTitle>Priser</RubricTitle>
        <Body1>
          Alla priser anges i svenska kronor (SEK) exklusive moms. Moms och
          eventuella andra tillämpliga skatter eller avgifter tillkommer vid
          fakturering.
        </Body1>
        <RubricTitle>Betalning</RubricTitle>
        <Body1>
          Betalning ska göras i förskott. Serviceavgifterna specificeras vid
          registrering. Kunden betalar de initiala serviceavgifterna med
          kreditkort eller, om erbjudet av Svalna AB, via faktura vid
          registrering och därefter. Serviceavgifterna betalas i sin helhet vid
          registrering eller i månadsvisa delbetalningar, beroende på vad som
          avtalats vid registrering. Vid delbetalningar kan en administrativ
          avgift tillkomma, vilken tydligt anges vid registreringstillfället.
        </Body1>
        <Body1>
          Fakturor förfaller till betalning trettio (30) dagar från
          fakturadatum. Den första fakturan utfärdas vid aktivering eller
          publicering av tjänsterna, om inte annat angivits i serviceavtalet
          eller vid registrering.
        </Body1>
        <RubricTitle>Återbetalningspolicy</RubricTitle>
        <Body1>
          <span>
            Kunden har rätt att säga upp sin prenumeration inom de första tre
            (3) månaderna av den initiala perioden (se
          </span>{' '}
          <a href='/user-agreement'>serviceavtalet</a>
          <span>
            &nbsp;för detaljer). Vid uppsägning inom denna period sker en
            fullständig återbetalning. Ingen återbetalning sker för redan
            betalda avgifter efter de tre (3) första månaderna. Fortsatt
            användning efter de initiala tre månaderna utan uppsägning innebär
            kundens acceptans av tjänsten, och avtalet fortsätter därefter under
            tolv (12) månader enligt vad som framgår av serviceavtalet.
          </span>
        </Body1>
        <RubricTitle>Prisjusteringar</RubricTitle>
        <Body1>
          Svalna AB förbehåller sig rätten att justera priser minst en (1) månad
          innan avtalet förnyas. Informationen om prisjusteringar kommuniceras
          till kunden skriftligen, exempelvis via e-post till den registrerade
          kontaktpersonen eller via kundportalen. Om kunden inte accepterar de
          justerade priserna har kunden rätt att säga upp avtalet i enlighet med
          vad som anges i serviceavtalet.
        </Body1>
        <RubricTitle>Sen betalning</RubricTitle>
        <Body1>
          Vid sen betalning har Svalna AB rätt att, utan föregående meddelande,
          upphöra med att tillhandahålla tjänsten tills full betalning har
          erlagts eller säga upp avtalet med omedelbar verkan. Sen betalning
          medför dröjsmålsränta enligt räntelagen (1975:635) på det utestående
          beloppet tills full betalning skett. Påminnelseavgifter och eventuella
          inkassokostnader kan tillkomma enligt gällande lag och praxis.
        </Body1>
        <RubricTitle>Full avtalstid</RubricTitle>
        <Body1>
          Avgiften för tjänsterna är betalbar för hela avtalstiden enligt
          serviceavtalet, även om kunden upphör att använda tjänsterna innan
          periodens slut.
        </Body1>
      </div>
    </LoginPageWrapper>
  )
}
