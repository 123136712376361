import React from 'react'
import { AlertProps } from '@mui/material'
import Svalna from '../@types'
import { SnackAlert } from '../components/SnackAlert'

interface Props {
  setError: (
    error?: AlertProps['children'],
    severity?: AlertProps['severity'],
  ) => void
}

const initialState: Props = {
  setError: () => {},
}

export const ErrorSnackBarContext = React.createContext<Props>(initialState)

const { Provider } = ErrorSnackBarContext

/**
 * Fetches and stores static data for logged in user
 * displays loading if no data is present
 */
export function ErrorSnackBarProvider({
  children,
}: Svalna.PropWithChildren): React.JSX.Element {
  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)

  const setError = (
    error: AlertProps['children'] = 'Något gick fel',
    severity: AlertProps['severity'] = 'error',
  ) => {
    setSnackbar({ children: error, severity })
  }
  return (
    <Provider value={{ setError }}>
      <SnackAlert
        open={!!snackbar}
        onClose={() => setSnackbar(null)}
        {...snackbar}
      />
      {children}
    </Provider>
  )
}
