import { FormikErrors } from 'formik'

const constraints = [
  'isEmail',
  'isEnum',
  'isNotEmpty',
  'maxLength',
  'MinLength',
  'max',
  'min',
] as const

type Constraints = (typeof constraints)[number]

type ValidationConstraints = {
  [K in Constraints]?: string
}
type ValidationError = {
  property: string
  constraints: ValidationConstraints
}

type Errors = {
  [K: string]: string
}

export function handleValidationError<T>(
  setErrors: (errors: FormikErrors<T>) => void,
  validationErrors: ValidationError[],
): boolean {
  let hasSetError = false
  const errors: Errors = {}
  validationErrors.forEach((validErr) => {
    constraints.forEach((value) => {
      if (validErr.constraints[value]) {
        errors[validErr.property] =
          (errors[validErr.property] ?? '') + validErr.constraints[value]
        hasSetError = true
      }
    })
  })
  if (hasSetError) {
    setErrors(errors as FormikErrors<T>)
  }
  return hasSetError
}
