import React, { useContext } from 'react'
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridValidRowModel,
} from '@mui/x-data-grid'
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined'
import { Stack, Tooltip } from '@mui/material'
import { Flex } from '../../../Flex'
import { RecatContext } from '../../../../context/RecatContext'
import { Maybe, RecatRule } from '../../../../graphql/generated'
import { spacing } from '../../../../theme'
import { SnackAlert } from '../../../SnackAlert'

interface RowType extends GridValidRowModel {
  id: number
  supplier: string
  supplierId: Maybe<string> | undefined
  account: string
  accountId: number
  comment: Maybe<string> | undefined
  category: string
  notRecategorisable: Maybe<boolean> | undefined
  attachedRule: Maybe<RecatRule> | undefined
}

function TransactionWithRule(props: GridRowParams): React.JSX.Element {
  // I don't know how to set the type while respecting the linter here
  // eslint-disable-next-line react/destructuring-assignment
  const row = props.row as RowType
  if (!row?.attachedRule) {
    // just here for the linter
    throw new Error('this should never happen')
  }
  // TODO RECAT put this commented out section back when the new emission model is published
  // let layers = ''
  // if (row.attachedRule.emissionLayers) {
  //   layers = Object.entries(row.attachedRule.emissionLayers)
  //     .map(([key, value]) => {
  //       if (value && layerKeys.includes(key)) {
  //         return layerKeyToName(key)
  //       }
  //       return undefined
  //     })
  //     .filter((value) => !!value)
  //     .join(', ')
  // }
  return (
    <Tooltip
      placement='left'
      arrow
      title={
        `Denna transaktions utsläpp bestäms av en användarregel. Denna regel anger utsläppsintensiteten till ${row.attachedRule.emissionIntensity?.toFixed(
          5,
        )}`
        // TODO RECAT put this commented out section back when the new emission model is published
        // för lager ${layers}. De återstående lagrens utsläpp bestäms av systemet`}
      }
    >
      <TaskOutlinedIcon titleAccess='användarregel' />
    </Tooltip>
  )
}

function TransactionLocked(props: GridRowParams): React.JSX.Element {
  return (
    <Tooltip
      placement='left'
      arrow
      title='Din organisation har särskilda regler för vissa transaktioner. Dessa transaktioner kan inte omkategoriseras här. Kontakta oss om du behöver fixa dessa transaktioner.'
    >
      <TaskOutlinedIcon titleAccess='kan inte omkategoriseras' />
    </Tooltip>
  )
}

const transactionsColumns: GridColDef[] = [
  { field: 'supplier', headerName: 'Leverantör', flex: 2 },
  { field: 'supplierId', headerName: 'Leverantörs-ID', flex: 2 },
  { field: 'account', headerName: 'Konto', flex: 2 },
  { field: 'accountId', headerName: 'Konto-ID', flex: 1 },
  {
    field: 'comment',
    headerName: 'Beskrivning',
    flex: 3,
  },
  { field: 'date', headerName: 'Datum', flex: 2 },
  {
    field: 'category',
    headerName: 'Kategori',
    flex: 2,
  },
  {
    field: 'actions',
    headerName: '',
    type: 'actions',
    cellClassName: 'actions',
    flex: 1,
    align: 'right',
    getActions: (param) => {
      if (param.row.notRecategorisable) {
        return [<TransactionLocked {...param} key='lockedLine' />]
      }
      if (param.row.attachedRule) {
        return [<TransactionWithRule {...param} key='ruleLine' />]
      }
      return []
    },
  },
]

function noRow() {
  return (
    <Stack height='100%' alignItems='center' justifyContent='center'>
      Inga transaktioner matchar sökningen
    </Stack>
  )
}

export function TransactionsBox(): React.JSX.Element {
  const {
    numTransactions,
    transactions,
    setPagination,
    pagination,
    loading,
    transactionsError,
  } = useContext(RecatContext)

  const transactionsRows: RowType[] = transactions?.map((t, index) => {
    return {
      id: index,
      supplier: t.transaction.supplierName,
      supplierId: t.transaction.supplierId,
      account: t.transaction.accountName,
      accountId: t.transaction.accountId,
      comment: t.transaction.comment,
      category: t.transaction.category.name,
      notRecategorisable: t.transaction.notRecategorisable,
      date: t.transaction.date,
      attachedRule: t.attachedRule,
    }
  })

  return (
    <Flex column stretchWidth style={{ gap: spacing.medium }}>
      <SnackAlert open={!!transactionsError} severity='error'>
        Fel vid sökning efter transaktioner
      </SnackAlert>
      <DataGrid
        style={{ width: '100%' }}
        rows={transactionsRows ?? []}
        columns={transactionsColumns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        paginationMode='server'
        paginationModel={pagination}
        pageSizeOptions={[10, 20, 50, 100]}
        onPaginationModelChange={setPagination}
        rowCount={numTransactions}
        loading={loading}
        autoHeight
        slots={{
          noRowsOverlay: noRow,
          noResultsOverlay: noRow,
        }}
        disableVirtualization
      />
    </Flex>
  )
}
