import React, { useCallback, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { captureException } from '@sentry/browser'
import { Flex } from '../../components/Flex'
import { UserContext } from '../../context/UserContext'
import { useSwamidAuthenticationMutation } from '../../graphql/generated'
import { setToken } from '../../utils/auth'
import { ButtonGreen } from '../../components/Buttons'
import { H5, S2Bold } from '../../components/Typography'

interface Props {
  setError: React.Dispatch<React.SetStateAction<string>>
  error: string
}

export function SSOLogin({ setError, error }: Props): React.JSX.Element {
  const [swamidlogin] = useSwamidAuthenticationMutation()
  const history = useHistory()
  const { setLoggedIn } = useContext(UserContext)

  const loginWithSwamid = useCallback(async () => {
    try {
      const res = await swamidlogin()
      if (res.data?.swamidLogin?.errors?.length) {
        setError(res.data.swamidLogin.errors[0].message)
        return
      }

      if (res.data?.swamidLogin.authentication) {
        const { token, expiresAt, refreshToken } =
          res.data.swamidLogin.authentication
        setToken(token, refreshToken, expiresAt)
        setLoggedIn(true)
        history.push('/')
        return
      }
      // we should never reach this point as if the data were not set an error should have been thrown
      // and if data was set we should hve gone in one of the if statements above
      throw new Error('No data returned')
    } catch (err) {
      captureException(err)
      setError('Något gick fel, försök igen senare.')
    }
  }, [history, setError, setLoggedIn, swamidlogin])

  useEffect(() => {
    loginWithSwamid()
  }, [loginWithSwamid])

  return (
    <>
      <Flex column data-testid='sso-login'>
        <S2Bold>Välkommen!</S2Bold>
        {error ? (
          <H5 data-testid='error-msg'>Något gick fel.</H5>
        ) : (
          <H5 data-testid='success-msg'>Du loggas snart in.</H5>
        )}
      </Flex>
      {error && (
        <Flex column stretchWidth>
          <ButtonGreen
            onClick={loginWithSwamid}
            style={{ width: '100%' }}
            data-testid='retry-button'
          >
            Försök igen
          </ButtonGreen>
        </Flex>
      )}
    </>
  )
}
