import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { Button, Tooltip } from '@mui/material'
import { Flex } from '../../../components/Flex'
import { ButtonGreen, ButtonLightBorder } from '../../../components/Buttons'
import { Loader } from '../../../components/Loader'
import { Role, useMeQuery } from '../../../graphql/generated'
import { steps } from '../ProfileSteps/ProfileStepper'
import { spacing } from '../../../theme'

interface NavButtonsProp {
  isProfileCreation?: boolean
  setActiveStep: (step: number) => void
  activeStep: number
  loading: boolean
  enableEdit: (editable: boolean) => void
  editEnabled: boolean
  saveAndContinue: () => Promise<void>
  saveAndClose: () => Promise<void>
  reset: () => void
}
export function NavButtons({
  isProfileCreation,
  setActiveStep,
  activeStep,
  loading,
  enableEdit,
  editEnabled,
  saveAndContinue,
  saveAndClose,
  reset,
}: NavButtonsProp): React.JSX.Element {
  const { data: meData } = useMeQuery() // this is called inside AppDataContext which will handle the error
  const user = meData?.me

  return (
    <Flex stretchWidth itemsCenter spaceBetween>
      <Flex style={{ gap: spacing.small }}>
        {!isProfileCreation && (
          <>
            <ButtonLightBorder
              onClick={() => {
                if (editEnabled) {
                  reset()
                }
                enableEdit(!editEnabled)
              }}
              disabled={
                !user || ![Role.Admin, Role.SysAdmin].includes(user?.role)
              }
              data-testid='edit-button'
            >
              {editEnabled ? 'AVBRYT' : 'ÄNDRA'}
            </ButtonLightBorder>
            {editEnabled && (
              <ButtonGreen
                onClick={saveAndClose}
                type='button'
                disabled={loading}
                data-testid='save-button'
              >
                {loading ? 'Laddar...' : 'SPARA'}
              </ButtonGreen>
            )}
          </>
        )}
      </Flex>
      <Flex>
        <Tooltip
          title={
            !isProfileCreation && editEnabled
              ? 'Du måste spara eller avbryta dina ändringar innan du kan lämna denna sida'
              : ''
          }
        >
          <span>
            <Button
              disabled={
                loading ||
                activeStep === 0 ||
                (!isProfileCreation && editEnabled)
              }
              onClick={() => {
                setActiveStep(activeStep - 1)
              }}
              color='secondary'
              sx={{
                '& .MuiButton-endIcon': { marginBottom: '2px', marginLeft: 0 },
                '& .MuiButton-startIcon': {
                  marginBottom: '2px',
                  marginRight: 0,
                },
              }}
              startIcon={
                <ArrowBackIosNewIcon style={{ fontSize: 'inherit' }} />
              }
              data-testid='back-button'
            >
              TILLBAKA
            </Button>
          </span>
        </Tooltip>
        <Tooltip
          title={
            !isProfileCreation && editEnabled
              ? 'Du måste spara eller avbryta dina ändringar innan du kan lämna denna sida'
              : ''
          }
        >
          <span>
            <Button
              onClick={() => {
                if (isProfileCreation) {
                  saveAndContinue()
                } else {
                  setActiveStep(activeStep + 1)
                }
              }}
              disabled={
                loading ||
                (!isProfileCreation && editEnabled) ||
                (activeStep === steps.length - 1 && !isProfileCreation)
              }
              color='secondary'
              sx={{
                '& .MuiButton-endIcon': { marginBottom: '2px', marginLeft: 0 },
                '& .MuiButton-startIcon': {
                  marginBottom: '2px',
                  marginRight: 0,
                },
              }}
              endIcon={<ArrowForwardIosIcon style={{ fontSize: 'inherit' }} />}
              startIcon={
                loading && (
                  <Loader
                    size={14}
                    borderSize={2}
                    style={{ marginRight: spacing.small }}
                  />
                )
              }
              data-testid='next-button'
            >
              {loading ? 'Laddar' : 'FORTSÄTT'}
            </Button>
          </span>
        </Tooltip>
      </Flex>
    </Flex>
  )
}
