import {
  AlertProps,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { captureException } from '@sentry/browser'
import {
  GetRegistrationsDocument,
  useApproveOrgRegistrationMutation,
  useGetSubscriptionPlansQuery,
} from '../../../graphql/generated'
import { ButtonGreen } from '../../../components/Buttons'
import { InnerInnerBox } from '../../../components/Flex'
import { Body1 } from '../../../components/Typography'
import { Loader } from '../../../components/Loader'
import { colors } from '../../../theme'

export function QuoteForm({
  close,
  setSnackbar,
  orgId,
  selectedPlan,
}: {
  close: () => void
  setSnackbar: React.Dispatch<
    React.SetStateAction<Pick<AlertProps, 'children' | 'severity'> | null>
  >
  orgId: string
  selectedPlan: string
}): React.JSX.Element {
  const [approveOrgRegistration] = useApproveOrgRegistrationMutation({
    refetchQueries: [{ query: GetRegistrationsDocument }],
  })

  const {
    data: subscriptionPlanData,
    loading: subscriptionPlanLoading,
    error: subscriptionPlanError,
  } = useGetSubscriptionPlansQuery()

  useEffect(() => {
    if (subscriptionPlanError) {
      setSnackbar({
        severity: 'error',
        children: 'något gick fel, vänligen ladda om sidan',
      })
    }
  }, [subscriptionPlanError, setSnackbar])

  const schema = Yup.object().shape({
    quote: Yup.number().min(0),
    subscriptionPlanName: Yup.string()
      .oneOf(
        subscriptionPlanData?.getSubscriptionPlans.map((plan) => plan.name) ??
          [],
        'Ogiltig prenumerationsplan',
      )
      .required('Nödvändig'),
  })

  const {
    handleSubmit,
    isSubmitting,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      quote: 0,
      subscriptionPlanName: selectedPlan,
    },
    onSubmit: async (variables): Promise<boolean> => {
      try {
        const result = await approveOrgRegistration({
          variables: {
            id: orgId,
            quote: variables.quote,
            subscriptionPlanName: variables.subscriptionPlanName,
          },
        })
        if (result.data) {
          setSnackbar({
            children: (
              <Body1 data-testid='link-sent'>
                Användaren har lagts till och ett mail har skickats till dennes
                inkorg.
              </Body1>
            ),
            severity: 'success',
          })
          close()
          return true
        }
        // we should never reach this point as if the data were not set an error should have been thrown
        throw new Error('No data returned')
      } catch (err) {
        captureException(err)
        setSnackbar({
          children: 'Something went wrong when approving the registration',
          severity: 'error',
        })
      }

      return false
    },
    validationSchema: schema,
  })

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <InnerInnerBox>
        <TextField
          id='quote'
          label='quote (yearly)'
          variant='standard'
          type='number'
          style={{ width: '100%' }}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.quote}
          error={touched.quote && !!errors.quote}
          helperText={touched.quote && errors.quote}
          required
          placeholder='0'
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        />
        <FormControl fullWidth variant='standard'>
          <InputLabel
            id='subscription-plan-label'
            required
            error={
              !!touched.subscriptionPlanName && !!errors.subscriptionPlanName
            }
          >
            Prisplan
          </InputLabel>
          <Select
            name='subscriptionPlanName'
            labelId='subscription-plan-label'
            id='subscription-plan-select'
            label='Prisplan'
            onChange={handleChange}
            value={values.subscriptionPlanName}
            displayEmpty
            error={
              !!touched.subscriptionPlanName && !!errors.subscriptionPlanName
            }
          >
            {subscriptionPlanLoading && (
              <MenuItem value='loading' key='loading'>
                <Loader size={20} borderSize={4} />
                <span>&nbsp;Laddar</span>
              </MenuItem>
            )}
            {subscriptionPlanData?.getSubscriptionPlans.map((plan) => {
              return (
                <MenuItem value={plan.name} key={plan.name}>
                  {plan.name}
                </MenuItem>
              )
            })}
          </Select>
          {touched.subscriptionPlanName && errors.subscriptionPlanName && (
            <FormHelperText style={{ color: colors.red }}>
              {errors.subscriptionPlanName}
            </FormHelperText>
          )}
        </FormControl>
        <ButtonGreen type='submit' disabled={isSubmitting} data-testid='submit'>
          save
        </ButtonGreen>
      </InnerInnerBox>
    </form>
  )
}
