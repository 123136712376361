import _ from 'lodash'
import React, {
  useCallback,
  useContext,
  useEffect,
  useId,
  useState,
} from 'react'
import styled from 'styled-components'
import {
  FormControl,
  MenuItem,
  InputLabel,
  TextField,
  InputAdornment,
} from '@mui/material'
import { captureException } from '@sentry/browser'
import { Flex } from '../Flex'
import { TooltipInfo } from '../InfoIcon'
import {
  GetOrgGoalDocument,
  OrgGoalCurveType,
  Role,
  useMeQuery,
  useSetOrgGoalMutation,
} from '../../graphql/generated'
import { SelectLightBorder } from '../Select'
import { ButtonGreen, ButtonLightBorder } from '../Buttons'
import { Loader } from '../Loader'
import { Body1Bold } from '../Typography'
import { mediumGap, spacing, theme, xlGap } from '../../theme'
import { nearestMultiple } from '../../utils/math'
import { GoalSettingContext } from '../../context/GoalSettingContext'
import { GoalContext } from '../../context/GoalContext'

// Vertical gap for inputs/buttons in grid.
const gridRowGap = mediumGap
// The gap between the inputs (year, curve, etc) and the save/reset buttons
// should be `xlGap`.
// Because the buttons are inside the grid-div, they already have `gridRowGap`
// as spacing. In order for the margin to equal `xlGap`,
// the difference is added to the margin, so that
// `gridRowGap` + `buttonGapMargin` = `xlGap`.
const buttonGapMargin = xlGap - gridRowGap

const ListHeader = styled.span`
  font-weight: bold;
  margin-right: ${spacing.small};
`

// Mimic style of SelectLightBorder for the reduction percent input.
const ReductionPercentInput = styled(TextField)({
  '& .MuiInputBase-root': {
    borderRadius: '10px',
    height: '33px',
    fontSize: '14px',
  },
})

export const curveTypeLabels: Readonly<{
  [curveType in OrgGoalCurveType]: string
}> = {
  [OrgGoalCurveType.Line]: 'Linjär',
  [OrgGoalCurveType.S]: 'S-kurva',
  [OrgGoalCurveType.Exp]: 'Exponentiell',
} as const

export function GoalParameters(): React.JSX.Element {
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false)
  const [parameterError, setParameterError] = useState<string | undefined>()

  const [setOrgGoal] = useSetOrgGoalMutation({
    refetchQueries: [{ query: GetOrgGoalDocument }],
  })

  const {
    maybeTargetFactor, // can be undefined, if no valid input is given
    setCurveType,
    setMaybeTargetFactor,
    setReferenceYear,
    setTargetYear,
    resetToDefault,
    hasChanges,
  } = useContext(GoalSettingContext)

  const { loading, error: goalError, data: goalData } = useContext(GoalContext)

  let reductionPercent: number | '' = ''
  if (_.isNumber(maybeTargetFactor)) {
    reductionPercent = Math.round(100 * (1 - maybeTargetFactor))
  }

  const refYearLabelId = useId()
  const targetYearLabelId = useId()
  const curveTypeLabelId = useId()

  const firstDataYear = goalData?.firstOrgDataYear
  const lastDataYear = goalData?.lastOrgDataYear
  const hasDataYears = _.isNumber(firstDataYear) && _.isNumber(lastDataYear)

  const me = useMeQuery() // this is called inside AppDataContext which will handle the error
  const loadingRole = me.loading
  const role = me.data?.me.role
  const userRoleCannotSave =
    !loadingRole && ![Role.Admin, Role.SysAdmin].includes(role!)

  useEffect(() => {
    if (hasDataYears) {
      if (!_.isNumber(goalData?.referenceYear)) {
        setReferenceYear(firstDataYear)
      }
      if (!_.isNumber(goalData?.targetYear)) {
        setTargetYear(lastDataYear + 5)
      }
    }
  }, [
    goalData?.referenceYear,
    goalData?.targetYear,
    firstDataYear,
    lastDataYear,
    setReferenceYear,
    setTargetYear,
    hasDataYears,
  ])

  const saveGoal = useCallback(async () => {
    try {
      setSaveInProgress(true)
      setParameterError(undefined)

      // Sanity check goal data for the linter:
      // The "save" button should be disabled until the goal data loads,
      // as well as if the data fails to load.
      // If the goal context object is defined, all the values in it should also be valid.
      if (!goalData) {
        setParameterError('Något gick fel') // this should never be displayed.
        return
      }

      await setOrgGoal({
        variables: {
          goal: {
            referenceYear: goalData.referenceYear,
            targetYear: goalData.targetYear,
            targetFactor: goalData.targetFactor,
            curveType: goalData.curveType,
            curve: goalData.curve.map((p) => ({
              year: p.x,
              targetFactorRelReferenceYear: p.y,
            })),
          },
        },
        refetchQueries: [{ query: GetOrgGoalDocument }],
      })
    } catch (e) {
      captureException(e)
      // Should probably not display message from server unless we know those are meant to be user-facing,
      // and the error should already be logged at the server side.
      // TODO: Update this to display the server error message after making the server
      //       return user-facing messages.
      setParameterError('Något gick fel, målet har inte sparats.')
    } finally {
      setSaveInProgress(false)
    }
  }, [goalData, setOrgGoal])

  let refYears: number[] = []
  let targetYears: number[] = []
  if (hasDataYears) {
    refYears = _.range(firstDataYear, 1 + lastDataYear)
    // TODO: Discuss and decide on a reasonable max target year.
    const maxTargetYear = nearestMultiple(lastDataYear + 25, 5, 'ceil')
    targetYears = _.range(lastDataYear + 1, 1 + maxTargetYear)
  }

  return (
    <Flex column>
      <Flex
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr', // Both columns get equal size.
          rowGap: gridRowGap,
          columnGap: spacing.medium,
        }}
      >
        <FormControl
          fullWidth
          data-testid='ref-year-selector'
          style={{ display: 'flex', flexDirection: 'column', gridColumn: 1 }}
        >
          <InputLabel id={refYearLabelId}>Basår</InputLabel>
          <SelectLightBorder
            label='Basår' // Note: this text is not displayed, but will alter how the border is drawn.
            labelId={refYearLabelId}
            disabled={loading}
            value={goalData?.referenceYear ?? firstDataYear ?? ''}
            onChange={(event) =>
              setReferenceYear(
                Number.parseInt(event.target.value as string, 10),
              )
            }
          >
            {refYears.map((year) => {
              return (
                <MenuItem key={`ref-${year}`} value={year}>
                  {year.toFixed(0)}
                </MenuItem>
              )
            })}
          </SelectLightBorder>
        </FormControl>
        <FormControl
          fullWidth
          data-testid='target-year-selector'
          style={{ display: 'flex', flexDirection: 'column', gridColumn: 2 }}
        >
          <InputLabel id={targetYearLabelId}>Målår</InputLabel>
          <SelectLightBorder
            label='Målår'
            placeholder='Välj år'
            labelId={targetYearLabelId}
            disabled={loading}
            value={goalData?.targetYear ?? lastDataYear ?? ''}
            onChange={(event) =>
              setTargetYear(Number.parseInt(event.target.value as string, 10))
            }
          >
            {targetYears.map((year) => {
              return (
                <MenuItem key={`target-${year}`} value={year}>
                  {year.toFixed(0)}
                </MenuItem>
              )
            })}
          </SelectLightBorder>
        </FormControl>
        <FormControl
          style={{ display: 'flex', flexDirection: 'column', gridColumn: 1 }}
          data-testid='percent-selector'
        >
          <ReductionPercentInput
            label='Minskning'
            aria-label='Minskning i procent'
            InputProps={{
              endAdornment: <InputAdornment position='end'>%</InputAdornment>,
              // Note: min/max/step affect how the arrow keys change the value,
              //       but by itself does not prevent the user from typing an arbitrary number.
              inputProps: { min: 0, max: 100, step: 1 },
            }}
            InputLabelProps={{ shrink: true }} // Prevent label from "growing" when input is empty.
            type='number'
            variant='outlined'
            disabled={loading}
            error={reductionPercent === ''}
            helperText={
              reductionPercent === '' ? 'En minskning måste anges' : undefined
            }
            onBeforeInput={(event) => {
              // Prevent non-digits in the input:
              // This handler is needed to prevent the user from inputting for example "e" or "-",
              // as the numeric input element considers these to be valid and will display them,
              // but will not cause "onChange" to run.
              //
              // This event handler is provided by React, and the event contains
              // a field called "data" which is equal to the standard InputEvent.data -
              // a string with the input.
              // See:
              //  https://react.dev/reference/react-dom/components/common#inputevent-handler
              //  https://developer.mozilla.org/en-US/docs/Web/API/InputEvent/data
              //  (or the W3C standard) https://w3c.github.io/input-events/#dfn-data
              const inputStr: string | undefined = (event as any).data
              if (inputStr && !inputStr.match(/\d/)) {
                // This prevents the character from being displayed in the input field,
                // "onChange" is not actually be called even if we allow the event, confusingly.
                event.stopPropagation()
                event.preventDefault()
              }
            }}
            onChange={(event) => {
              // Only allow percent as integers [0, 100] (leading zero is ok, and ignored when parsing).
              // Treat empty string and invalud values as an empty field.
              // All other value are ignored (and should be blocked by "onBeforeInput" on the input element).
              const s = event.target.value
              if (s.match(/^(?:[0-9]{1,2}|100)$/i)) {
                const x = Number.parseInt(s, 10)
                setMaybeTargetFactor(1 - x / 100)
              } else if (!s) {
                setMaybeTargetFactor(undefined)
              }
            }}
            value={reductionPercent} // Percent or empty string.
          />
        </FormControl>
        <FormControl
          data-testid='curve-type-selector'
          style={{
            display: 'flex',
            flexDirection: 'column',
            gridColumn: 2,
            // Note on 'minWidth':
            // MUI InputLabel:s use absolute positioning, so the container does not resize if the label is long,
            // and in the case of the curve type selector, the label is long enough that it gets cut off.
            // Setting a min width seems like the simplest solution, under current requirements.
            // Another option would be to override the CSS so the label is position relative, but then other elements would also
            // need adjustment to account for the fact that the label would be given vertical/hotisontal space in the layout.
            minWidth: '14rem',
          }}
        >
          <InputLabel id={curveTypeLabelId} style={{ width: '100%' }}>
            <span>Vilken typ av minskning?</span>
            <TooltipInfo
              style={{ display: 'inline-flex' }}
              info={<CurveTypeTooltipText />}
            />
          </InputLabel>
          <SelectLightBorder
            disabled={loading}
            // NB: This label is not displayed, but affects the "hole" in the components outline,
            //    and should match the space for the text + icon displayed by the label component defined above.
            //    The extra characters at the end are padding for "(i)" icon;
            //    they are arbitrary characters that happen to make the spacing look decent.
            label='Vilken typ av minskning?_(_i_).'
            labelId={curveTypeLabelId}
            value={goalData?.curveType ?? OrgGoalCurveType.S}
            onChange={(event) =>
              setCurveType(event.target.value as OrgGoalCurveType)
            }
          >
            {Object.entries(curveTypeLabels).map(([typeKey, label]) => (
              <MenuItem key={typeKey} value={typeKey}>
                {label}
              </MenuItem>
            ))}
          </SelectLightBorder>
        </FormControl>
        <ButtonLightBorder
          disabled={loading || !hasChanges}
          onClick={resetToDefault}
          style={{
            marginTop: buttonGapMargin,
          }}
        >
          Återställ
        </ButtonLightBorder>
        <ButtonGreen
          style={{
            // Override pointerEvents when the button is disabled, or
            // the tooltip cannot be displayed since hover events would be ignored.
            // Note: this does not make the button clickable.
            pointerEvents: userRoleCannotSave ? 'auto' : undefined,
            marginTop: buttonGapMargin,
          }}
          disabled={
            loading ||
            loadingRole ||
            !!goalError ||
            userRoleCannotSave ||
            reductionPercent === ''
          }
          onClick={saveGoal}
          startIcon={
            loading || saveInProgress ? (
              // A white semi-circle with one quarter being transparent.
              <Loader
                size={15}
                borderSize={4}
                style={{
                  borderColor: 'white white white transparent',
                }}
              />
            ) : undefined
          }
          endIcon={
            userRoleCannotSave ? (
              <TooltipInfo
                info='Du saknar behörighet att spara mål'
                size={15}
                data-testid='no-save-permission-tooltip'
                // Without this styling, the button will become significantly taller
                // as the (i)-icon will inherit a larger font size, and it will
                // also look poorly vertically aligned with the button text
                // (adjusting the font size almost fixes this aligment as well).
                style={{
                  fontSize: theme.typography.button.fontSize,
                  transform: 'translate(0, -1px)', // Margin or padding would make the button taller.
                }}
              />
            ) : undefined
          }
        >
          Spara mål
        </ButtonGreen>
      </Flex>
      {!!parameterError?.length && (
        <Body1Bold
          style={{ marginTop: spacing.medium, color: theme.palette.error.main }}
        >
          {parameterError}
        </Body1Bold>
      )}
    </Flex>
  )
}

function CurveTypeTooltipText(): React.JSX.Element {
  return (
    // Note: Using plain html elements rather than styled `Body1` et. al. from Typography,
    //       to properly inherit font etc. from `TooltipInfo` internals.
    <>
      <p>
        Här kan du specificera hur din organisation avser att minska sina
        utsläpp över tid.
      </p>
      <p>
        Även om en framtida målsättning kanske redan finns, kommer hastigheten
        på utsläppsminskningen att påverka de totala kumulativa utsläppen.
      </p>
      <p>
        Din organisations förmåga att minska utsläppen beror på en rad interna
        och externa faktorer. Med det i åtanke har Svalna tagit fram tre olika
        typer av minskningstakt:
      </p>
      <ul>
        <li>
          <ListHeader>Linjär minskningskurva:</ListHeader>
          <span>Utsläppen minskar med en fast mängd varje år.</span>
        </li>
        <li>
          <ListHeader>S-formad minskningskurva:</ListHeader>
          <span>
            Utsläppen minskar långsamt till en början, för att sedan öka i
            hastighet och slutligen plana ut.
          </span>
        </li>
        <li>
          <ListHeader>Exponentiell minskningskurva:</ListHeader>
          <span>
            Utsläppen minskar med en fast procentandel varje år, vilket innebär
            en större absolut minskning i början som sedan planar ut över tid.
          </span>
        </li>
      </ul>
      <p>
        Den linjära och S-formade minskningskurvan resulterar i samma totala
        kumulativa utsläpp, medan den exponentiella kurvan leder till lägre
        kumulativa utsläpp.
      </p>
    </>
  )
}
