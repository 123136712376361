import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { FlexingBox } from '../../components/Flex'
import { spacing } from '../../theme'
import { H5Bold, H6 } from '../../components/Typography'
import { ButtonGreen } from '../../components/Buttons'
import { Role, useMeQuery } from '../../graphql/generated'

const Container = styled(FlexingBox)`
  overflow: visible;
  margin-top: ${spacing.large};
  margin-right: ${spacing.large};
  max-width: 680px;
  padding: ${spacing.xl};
  gap: ${spacing.xxxl};
  align-items: center;
  min-height: auto;
`

export function NoProfileView(): React.JSX.Element {
  const history = useHistory()
  const { data: meData } = useMeQuery() // this is called inside AppDataContext which will handle the error

  return (
    <Container>
      <H5Bold data-testid='no-profile-title'>
        Här kommer det visas grafer över ert klimatavtryck!
      </H5Bold>
      {[Role.Admin, Role.SysAdmin].includes(meData?.me.role ?? Role.User) ? (
        <>
          <H6 data-testid='working'>
            Men först måste du fylla i din organisationsprofil.
          </H6>
          <ButtonGreen
            onClick={() => {
              history.push('/profile')
            }}
          >
            Gå till organisationsprofil
          </ButtonGreen>
        </>
      ) : (
        <H6>Så snart en administratör har skapat organisationsprofilen</H6>
      )}
    </Container>
  )
}
