import React from 'react'
import styled from 'styled-components'
import Svalna from '../@types'
import { Role, useMeQuery } from '../graphql/generated'
import { JobSnack } from './jobs/JobSnack'
import {
  colors,
  contentPadding,
  headerHeight,
  headerPadding,
  navbarWidth,
} from '../theme'
import { Navigation } from './sidebar/Navigation'
import { Header as HeaderContent } from './header/Header'
import { tourNavbarClassName } from './tour/tourConstants'

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: ${navbarWidth};
`

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Header = styled.header`
  display: flex;
  flex-direction: column;
  height: ${headerHeight};
  min-width: fit-content;
  position: sticky;
  top: 0;
  background-color: ${colors.backgroundWhite};
  /* 
    Pad left/right so the inner header wrapper can have a 100 % width,
    which is useful for displaying the bottom border of the header
    while still having padding on each side.
  */
  padding: ${headerPadding};
  /**
    Z-index makes sticky scrolled content pass under.
    Note that MUI also uses Z-index for some things
   */
  z-index: 2;
`

const PageNavComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: ${navbarWidth};
  /* 
    Nav bar should allow Y-scroll if needed, but not X-scroll.
    Note: 
    The scroll breaks after the tour has been displayed, and must be
    dynamically fixed in TourContext when the tour completes.
    See related https://github.com/gilbarbara/react-joyride/issues/563 [2024-10]
    but note that the "disableScrollParentFix" suggested there does not work for us,
    as this breaks the highlighting for those steps.
  */
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${colors.granit};
  /**
    Page content goes under if scrolling in extreme zoom.
    Note that MUI also uses Z-index for some things
  */
  z-index: 3;
`

const MainContainer = styled.main`
  display: flex;
  width: 100%;
  min-width: fit-content;
  justify-content: space-evenly;
  padding: ${contentPadding};
`

interface Props extends Svalna.PropWithChildren {
  headerContent?: React.JSX.Element
  title: string
  loading?: boolean
  hideHeader?: boolean
  hideJobButton?: boolean
}

export function Layout({
  children,
  headerContent,
  title,
  loading,
  hideHeader,
  hideJobButton,
}: Props): React.JSX.Element {
  const { data: meData } = useMeQuery() // this is called inside AppDataContext which will handle the error
  const user = meData?.me

  return (
    <PageWrapper>
      {(user?.role === Role.Admin || user?.role === Role.SysAdmin) && (
        <JobSnack />
      )}
      <PageNavComponent
        // Set a class name for identification of this element,
        // to undo the Joyride "overflow: initial".
        className={tourNavbarClassName}
      >
        <Navigation />
      </PageNavComponent>
      <RightSide>
        {!hideHeader && (
          <Header>
            <HeaderContent
              title={title}
              hideJobButton={hideJobButton}
              loading={loading}
            >
              {headerContent}
            </HeaderContent>
          </Header>
        )}
        <MainContainer>{children}</MainContainer>
      </RightSide>
    </PageWrapper>
  )
}
