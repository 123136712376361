import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import {
  DataGrid,
  GridColDef,
  GridRowModes,
  GridRowsProp,
  GridToolbar,
} from '@mui/x-data-grid'
import RsvpIcon from '@mui/icons-material/Rsvp'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import {
  AlertProps,
  Dialog,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ApolloError } from '@apollo/client'
import { captureException } from '@sentry/browser'
import { Section, SectionId, SysadminSectionId } from '../../emission/Section'
import {
  Flex,
  FlexingBox,
  FlexingBoxContent,
  HeadRow,
} from '../../../components/Flex'
import { TitleWithSub } from '../../../components/TitleWithSub'
import {
  GetRegistrationsDocument,
  OrgRegistration,
  useDisapproveOrgRegistrationMutation,
  useGetRegistrationsQuery,
  useGetSubscriptionPlansQuery,
  useRegisterOrganisationMutation,
  useUpdateOrgRegistrationApprovalCodeMutation,
} from '../../../graphql/generated'
import { ButtonGreen } from '../../../components/Buttons'
import { toErrorMap } from '../../../utils/toErrorMap'
import { colors, spacing } from '../../../theme'
import { SnackAlert } from '../../../components/SnackAlert'
import { Body1, Body1Bold } from '../../../components/Typography'
import { formatISO } from '../../../utils/date'
import { QuoteForm } from './QuoteForm'
import { GridActionItem } from '../../../components/GridActionItem'
import { ErrorSnackBarContext } from '../../../context/ErrorSnackBarContex'
import { Loader } from '../../../components/Loader'

interface Props {
  active: {
    scroll: boolean
    sectionId: SectionId
  }
  handleSectionVisibilityChange: (
    sectionId: SectionId,
    isVisible: boolean,
  ) => void
}

export function Registrations({
  active,
  handleSectionVisibilityChange,
}: Props): React.JSX.Element {
  const {
    data: registrationData,
    loading: loadingData,
    error,
  } = useGetRegistrationsQuery()

  const { setError } = useContext(ErrorSnackBarContext)
  useEffect(() => {
    if (error) {
      captureException(error)
      setError()
    }
  }, [error, setError])

  const registrations = registrationData?.getRegistrations
  const [registerOrganisation] = useRegisterOrganisationMutation({
    refetchQueries: [{ query: GetRegistrationsDocument }],
  })
  const [disapproveOrgRegistration] = useDisapproveOrgRegistrationMutation({
    refetchQueries: [{ query: GetRegistrationsDocument }],
  })

  const [regApproval, setRegApproval] = React.useState<
    OrgRegistration | undefined
  >(undefined)
  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)
  const [rows, setRows] = React.useState<GridRowsProp<OrgRegistration>>([])
  const [updateOrgRegistrationApprovalCode] =
    useUpdateOrgRegistrationApprovalCodeMutation()

  useEffect(() => {
    setRows(registrations ?? [])
  }, [registrations])

  const setEmailSent = () => {
    setSnackbar({
      children: (
        <Body1 data-testid='link-sent'>
          Användaren har lagts till och ett mail har skickats till dennes
          inkorg.
        </Body1>
      ),
      severity: 'success',
    })
  }

  const handleDisapproveRegistration = useCallback(
    async (org: OrgRegistration) => {
      setSnackbar(null)

      try {
        const result = await disapproveOrgRegistration({
          variables: {
            id: org.id,
          },
        })
        if (result.data) {
          setSnackbar({
            children: (
              <Body1 data-testid='unapprove-conf'>
                The registration has been unapproved.
              </Body1>
            ),
            severity: 'success',
          })
          return
        }
        // we should never reach this point as if the data were not set an error should have been thrown
        throw new Error('No data returned')
      } catch (err) {
        captureException(err)
        setSnackbar({
          children: `Something went wrong`,
          severity: 'error',
        })
      }
    },
    [disapproveOrgRegistration],
  )

  const handleUpdateRegistrationCode = useCallback(
    async (reg: OrgRegistration) => {
      try {
        const result = await updateOrgRegistrationApprovalCode({
          variables: {
            id: reg.id,
          },
        })
        if (result.data) {
          setEmailSent()
          return
        }
        // we should never reach this point as if the data were not set an error should have been thrown
        throw new Error('No data returned')
      } catch (err) {
        captureException(err)
        setSnackbar({
          children: `Error while updating the registration code: ${JSON.stringify(
            err,
          )}`,
          severity: 'error',
        })
      }
    },
    [updateOrgRegistrationApprovalCode],
  )

  const columns: GridColDef<OrgRegistration>[] = useMemo(
    () => [
      { field: 'orgNumber', headerName: 'Organisation number', flex: 2 },
      { field: 'name', headerName: 'name', flex: 3 },
      { field: 'email', headerName: 'email', flex: 3 },
      { field: 'subscriptionPlanName', headerName: 'plan', flex: 2 },
      { field: 'bookingSystem', headerName: 'booking System', flex: 2 },
      { field: 'nbEmployees', headerName: 'nb employees', flex: 1 },
      { field: 'industrialSectorId', headerName: 'SNI', flex: 2 },
      {
        field: 'createdDate',
        headerName: 'creation',
        flex: 2,
        valueFormatter: (value?: Date) => {
          if (value == null) {
            return ''
          }
          return formatISO(value)
        },
      },
      {
        field: 'approved',
        headerName: 'approved',
        flex: 1,
        type: 'actions',
        getActions: ({ id, row }) => {
          const actions = []

          if (row.approved) {
            actions.push(
              <GridActionItem
                key={`approval-${id}`}
                icon={<CheckBoxIcon />}
                label='disapprove'
                className='textPrimary'
                onClick={() => handleDisapproveRegistration(row)}
                color='inherit'
                data-testid={`approval-${id}`}
                rowId={id}
                modeToShowItemIn={GridRowModes.View}
              />,
            )
          } else {
            actions.push(
              <GridActionItem
                key={`approval-${id}`}
                icon={<CheckBoxOutlineBlankIcon />}
                label='approve'
                className='textPrimary'
                onClick={() => setRegApproval(row)}
                color='inherit'
                data-testid={`approval-${id}`}
                rowId={id}
                modeToShowItemIn={GridRowModes.View}
              />,
            )
          }

          return actions
        },
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        flex: 1,
        cellClassName: 'actions',
        getActions: ({ id, row }) => {
          const actions = []

          if (row?.approved) {
            actions.push(
              <GridActionItem
                key={`rsvp-${id}`}
                icon={
                  <Tooltip title='Uppdatera registreringslänk'>
                    <RsvpIcon />
                  </Tooltip>
                }
                label='invite'
                className='textPrimary'
                onClick={() => handleUpdateRegistrationCode(row)}
                color='inherit'
                data-testid={`rsvp-button-${id}`}
                modeToShowItemIn={GridRowModes.View}
                rowId={id}
              />,
            )
          }

          return actions
        },
      },
    ],
    [handleDisapproveRegistration, handleUpdateRegistrationCode],
  )

  const columnVisibilityModel = {
    orgNumber: false,
    industrialSectorId: false,
  }

  const { data: subscriptionPlanData, loading: subscriptionPlanLoading } =
    useGetSubscriptionPlansQuery()

  const NOTSET = 'NotSet'

  const registerSchema = Yup.object().shape({
    orgNumber: Yup.string()
      .matches(
        /^[0-9]{6}-[0-9]{4}$/,
        'Det är inte ett giltigt organisationsnummer',
      )
      .required('Nödvändig'),
    email: Yup.string().email('Ogiltig e-postadress').required('Nödvändig'),
    subscriptionPlanName: Yup.string()
      .notOneOf([NOTSET], 'Nödvändig')
      .oneOf(
        subscriptionPlanData?.getSubscriptionPlans.map((plan) => plan.name) ??
          [],
        'Ogiltig prisplan',
      )
      .required('Nödvändig'),
  })

  const registerOrgFormik = useFormik({
    initialValues: {
      orgNumber: '',
      email: '',
      acceptPolicies: true,
      bookingSystem: '',
      subscriptionPlanName: NOTSET,
    },
    validationSchema: registerSchema,
    onSubmit: async (values, { setErrors }) => {
      try {
        const result = await registerOrganisation({ variables: values })
        if (result.data) {
          if (result.data?.registerOrganisation.errors) {
            setErrors(toErrorMap(result.data.registerOrganisation.errors))
          }
          return
        }
        // we should never reach this point as if the data were not set an error should have been thrown
        throw new Error('No data returned')
      } catch (err) {
        captureException(err)
        if (err instanceof ApolloError) {
          setErrors({ orgNumber: err.message })
        } else {
          setErrors({ orgNumber: 'something went wrong' })
        }
      }
    },
  })

  const handleCloseSnackbar = () => setSnackbar(null)

  return (
    <Section
      sectionId={SysadminSectionId.organisations}
      active={active}
      onVisibilityChange={handleSectionVisibilityChange}
      key='registrations-section'
    >
      <FlexingBox>
        <HeadRow>
          <TitleWithSub
            title='Registrations'
            sub=''
            infoDescription='list of registrations'
          />
        </HeadRow>
        <div style={{ padding: spacing.large }}>
          <DataGrid
            rows={rows ?? []}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
              columns: {
                columnVisibilityModel,
              },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            getRowId={(row) => row.id}
            editMode='row'
            disableVirtualization
            slots={{
              toolbar: GridToolbar,
            }}
          />

          <form
            onSubmit={registerOrgFormik.handleSubmit}
            style={{ paddingTop: spacing.large }}
          >
            <Flex style={{ gap: spacing.medium }}>
              <TextField
                type='text'
                name='orgNumber'
                label='Organisationsnummer'
                onChange={registerOrgFormik.handleChange}
                onBlur={registerOrgFormik.handleBlur}
                value={registerOrgFormik.values.orgNumber}
                variant='standard'
                placeholder='xxxxxx-xxxx'
                slotProps={{ inputLabel: { shrink: true } }}
                error={
                  !!registerOrgFormik.touched.orgNumber &&
                  !!registerOrgFormik.errors.orgNumber
                }
                helperText={
                  registerOrgFormik.touched.orgNumber &&
                  registerOrgFormik.errors.orgNumber
                }
                required
                style={{ flex: 1 }}
              />
              <TextField
                type='text'
                name='email'
                label='Företagsmailadress'
                onChange={registerOrgFormik.handleChange}
                onBlur={registerOrgFormik.handleBlur}
                value={registerOrgFormik.values.email}
                variant='standard'
                placeholder='namn@domän.se'
                slotProps={{ inputLabel: { shrink: true } }}
                error={
                  !!registerOrgFormik.touched.email &&
                  !!registerOrgFormik.errors.email
                }
                helperText={
                  registerOrgFormik.touched.email &&
                  registerOrgFormik.errors.email
                }
                required
                style={{ flex: 1 }}
              />
              <FormControl fullWidth variant='standard' style={{ flex: 1 }}>
                <InputLabel
                  id='subscription-plan-label'
                  required
                  error={
                    !!registerOrgFormik.touched.subscriptionPlanName &&
                    !!registerOrgFormik.errors.subscriptionPlanName
                  }
                >
                  Prisplan
                </InputLabel>
                <Select
                  name='subscriptionPlanName'
                  labelId='subscription-plan-label'
                  id='subscription-plan-select'
                  label='Prisplan'
                  onChange={registerOrgFormik.handleChange}
                  value={registerOrgFormik.values.subscriptionPlanName}
                  displayEmpty
                  renderValue={
                    registerOrgFormik.values.subscriptionPlanName !== NOTSET
                      ? undefined
                      : () => 'Välj'
                  }
                  error={
                    !!registerOrgFormik.touched.subscriptionPlanName &&
                    !!registerOrgFormik.errors.subscriptionPlanName
                  }
                >
                  {subscriptionPlanLoading && (
                    <MenuItem value={NOTSET} key={NOTSET}>
                      <Loader size={20} borderSize={4} />
                      <span>&nbsp;Ladar</span>
                    </MenuItem>
                  )}
                  {subscriptionPlanData?.getSubscriptionPlans.map((plan) => {
                    return (
                      <MenuItem value={plan.name} key={plan.name}>
                        {plan.name}
                      </MenuItem>
                    )
                  })}
                </Select>
                {registerOrgFormik.touched.subscriptionPlanName &&
                  registerOrgFormik.errors.subscriptionPlanName && (
                    <FormHelperText style={{ color: colors.red }}>
                      {registerOrgFormik.errors.subscriptionPlanName}
                    </FormHelperText>
                  )}
              </FormControl>
              <TextField
                type='text'
                name='bookingSystem'
                label='bokningssystem'
                onChange={registerOrgFormik.handleChange}
                onBlur={registerOrgFormik.handleBlur}
                value={registerOrgFormik.values.bookingSystem}
                variant='standard'
                placeholder='fortnox'
                slotProps={{ inputLabel: { shrink: true } }}
                error={
                  !!registerOrgFormik.touched.bookingSystem &&
                  !!registerOrgFormik.errors.bookingSystem
                }
                helperText={
                  registerOrgFormik.touched.bookingSystem &&
                  registerOrgFormik.errors.bookingSystem
                }
                style={{ flex: 1 }}
              />
              <ButtonGreen
                type='submit'
                disabled={loadingData || !registerOrgFormik.isValid}
                style={{ flex: 1 }}
              >
                Add registration
              </ButtonGreen>
            </Flex>
          </form>
        </div>
      </FlexingBox>
      <SnackAlert
        open={!!snackbar}
        onClose={handleCloseSnackbar}
        {...snackbar}
      />
      <Dialog open={!!regApproval} onClose={() => setRegApproval(undefined)}>
        <FlexingBoxContent>
          <Body1Bold data-testid='payment-info-dialog'>
            Registration approval for {regApproval?.name}
          </Body1Bold>
          <QuoteForm
            close={() => setRegApproval(undefined)}
            setSnackbar={setSnackbar}
            orgId={
              regApproval?.id ??
              '' /* the ?? should never happen as then the dialog would not be opened */
            }
            selectedPlan={
              regApproval?.subscriptionPlanName ??
              '' /* the ?? should never happen as then the dialog would not be opened */
            }
          />
        </FlexingBoxContent>
      </Dialog>
    </Section>
  )
}
