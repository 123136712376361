import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { AlertProps, MenuItem, TextField } from '@mui/material'
import { captureException } from '@sentry/browser'
import { ApolloError } from '@apollo/client'
import styled from 'styled-components'
import * as Yup from 'yup'
import { Flex } from '../../components/Flex'
import {
  OrganisationsQuery,
  Role,
  UsersDocument,
  useAddUserMutation,
  useOrganisationsLazyQuery,
} from '../../graphql/generated'
import { ButtonGreen } from '../../components/Buttons'
import { Body1Bold } from '../../components/Typography'
import { handleValidationError } from '../../utils/validationError'
import { Loader } from '../../components/Loader'
import { toErrorMap } from '../../utils/toErrorMap'
import { spacing } from '../../theme'
import { RoleNameMap } from '../../utils/roles'

const TextFieldStyled = styled(TextField)`
  width: 100%;
`

interface Props {
  roleOptions: RoleNameMap[]
  setError: (error: string) => void
  sysAdmin?: boolean
  onSuccess?: () => void
  defaultRole?: Role
  buttonText?: string
  setSnackbar: React.Dispatch<
    React.SetStateAction<Pick<AlertProps, 'children' | 'severity'> | null>
  >
}

export function AddUserForm({
  roleOptions,
  setError,
  sysAdmin,
  onSuccess,
  defaultRole,
  buttonText,
  setSnackbar,
}: Props): React.JSX.Element {
  const [addUser] = useAddUserMutation({
    refetchQueries: [
      { query: UsersDocument, variables: { allOrgs: sysAdmin } },
    ],
  })
  const [getOrganisations] = useOrganisationsLazyQuery()
  const [orgData, setOrgData] = useState<OrganisationsQuery | undefined>(
    undefined,
  )

  useEffect(() => {
    if (sysAdmin) {
      getOrganisations().then((orgs) => {
        if (orgs.error) {
          captureException(orgs.error)
          setError('Något gick fel när organisationerna skulle hämtas')
          return
        }
        setOrgData(orgs.data)
      })
    }
  }, [getOrganisations, setError, sysAdmin])

  const userSchema = Yup.object().shape({
    firstName: Yup.string().max(255, 'För långt').required('Nödvändig'),
    lastName: Yup.string().max(255, 'För långt').required('Nödvändig'),
    email: Yup.string().email('Ogiltig e-postadress').required('Nödvändig'),
    role: Yup.mixed<Role>().oneOf(Object.values(Role)),
  })

  const getDefaultRole = () => {
    if (
      defaultRole &&
      roleOptions.find((option) => option.value === defaultRole)
    ) {
      return defaultRole
    }
    if (roleOptions.find((option) => option.value === Role.User)) {
      return Role.User
    }
    return roleOptions[0].value
  }

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      role: getDefaultRole(),
      organisationId: '',
    },
    validationSchema: () => userSchema,
    onSubmit: async (values, { setErrors, resetForm }) => {
      try {
        const response = await addUser({ variables: { newUser: values } })
        if (response.data?.addUser.errors) {
          captureException(response.data.addUser.errors)
          const errorMap = toErrorMap(response.data?.addUser.errors)
          setErrors(errorMap)
          if (errorMap.all) {
            setError(errorMap.all)
          }
        } else if (response.data?.addUser.email) {
          setSnackbar({
            severity: 'success',
            children:
              'Användaren har lagts till och ett mail har skickats till dennes inkorg. ',
          })
          resetForm()
          if (onSuccess) {
            onSuccess()
          }
        } else {
          captureException(
            'Did neither receive an error nor a response when adding a user',
          )
          setError('Något gick fel')
        }
      } catch (err) {
        captureException(err)
        let hasSetError = false
        if (err instanceof ApolloError) {
          if (err.graphQLErrors) {
            err.graphQLErrors.forEach((gqlError) => {
              if (gqlError.extensions?.code === 'BAD_USER_INPUT') {
                const result = handleValidationError(
                  setErrors,
                  gqlError.extensions.validationErrors,
                )
                if (result) {
                  hasSetError = true
                }
              }
            })
          }
        }

        if (!hasSetError) {
          setError('Något gick fel när användaren lades till')
        }
      }
    },
  })

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
      <Body1Bold>Fyll i uppgifter för ny användare</Body1Bold>
      <Flex column stretchWidth style={{ gap: spacing.large }}>
        {sysAdmin && orgData?.organisations && (
          <Flex stretchWidth style={{ gap: spacing.xl }}>
            <TextFieldStyled
              select
              name='organisationId'
              label='Organisation'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.organisationId}
              variant='standard'
              error={
                !!formik.touched.organisationId &&
                !!formik.errors.organisationId
              }
              required
            >
              {orgData?.organisations.map((org) => {
                return (
                  <MenuItem value={org.id} key={org.id}>
                    {org.name} ({org.id})
                  </MenuItem>
                )
              })}
            </TextFieldStyled>
          </Flex>
        )}
        <Flex stretchWidth style={{ gap: spacing.xl }}>
          <TextFieldStyled
            type='text'
            name='firstName'
            label='Förnamn'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            variant='standard'
            error={!!formik.touched.firstName && !!formik.errors.firstName}
            helperText={formik.touched.firstName && formik.errors.firstName}
            required
          />
          <TextFieldStyled
            type='text'
            name='lastName'
            label='Efternamn'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            variant='standard'
            error={!!formik.touched.lastName && !!formik.errors.lastName}
            helperText={formik.touched.lastName && formik.errors.lastName}
            required
          />
        </Flex>

        <Flex stretchWidth style={{ gap: spacing.xl }}>
          <TextFieldStyled
            type='text'
            name='email'
            label='Mailadress'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            variant='standard'
            error={!!formik.touched.email && !!formik.errors.email}
            helperText={formik.touched.email && formik.errors.email}
            required
          />

          <TextFieldStyled
            select
            name='role'
            label='Roll'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.role}
            variant='standard'
            error={!!formik.touched.role && !!formik.errors.role}
            required
          >
            {roleOptions.map((role) => {
              return (
                <MenuItem value={role.value} key={role.value}>
                  {role.label}
                </MenuItem>
              )
            })}
          </TextFieldStyled>
        </Flex>

        <ButtonGreen
          type='submit'
          disabled={formik.isSubmitting}
          endIcon={
            formik.isSubmitting ? (
              <Loader size={15} borderSize={4} reversed />
            ) : undefined
          }
          data-testid='submit'
        >
          {buttonText ?? 'Lägg till'}
        </ButtonGreen>
      </Flex>
    </form>
  )
}
