import { Role, User } from '../graphql/generated'

export interface RoleNameMap {
  value: Role
  label: string
}

const getRoleName = (role: Role): string => {
  switch (role) {
    case Role.Accountant:
      return 'Ekonomiadministratör'
    case Role.Admin:
      return 'Admin'
    case Role.User:
      return 'Användare'
    case Role.SysAdmin:
      return 'Sysadmin'
    default:
      throw new Error('unknown role')
  }
}

export const getAccountingRolesMap = (): RoleNameMap[] => {
  const roleOptions = Object.entries(Role)
    .filter(([, role]) => {
      // ontly keep admin and accountant roles here
      return [Role.Admin, Role.Accountant].includes(role)
    })
    .map(([, role]) => {
      return {
        value: role,
        label: getRoleName(role),
      }
    })

  return roleOptions
}

export const getAllRolesMap = (user?: Pick<User, 'role'>): RoleNameMap[] => {
  const roleOptions = Object.entries(Role)
    .filter(([, role]) => {
      if (user?.role === Role.SysAdmin) {
        return true
      }
      return role !== Role.SysAdmin
    })
    .map(([, role]) => {
      return {
        value: role,
        label: getRoleName(role),
      }
    })

  return roleOptions
}
