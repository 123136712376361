import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import {
  AlertProps,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  TextField,
} from '@mui/material'
import * as Yup from 'yup'
import { captureException } from '@sentry/browser'
import { ApolloError } from '@apollo/client'
import _ from 'lodash'
import {
  PaymentInformation,
  useGetPaymentInfoQuery,
  useUpdatePaymentInfoMutation,
} from '../../graphql/generated'
import { toErrorMap } from '../../utils/toErrorMap'
import { handleValidationError } from '../../utils/validationError'
import { Flex, InnerBoxForm, InnerInnerBox } from '../../components/Flex'
import { Loader } from '../../components/Loader'
import { Body1 } from '../../components/Typography'
import { spacing } from '../../theme'
import { ButtonGreen } from '../../components/Buttons'

export function UpdatePaymentInfoForm({
  onSuccess,
  setSnackbar,
  hidePolicyBox,
  paymentInfo,
}: {
  onSuccess: () => void
  setSnackbar: React.Dispatch<
    React.SetStateAction<Pick<AlertProps, 'children' | 'severity'> | null>
  >
  hidePolicyBox?: boolean
  paymentInfo?: PaymentInformation
}): React.JSX.Element {
  const [initialLoading, setInitialLoading] = useState(!paymentInfo)

  const {
    data,
    loading: paymentInfoLoading,
    error: paymentInfoError,
  } = useGetPaymentInfoQuery({ skip: !!paymentInfo })

  useEffect(() => {
    if (paymentInfoError) {
      setSnackbar({
        children: 'Något gick fel, ladda om sidan och försök igen',
        severity: 'error',
      })
    }
  }, [paymentInfoError, setSnackbar])

  const [updatePaymentInfo] = useUpdatePaymentInfoMutation()

  const schema = Yup.object().shape({
    companyName: Yup.string()
      .min(2, 'För kort!')
      .max(50, 'För lång text, max 50 tecken!')
      .required('Nödvändig'),
    address: Yup.string()
      .min(2, 'För kort!')
      .max(50, 'För lång text, max 50 tecken!')
      .required('Nödvändig'),
    postNumber: Yup.string().matches(
      /^[0-9]{5}$/,
      'Det är inte ett giltigt postnummer',
    ),
    city: Yup.string()
      .min(2, 'För kort!')
      .max(50, 'För lång text, max 50 tecken!')
      .required('Nödvändig'),
    contactName: Yup.string()
      .min(2, 'För kort!')
      .max(50, 'För lång text, max 50 tecken!')
      .required('Nödvändig'),
    contactEmail: Yup.string()
      .email('Ogiltig e-postadress')
      .required('Nödvändig'),
    acceptPolicies: Yup.boolean()
      .isTrue('Du måste acceptera villkoren')
      .required('Du måste acceptera villkoren'),
  })

  const {
    handleSubmit,
    isSubmitting,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      orgId: paymentInfo?.id ?? '',
      companyName: paymentInfo?.companyName ?? '',
      address: paymentInfo?.address ?? '',
      postNumber: paymentInfo?.postNumber ?? '',
      city: paymentInfo?.city ?? '',
      contactName: paymentInfo?.contactName ?? '',
      contactEmail: paymentInfo?.contactEmail ?? '',
      acceptPolicies: paymentInfo?.acceptPolicies ?? false,
    },
    onSubmit: async (variables, { setErrors }): Promise<boolean> => {
      try {
        const result = await updatePaymentInfo({
          variables: { paymentInfo: variables },
        })

        if (result.data?.updatePaymentInfo.errors) {
          setErrors(toErrorMap(result.data.updatePaymentInfo.errors))
          return false
        } else if (result.data?.updatePaymentInfo.paymentInfo) {
          onSuccess()
          return true
        }

        // we should never reach this point as if the data were not set an error should have been thrown
        // and if the data were set we should have gone in one of the if branches above
        throw new Error('No data returned')
      } catch (err) {
        captureException(err)
        if (err instanceof ApolloError) {
          if (err.graphQLErrors) {
            let handledError = false
            err.graphQLErrors.forEach((gqlError) => {
              if (gqlError.extensions?.code === 'BAD_USER_INPUT') {
                const result = handleValidationError(
                  setErrors,
                  gqlError.extensions.validationErrors,
                )
                if (result) {
                  handledError = true
                }
              }
            })
            if (handledError) {
              return false
            }
          }
        }
      }
      setSnackbar({
        children: 'Ett fel uppstod när värdena skickades, försök igen',
        severity: 'error',
      })
      return false
    },
    validationSchema: schema,
  })

  useEffect(() => {
    if (data?.getPaymentInfo) {
      setValues({
        ..._.omit(data?.getPaymentInfo, 'id', '__typename'),
        orgId: '',
      })
    }
    if (!paymentInfoLoading) {
      // this way the initial loading is set to false the first time we get the payment
      // and remain false afterward, even if the payment info are reloaded
      setInitialLoading(false)
    }
  }, [data?.getPaymentInfo, paymentInfoLoading, setValues])

  if (initialLoading) {
    return (
      <Flex stretchWidth spaceAround>
        <Loader size={80} borderSize={20} data-testid='loader' />
      </Flex>
    )
  }

  return (
    <InnerInnerBox>
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <InnerInnerBox>
          <InnerBoxForm>
            <Body1>Företagets uppgifter:</Body1>
            <TextField
              id='companyName'
              label='Företagsnamn'
              variant='standard'
              style={{ width: '100%' }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.companyName}
              error={touched.companyName && !!errors.companyName}
              helperText={touched.companyName && errors.companyName}
              required
              placeholder='Företagsnamn'
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
            <TextField
              id='address'
              label='Adress'
              variant='standard'
              style={{ width: '100%' }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address}
              error={touched.address && !!errors.address}
              helperText={touched.address && errors.address}
              required
              placeholder='Gatunamn & husnumer'
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
            <Flex stretchWidth style={{ gap: spacing.medium }}>
              <TextField
                id='postNumber'
                label='Postnummer'
                variant='standard'
                style={{ width: '100%' }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.postNumber}
                error={touched.postNumber && !!errors.postNumber}
                helperText={touched.postNumber && errors.postNumber}
                required
                placeholder='xxxxx'
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
              />
              <TextField
                id='city'
                label='Ort'
                variant='standard'
                style={{ width: '100%' }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.city}
                error={touched.city && !!errors.city}
                helperText={touched.city && errors.city}
                required
                placeholder='Ort'
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
              />
            </Flex>
          </InnerBoxForm>
          <InnerBoxForm>
            <Body1>Kontaktpersonens uppgifter:</Body1>
            <TextField
              id='contactName'
              label='Namn'
              variant='standard'
              style={{ width: '100%' }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.contactName}
              error={touched.contactName && !!errors.contactName}
              helperText={touched.contactName && errors.contactName}
              required
              placeholder='Förnamn & efternamn'
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
            <TextField
              id='contactEmail'
              label='E-postadress'
              variant='standard'
              style={{ width: '100%' }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.contactEmail}
              error={touched.contactEmail && !!errors.contactEmail}
              helperText={touched.contactEmail && errors.contactEmail}
              required
              placeholder='adress@mail.com'
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
          </InnerBoxForm>
          {!hidePolicyBox && (
            <FormControl
              required
              error={touched.acceptPolicies && !!errors.acceptPolicies}
            >
              <FormControlLabel
                name='acceptPolicies'
                required
                onChange={handleChange}
                control={
                  <Checkbox
                    checked={values.acceptPolicies}
                    data-testid='checkbox'
                  />
                }
                label={
                  <>
                    <span>
                      Jag godkänner betalning via faktura och är medveten om
                    </span>{' '}
                    <a href='/payment-terms' target='_blank'>
                      betalningsvillkoren
                    </a>
                    <span>.</span>
                  </>
                }
                data-testid='acceptPolicies'
              />
              {touched.acceptPolicies && (
                <FormHelperText>{errors.acceptPolicies}</FormHelperText>
              )}
            </FormControl>
          )}

          <ButtonGreen
            type='submit'
            disabled={isSubmitting}
            data-testid='submit'
          >
            Fortsätt
          </ButtonGreen>
        </InnerInnerBox>
      </form>
    </InnerInnerBox>
  )
}
