import React, { useCallback, useContext, useEffect, useState } from 'react'
import { captureException } from '@sentry/browser'
import { Layout } from '../../components/Layout'
import { SideColumn } from '../../components/TableOfContent'
import { LayoutContent } from '../../components/LayoutContent'
import { useOrganisationsQuery } from '../../graphql/generated'
import { TableOfContentButton } from '../../components/Buttons'
import { SectionId, SysadminSectionId } from '../emission/Section'
import { Organisations } from './sysadminSections/Organisations'
import { SSO } from './sysadminSections/SSO'
import { Users } from './sysadminSections/Users'
import { Registrations } from './sysadminSections/Registrations'
import { ErrorSnackBarContext } from '../../context/ErrorSnackBarContex'

type SectionDef = {
  text: string
  sectionId: SysadminSectionId
  section: React.JSX.Element
}

export function Sysadmin(): React.JSX.Element {
  const [active, setActiveSessionId] = useState<{
    scroll: boolean
    sectionId: SectionId
  }>({ scroll: false, sectionId: SysadminSectionId.users })

  const { data: orgData, error } = useOrganisationsQuery()

  const { setError } = useContext(ErrorSnackBarContext)
  useEffect(() => {
    if (error) {
      captureException(error)
      setError()
    }
  }, [error, setError])

  const handleSectionVisibilityChange = useCallback(
    (sectionId: SectionId, isVisible: boolean) => {
      if (isVisible) {
        setActiveSessionId({ scroll: false, sectionId })
      }
    },
    [setActiveSessionId],
  )

  const tableOfContent: Array<SectionDef> = []

  tableOfContent.push({
    text: 'Registrations',
    sectionId: SysadminSectionId.registrations,
    section: (
      <Registrations
        active={active}
        handleSectionVisibilityChange={handleSectionVisibilityChange}
        key='registration-box'
      />
    ),
  })

  tableOfContent.push({
    text: 'Organisations',
    sectionId: SysadminSectionId.organisations,
    section: (
      <Organisations
        active={active}
        handleSectionVisibilityChange={handleSectionVisibilityChange}
        orgData={orgData}
        key='organisation-box'
      />
    ),
  })

  tableOfContent.push({
    text: 'Users',
    sectionId: SysadminSectionId.users,
    section: (
      <Users
        active={active}
        handleSectionVisibilityChange={handleSectionVisibilityChange}
        key='user-box'
      />
    ),
  })

  tableOfContent.push({
    text: 'SSO',
    sectionId: SysadminSectionId.sso,
    section: (
      <SSO
        active={active}
        handleSectionVisibilityChange={handleSectionVisibilityChange}
        orgData={orgData}
        key='sso-box'
      />
    ),
  })

  return (
    <Layout title='Sysadmin'>
      <SideColumn>
        {tableOfContent.map((c) => {
          if (c) {
            return (
              <TableOfContentButton
                isActive={c.sectionId === active.sectionId}
                onClick={() =>
                  setActiveSessionId({ sectionId: c.sectionId, scroll: true })
                }
                key={`${c.sectionId}-button`}
              >
                {c.text}
              </TableOfContentButton>
            )
          } else {
            return undefined
          }
        })}
      </SideColumn>

      {/* Setting the max width here is a bit of a hack. 
          We need it because we use datagrid with columns with a min width in pixel.
          This can push the width of the table to be more than 100% of its parent width which result
          in the parent growing until it reach the max size set in layoutContent, which is 1300px.
          If we start using datagrid with minimum width columns in other place we may want to change the 
          way we define the maxWidth in LayoutContent, but this will also imply ensuring that the computation of 
          minOverlayWidth in EmissionTourContext is still corect. */}
      <LayoutContent style={{ maxWidth: 'calc(min(1300px,60vw))' }}>
        {tableOfContent.map((c) => {
          if (c) {
            return c.section
          } else {
            return undefined
          }
        })}
      </LayoutContent>
      {/* Empty ToC to have even spacing on the left/right side of main content. */}
      <SideColumn />
    </Layout>
  )
}
